import { AnalyzerRow } from "../../types";

export const getCovenantsDataToExcel = async (data: Array<AnalyzerRow>) => {
  if (!data || data.length === 0) return null;

  const preparedData: Array<AnalyzerRow> = [];

  for await (const d of data) {
    const newObject: { [key: string]: any } = {};
    newObject["Portfolio Company"] = d.company_name;
    newObject["Period"] = d.period || "";
    newObject["Currency"] = d.currency || "";
    newObject["Leverage Ratio - Opening"] = d.opening_leverage || "";
    newObject["Leverage Ratio - Current"] = d.current_leverage || "";
    newObject["Leverage Ratio - Covenant Test"] = d.leverage_covenant_test || "";
    newObject["Leverage Ratio - Headroom"] = d.leverage_headroom || "";
    newObject["Interest Cover - Current"] = d.interest_current || "";
    newObject["Interest Cover - Covenant Test"] = d.interest_covenant_test || "";
    newObject["Interest Cover - Headroom"] = d.interest_headroom || "";
    newObject["Cash Cover - Current"] = d.cash_current || "";
    newObject["Cash Cover - Covenant Test"] = d.cash_covenant_test || "";
    newObject["Cash Cover - Headroom"] = d.cash_headroom || "";

    preparedData.push(newObject);
  }

  return preparedData;
};
