import { Link } from "@mui/material";
import { Buffer } from "buffer";
import { ReportingPeriods } from "../../../types";
import { formatValue } from "../../../utils/auxiliary";
import { DataFormatTypes } from "../../../types";

interface StandardDataCellProps {
  statement: string;
  companyId: number;
  period: ReportingPeriods;
  companyReportingCycle: ReportingPeriods;
  data_point: string;
  uuid: string;
  value: number;
  month: number;
  fiscalYear: number;
  formula: string;
  is_mapping: boolean;
  handleOpenTraceability: (data_point: string, uuid: string, month: number) => void;
  style?: string;
}

export const StandardDataCell = ({
  companyId,
  statement,
  period,
  companyReportingCycle,
  data_point,
  uuid,
  value,
  month,
  style,
  fiscalYear,
  formula,
  is_mapping,
  handleOpenTraceability,
}: StandardDataCellProps) => {
  const handleClick = () => {
    // TODO Use either another encoding or use a salt file to encode the data.
    const encodedData = Buffer.from(
      JSON.stringify({
        statement: statement,
        period: period,
        uuid: formula,
        month: month,
        year: fiscalYear,
        company_period: companyReportingCycle,
      })
    ).toString("base64");

    is_mapping
      ? window.open(`/view_reported/${companyId}/${encodedData}`, "_blank", "width=1300,height=500,scrollbars=yes,resizable=yes")
      : handleOpenTraceability(data_point, uuid, month);
  };

  const symbol = style === "margin" ? DataFormatTypes.margin : style === "ratio" ? DataFormatTypes.ratio : DataFormatTypes.none;
  const outputValue = formatValue(value, symbol);

  return (
    <Link
      e2e-test-id={`value-cell-${data_point}-${month}`}
      color="#1A50DD"
      underline="none"
      sx={{ cursor: "pointer" }}
      onClick={() => handleClick()}
    >
      {outputValue}
    </Link>
  );
};
