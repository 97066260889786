import { SelectChangeEvent, Divider } from "@mui/material";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/hooks";
import { setSelectedPortfolio } from "../../redux/reducers/portfolioReducer";
import { Country, Fund, Portfolio, Sector, PerformanceFilterOption } from "../../types";
import { MultiSelect, MultiSelectItemType } from "../MultiSelect";
import { PerformanceFilterSelect } from "../PerformanceFilterSelect";
import { BootstrapInput } from "../MultiSelect/styles";
import { CustomSelect, CustomSelectItem, FilterForm, Wrapper } from "./styles";

export type GlobalFiltersProps = {
  portfolios: Portfolio[];
  funds: Fund[];
  sectors: Sector[];
  countries: Country[];
  parValues: PerformanceFilterOption[];
  leverages: PerformanceFilterOption[];
  revenuesVsBudget: PerformanceFilterOption[];
  revenuesVsLastYear: PerformanceFilterOption[];
  selectedFunds: MultiSelectItemType[];
  selectedSectors: MultiSelectItemType[];
  selectedCountries: MultiSelectItemType[];
  selectedParValues: (number | null)[];
  selectedLeverages: (number | null)[];
  selectedRevenuesVsBudget: (number | null)[];
  selectedRevenuesVsLastYear: (number | null)[];
  onFilter: (
    selectedPortfolioId?: MultiSelectItemType,
    selectedFundIds?: MultiSelectItemType[],
    selectedSectorIds?: MultiSelectItemType[],
    selectedCountryIds?: MultiSelectItemType[],
    selectedParValues?: (number | null)[],
    selectedLeverages?: (number | null)[],
    selectedRevenuesVsBudget?: (number | null)[],
    selectedRevenuesVsLastYear?: (number | null)[]
  ) => void;
};

export const GlobalFilters = ({
  portfolios,
  funds,
  sectors,
  countries,
  parValues,
  leverages,
  revenuesVsBudget,
  revenuesVsLastYear,
  selectedFunds,
  selectedSectors,
  selectedCountries,
  selectedParValues,
  selectedLeverages,
  selectedRevenuesVsBudget,
  selectedRevenuesVsLastYear,
  onFilter,
}: GlobalFiltersProps) => {
  const dispatch = useDispatch();
  const state = useAppSelector((state) => state.app);

  const portfolioItems = portfolios.map(({ id, portfolio_name }) => ({
    id: String(id),
    description: portfolio_name,
  }));

  const fundItems = funds.map(({ name, description }) => ({
    id: name,
    description,
  }));

  const sectorItems = sectors.map(({ name, description }) => ({
    id: name,
    description,
  }));

  const countryItems = countries.map(({ name, description }) => ({
    id: name,
    description,
  }));

  const filterChanged = async (previousSelection: MultiSelectItemType[], newSelection: MultiSelectItemType[]) => {
    // check length of both arrays is same or not
    if (previousSelection.length !== newSelection.length) return true;

    // sorting the arrays by id to compare elements
    const sortedPreviosSelection = previousSelection.slice().sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });

    const sortedNewSelection = newSelection.slice().sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });

    // comparing the elements to check if the arrays have the same values
    const hasSameValues = sortedPreviosSelection.every(
      (value, index) => value.id === sortedNewSelection[index].id && value.description === sortedNewSelection[index].description
    );

    return !hasSameValues;
  };

  const performanceFilterChanged = (previousSelection: (number | null)[], newSelection: (number | null)[]) => {
    if (previousSelection[0] !== newSelection[0] || previousSelection[1] !== newSelection[1]) {
      return true;
    } else {
      return false;
    }
  };

  const filterPortfolio = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as MultiSelectItemType;

    if (state.selectedPortfolio.data.id === value.id) return;

    dispatch(
      setSelectedPortfolio({
        data: value,
      })
    );
    onFilter(
      value,
      selectedFunds,
      selectedSectors,
      selectedCountries,
      selectedParValues,
      selectedLeverages,
      selectedRevenuesVsBudget,
      selectedRevenuesVsLastYear
    );
  };

  const filterFunds = async (selectedValues: MultiSelectItemType[]) => {
    if (await filterChanged(selectedFunds, selectedValues)) {
      onFilter(
        state.selectedPortfolio.data,
        selectedValues,
        selectedSectors,
        selectedCountries,
        selectedParValues,
        selectedLeverages,
        selectedRevenuesVsBudget,
        selectedRevenuesVsLastYear
      );
    }
  };

  const filterSectors = async (selectedValues: MultiSelectItemType[]) => {
    if (await filterChanged(selectedSectors, selectedValues)) {
      onFilter(
        state.selectedPortfolio.data,
        selectedFunds,
        selectedValues,
        selectedCountries,
        selectedParValues,
        selectedLeverages,
        selectedRevenuesVsBudget,
        selectedRevenuesVsLastYear
      );
    }
  };

  const filterCountries = async (selectedValues: MultiSelectItemType[]) => {
    if (await filterChanged(selectedCountries, selectedValues)) {
      onFilter(
        state.selectedPortfolio.data,
        selectedFunds,
        selectedSectors,
        selectedValues,
        selectedParValues,
        selectedLeverages,
        selectedRevenuesVsBudget,
        selectedRevenuesVsLastYear
      );
    }
  };

  const filterParValue = (selectedValues: (number | null)[]) => {
    if (performanceFilterChanged(selectedParValues, selectedValues)) {
      onFilter(
        state.selectedPortfolio.data,
        selectedFunds,
        selectedSectors,
        selectedCountries,
        selectedValues,
        selectedLeverages,
        selectedRevenuesVsBudget,
        selectedRevenuesVsLastYear
      );
    }
  };

  const filterLeverage = (selectedValues: (number | null)[]) => {
    if (performanceFilterChanged(selectedLeverages, selectedValues)) {
      onFilter(
        state.selectedPortfolio.data,
        selectedFunds,
        selectedSectors,
        selectedCountries,
        selectedParValues,
        selectedValues,
        selectedRevenuesVsBudget,
        selectedRevenuesVsLastYear
      );
    }
  };

  const filterRevenueVsBudget = (selectedValues: (number | null)[]) => {
    if (performanceFilterChanged(selectedRevenuesVsBudget, selectedValues)) {
      onFilter(
        state.selectedPortfolio.data,
        selectedFunds,
        selectedSectors,
        selectedCountries,
        selectedParValues,
        selectedLeverages,
        selectedValues,
        selectedRevenuesVsLastYear
      );
    }
  };

  const filterRevenueVsLastYear = (selectedValues: (number | null)[]) => {
    if (performanceFilterChanged(selectedRevenuesVsLastYear, selectedValues)) {
      onFilter(
        state.selectedPortfolio.data,
        selectedFunds,
        selectedSectors,
        selectedCountries,
        selectedParValues,
        selectedLeverages,
        selectedRevenuesVsBudget,
        selectedValues
      );
    }
  };

  return (
    <Wrapper>
      <FilterForm container>
        {portfolioItems && (
          <CustomSelect
            value={state.selectedPortfolio?.data?.id}
            input={<BootstrapInput />}
            displayEmpty
            renderValue={(value) => (value === "" ? "Portfolios" : String(state.selectedPortfolio.data?.description))}
            onChange={filterPortfolio}
          >
            {portfolioItems?.map((p, index) => (
              <CustomSelectItem key={index} value={p.id}>
                {p.description}
              </CustomSelectItem>
            ))}
          </CustomSelect>
        )}
        {fundItems && (
          <MultiSelect
            label="Fund"
            collection={fundItems}
            values={selectedFunds}
            onSelectClosed={(values) => filterFunds(values)}
          />
        )}
        {sectorItems && (
          <MultiSelect
            label="Sector"
            collection={sectorItems}
            values={selectedSectors}
            onSelectClosed={(values) => filterSectors(values)}
          />
        )}
        {countryItems && (
          <MultiSelect
            label="Country"
            collection={countryItems}
            values={selectedCountries}
            onSelectClosed={(values) => filterCountries(values)}
          />
        )}
        <Divider orientation="vertical" variant="middle" flexItem></Divider>
        <PerformanceFilterSelect
          title="Par Value"
          rangeSelection={selectedParValues}
          marks={parValues}
          onSelectClosed={(values) => filterParValue(values)}
        />
        <PerformanceFilterSelect
          title="Leverage"
          rangeSelection={selectedLeverages}
          marks={leverages}
          onSelectClosed={(values) => filterLeverage(values)}
        />
        <PerformanceFilterSelect
          title="Revenue vs Budget"
          rangeSelection={selectedRevenuesVsBudget}
          marks={revenuesVsBudget}
          onSelectClosed={(values) => filterRevenueVsBudget(values)}
        />
        <PerformanceFilterSelect
          title="Revenue vs Last Year"
          rangeSelection={selectedRevenuesVsLastYear}
          marks={revenuesVsLastYear}
          onSelectClosed={(values) => filterRevenueVsLastYear(values)}
        />
      </FilterForm>
    </Wrapper>
  );
};
