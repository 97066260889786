import FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import {
  getReportingCycleDescription,
  getStatementDescription,
  getSubsectionDescription,
} from "../../../services/export_to_excel/helpers";

import { FinancialStatements, ReportSection } from "../../../types";
import { ExportStatementsFileProps, ExportToFileType } from "../types.d";
import { getDebtStructureConvenantsDataToExcel } from "../../../services/export_to_excel/debtstructure-covenants";
import { getFinancialStatementDataToExcel } from "../../../services/export_to_excel/financial";

export const exportFinancialDebtStructureCovenantsStatementsDataToExcel = async (
  fileName: string,
  data: Array<ReportSection>,
  props: ExportStatementsFileProps,
  statementType: ExportToFileType
) => {
  let preparedData;

  switch (statementType) {
    case ExportToFileType.FinancialStatements:
      preparedData = await getFinancialStatementDataToExcel(data);
      break;
    case ExportToFileType.DebtStructureAndCovenants:
      preparedData = await getDebtStructureConvenantsDataToExcel(
        props.financialStatement,
        data,
        props.debtCovenantsPeriod,
        props.debtCovenantsCurrentCovenantPeriod
      );
      break;
  }

  if (!preparedData) {
    //FIXME we need to return some error message to the user.
    return null;
  }

  await generate(fileName, preparedData, props);
};

const getMetadata = async (
  companyName: string,
  financialStatement: string,
  statementType: string,
  reportCycle: string,
  financialYear: string,
  currency: string,
  unit: string,
  debtCovenantsPeriod?: string
) => {
  return [
    {
      name: "Company Name",
      value: companyName,
    },
    {
      name: "Subsection",
      value: await getSubsectionDescription(financialStatement),
    },
    {
      name: "Table Type",
      value: await getStatementDescription(statementType, debtCovenantsPeriod),
    },
    {
      name: "Reporting",
      value: await getReportingCycleDescription(reportCycle),
    },
    {
      name: "Financial Year",
      value: financialYear,
    },
    {
      name: "Currency",
      value: currency,
    },
    {
      name: "Units",
      value: unit,
    },
  ];
};

export const generate = async (fileName: string, preparedData: any, props: ExportStatementsFileProps) => {
  const metadata = await getMetadata(
    props.companyName,
    props.financialStatement,
    props.statementType,
    props.reportingCycle,
    props.financialYear,
    props.currency,
    props.unit
  );

  const mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const wsMetadata = XLSX.utils.json_to_sheet(metadata, { skipHeader: true });
  const wsData = XLSX.utils.json_to_sheet(preparedData);

  // Defining the width of the columns
  const headerColWidths = await getHeaderColumnWidths();
  const dataColWidths = await getDataColumnWidths(props.financialStatement);

  // Apply column widths to the worksheet
  wsMetadata["!cols"] = headerColWidths;
  wsData["!cols"] = dataColWidths;

  // Apply number format to data cells
  const range = XLSX.utils.decode_range(`B2:Z${preparedData.length + 1}`);
  const numberFormat = "#,##0.00";

  for (let R = range.s.r; R <= range.e.r; ++R) {
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = { c: C, r: R };
      const cellRef = XLSX.utils.encode_cell(cellAddress);
      const cell = wsData[cellRef];
      if (cell && cell.t === "n") {
        cell.z = numberFormat; // Apply number format
      }
    }
  }

  const cellAddress = { c: 1, r: 2 };
  const cellRef = XLSX.utils.encode_cell(cellAddress);

  wsData[cellRef].z = numberFormat;

  const wb = { Sheets: { Data: wsData, Metadata: wsMetadata }, SheetNames: ["Data", "Metadata"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const excelBlob = new Blob([excelBuffer], { type: mimeType });

  FileSaver.saveAs(excelBlob, fileName);
};

const getHeaderColumnWidths = async () => {
  return [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];
};

const getDataColumnWidths = async (financialStatementType: string) => {
  let firstColumnWidth = 40;
  let otherColumnsWidth = 12;

  switch (financialStatementType) {
    case FinancialStatements.COVENANTS:
      otherColumnsWidth = 30;
      break;
    case FinancialStatements.DEBT_STRUCTURE:
      otherColumnsWidth = 30;
      break;
  }

  const widths = [{ wch: firstColumnWidth }];

  for (let i = 1; i <= 20; i++) {
    widths.push({ wch: otherColumnsWidth });
  }

  return widths;
};
