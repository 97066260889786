import { Typography, Box, Stack, styled, MenuItem, InputBase, Button, Card, CardActions, CardContent, Grid } from "@mui/material";

export const Wrapper = styled(Box)`
  padding: 4.375rem 11.25rem 2.75rem;
`;

export const TabTitle = styled(Typography)`
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 2.125rem;
  margin-bottom: 2rem;
`;
export const CommentCount = styled(Typography)`
  color: #586463;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
`;
export const ButtonStack = styled(Stack)`
  padding-bottom: 1rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const PeriodButton = styled(Button)(() => ({
  color: "#313636",
  fontFamily: "Inter, sans-serif",
  fontSize: 12,
  fontWeight: 400,
  textTransform: "none",
  padding: "8px 12px",
  border: "1px solid #d7d7d7",
  borderRadius: 6,
  "&.MuiButton-outlined": {
    color: "#162859",
    border: "1px solid #162859",
  },
}));

export const BootstrapInput = styled(InputBase)(() => ({
  color: "#292727",
  fontFamily: "Inter, sans-serif",
  "label + &": {
    marginTop: "1rem",
  },
  "& .MuiSelect-icon": {
    width: ".75em",
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#ffffff",
    border: "1px solid #B4B7B7",
    fontSize: 12,
    lineHeight: "20px",
    padding: "6px 40px 6px 8px",
    fontFamily: ["Inter", "-apple-system", "BlinkMacSystemFont", '"Segoe UI"', '"Helvetica Neue"', "Arial", "sans-serif"].join(
      ","
    ),
    "& :focus": {
      borderRadius: 4,
      backgroundColor: "#E8EAEE",
      outline: 0,
      border: "none",
      boxShadow: "none",
    },
  },
  "& .MuiSelect-select.MuiSelect-standard.MuiInputBase-input": {
    paddingRight: "34px",
  },
  "& .MuiSelect-icon.MuiSelect-iconStandard": {
    marginRight: "13px",
  },
  "& :hover": {
    backgroundColor: "#E8EAEE",
  },
  "& :active": {
    backgroundColor: "#E8EAEE",
  },
  "& :focus": {
    backgroundColor: "#E8EAEE",
  },
}));

export const StyledMenuItem = styled(MenuItem)({
  fontFamily: "Inter, sans-serif",
  fontSize: 12,
  fontWeight: 400,
  lineHeight: "20px",
  paddingTop: "4px",
  paddingBottom: "4px",
});

export const AnalystComments = styled(Box)``;

export const CommentCard = styled(Card)`
  padding: 3rem 3.125rem;
  margin-bottom: 1rem;
  .MuiCardHeader-root {
    padding: 0rem 0.75rem 1rem 0rem;
  }
  .MuiCardActions-root {
    padding: 0rem;
  }
  .MuiCardHeader-root .MuiLink-root {
    color: #1a50dd;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .MuiTypography-root,
  .MuiTypography-root > * {
    font-family: "Inter", sans-serif;
    color: #292727;
  }
  .MuiTypography-body1 {
    font-size: 1rem;
  }
  .MuiTypography-h4 {
    font-weight: 600;
    font-size: 22px;
    line-height: 34px;
  }
  .MuiTypography-h5 {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .MuiTypography-body2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #586463;
  }
`;
export const CommentContent = styled(Grid)`
  padding: 0;
  margin-top: -1rem;
`;
export const CommentText = styled(CardContent)`
  padding: 0rem;
  padding-bottom: 0.625rem;
`;
export const AnalystCardActions = styled(CardActions)`
  margin-left: -0.5rem;
`;

export const EditorWrapper = styled(Box)`
  margin-bottom: 1rem;
  .MuiCardContent-root {
    padding-left: 0;
    padding-right: 0;
  }
`;
