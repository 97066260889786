import { FilterOptions } from "./types";
import ApiClient from "../api";
import { ReportingPeriods, StatementType } from "../../types";

const getReportedCovenantsByCompanyId = async <T>(companyId: number, financialYear: number, period: string) => {
  try {
    const response = await ApiClient.get<T>(`/covenants/${companyId}/reported?financialYear=${financialYear}&period=${period}`);
    return response.data;
  } catch (error) {
    console.error("API error:", error);
    throw new Error("Failed to fetch reported income data for deal.");
  }
};

const getStandardizedCovenantsByCompanyId = async <T>(companyId: number, financialYear: number, period: string) => {
  try {
    const response = await ApiClient.get<T>(`/covenants/${companyId}/reported?financialYear=${financialYear}&period=${period}`);
    return response.data;
  } catch (error) {
    console.error("API error:", error);
    throw new Error("Failed to fetch standardized financial data for deal.");
  }
};

const getCurrentCovenantsByCompanyId = async <T>(
  companyId: number,
  financialYear: number,
  period: string,
  currentPeriod: number[],
  isPeriodSelected: boolean
) => {
  try {
    const response = await ApiClient.get<T>(
      `/covenants/${companyId}/current?financialYear=${financialYear}&period=${period}&currentPeriod=${currentPeriod}&isPeriodSelected=${isPeriodSelected}`
    );
    return response.data;
  } catch (error) {
    console.error("API error:", error);
    throw new Error("Failed to fetch current covenants data for deal.");
  }
};

const getCovenantsFilters = async (companyId: number, type: StatementType, period: ReportingPeriods) => {
  try {
    const response = await ApiClient.get(`/covenants/${companyId}/filters?type=${type}&period=${period}`);
    return response.data as FilterOptions;
  } catch (error) {
    console.error("API error:", error);
    throw new Error("Failed to fetch reported financial data for deal.");
  }
};

export {
  getReportedCovenantsByCompanyId,
  getStandardizedCovenantsByCompanyId,
  getCurrentCovenantsByCompanyId,
  getCovenantsFilters,
};
