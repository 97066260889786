import { ManagementCommentary, ManagementCommentaryByYear } from "../../types";
import ApiClient from "../api";

const getAllManagementCommmentsByCompanyId = async (dealId: number) => {
  try {
    const response = await ApiClient.get<ManagementCommentaryByYear>(`/management_commentary/${dealId}`);
    return response.data as ManagementCommentaryByYear;
  } catch (err) {
    console.error("api error:");
    console.error(err);
    throw Error("Failed to get all analyst comments for the latest year.");
  }
};

const getAllManagementCommmentsByYear = async (dealId: number, selectedYear: number) => {
  try {
    const response = await ApiClient.get<ManagementCommentary[]>(`/management_commentary/year/${dealId}/${selectedYear}`);
    return response.data;
  } catch (err) {
    console.error("api error:");
    console.error(err);
    throw Error("Failed to get all analyst comments for the latest year.");
  }
};

export { getAllManagementCommmentsByCompanyId, getAllManagementCommmentsByYear };
