import { AxiosRequestConfig } from "axios";
import { store } from "../../redux/store";
import { getTokenResponse, handleSessionExpired, setAuthDataToState } from "../auth";

export const apiInterceptor = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const authData = store.getState().app.user.auth;
  let token;

  const renewToken = async () => {
    try {
      const tokenResponse = await getTokenResponse();
      await setAuthDataToState(tokenResponse);
      token = tokenResponse.idToken;
    } catch (error) {
      console.error("error when getting the token response:", error);
      await handleSessionExpired();
    }
  };

  if (!authData) {
    renewToken();
  } else {
    token = authData.idToken;

    if (!token) {
      renewToken();
    } else {
      const expiresOnTimestamp = new Date(authData.expiresOn).getTime();
      const tokenIsValid = await validateToken(expiresOnTimestamp);

      if (!tokenIsValid) {
        renewToken();
      }
    }
  }

  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

const validateToken = async (expiresOnTimestamp: number) => {
  if (!expiresOnTimestamp) return false;

  const now = new Date().getTime();
  const isValid = expiresOnTimestamp > now;
  //console.info(isValid);
  return isValid;
};

export const apiResponseInterceptor = async (response: any) => {
  return response;
};
