import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Buffer } from "buffer";
import { Box } from "@mui/material";
import { HighlightedCell, StatementTable } from "../../components/StatementTable";
import { StatementContainer } from "../../components/FinancialStatement/styles";
import { Container, HeaderItem, PageHeader, Title, Wrapper } from "./styles";
import { getDealById } from "../../services/deals";
import {
  Company,
  FinancialStatements,
  ReportSection,
  ReportingPeriods,
  SimplifiedFinancialStatement,
  StatementType,
  Units,
} from "../../types";
import { getReportedBalanceSheetByCompanyId } from "../../services/balance_sheet";
import { getReportedCashFlowByCompanyId } from "../../services/cash_flow";
import { getReportedIncomeByCompanyId } from "../../services/income_statement";
import { getReportedDebtStructureByCompanyId } from "../../services/debt_structure";
import { FullscreenCircularProgress } from "../../components/FullscreenCircularProgress";
import { getReportedCovenantsByCompanyId } from "../../services/covenants";

function getStatementDescription(statement: string): string {
  switch (statement) {
    case FinancialStatements.INCOME_STATEMENT:
      return "Income Statement";
    case FinancialStatements.BALANCE_SHEET:
      return "Balance Sheet";
    case FinancialStatements.CASH_FLOW:
      return "Cash Flow";
    case FinancialStatements.DEBT_STRUCTURE:
      return "Deb Structure";
    case FinancialStatements.COVENANTS:
      return "Covenants";
    default:
      return "Unknown Statement";
  }
}

export const ReportedViewPopup = () => {
  const [deal, setDeal] = useState<Company>();
  const [data, setData] = useState<ReportSection[] | null>(null);
  const [reportUnit, setReportUnit] = useState<Units>();
  const [loading, setLoading] = useState(true);
  const [highlighted, setHighlighted] = useState<HighlightedCell>();
  const { companyId, hash } = useParams();
  const decoded = Buffer.from(hash!, "base64").toString("utf-8");
  const { statement, year, uuid, month, period, company_period } = JSON.parse(decoded);

  const fetchStatementFinancials = (statement: string, year: string) => {
    if (statement === FinancialStatements.BALANCE_SHEET) {
      getReportedBalanceSheetByCompanyId<SimplifiedFinancialStatement>(+companyId!, +year).then((report) => {
        setReportUnit(report.unit);
        setData(report.items);
        setLoading(false);
      });
    }

    if (statement === FinancialStatements.CASH_FLOW) {
      getReportedCashFlowByCompanyId<SimplifiedFinancialStatement>(+companyId!, +year!, period, false).then((report) => {
        setReportUnit(report.unit);
        setData(report.items);
        setLoading(false);
      });
    }

    if (statement === FinancialStatements.INCOME_STATEMENT) {
      getReportedIncomeByCompanyId<SimplifiedFinancialStatement>(+companyId!, +year!, period, false).then((report) => {
        setReportUnit(report.unit);
        setData(report.items);
        setLoading(false);
      });
    }
    if (statement === FinancialStatements.DEBT_STRUCTURE) {
      getReportedDebtStructureByCompanyId<SimplifiedFinancialStatement>(+companyId!, +year, period).then((report) => {
        setReportUnit(report.unit);
        setData(report.items);
        setLoading(false);
      });
    }
    if (statement === FinancialStatements.COVENANTS) {
      getReportedCovenantsByCompanyId<SimplifiedFinancialStatement>(+companyId!, +year, period).then((report) => {
        setReportUnit(report.unit);
        setData(report.items);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    getDealById(+companyId!).then((deal) => {
      setDeal(deal as Company);
      switch (period) {
        case ReportingPeriods.Quarterly:
          setHighlighted({ target_uuid: uuid, month: month });
          fetchStatementFinancials(statement, year);
          break;
        default:
          setHighlighted({ target_uuid: uuid, month: month });
          fetchStatementFinancials(statement, year);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <FullscreenCircularProgress />;

  return (
    <Wrapper container>
      <Container item xs={12}>
        <PageHeader container>
          <HeaderItem item xs={12}>
            <Title>
              {deal?.deal_name}{" "}
              <Box ml={1} sx={{ color: "#586463", fontWeight: 300 }} component="span" role="reported">
                Reported {getStatementDescription(statement)}
              </Box>
            </Title>
          </HeaderItem>
        </PageHeader>

        <StatementContainer>
          {!loading && (
            <StatementTable
              companyId={+companyId!}
              statement={statement}
              mode={StatementType.REPORTED}
              period={period}
              company_period={company_period}
              unit={reportUnit!}
              currency={deal?.currency!}
              financialYear={year}
              data={data!}
              highlightItem={highlighted}
              enableConversions={deal?.enable_conversions}
            />
          )}
        </StatementContainer>
      </Container>
    </Wrapper>
  );
};
