import { Box, InputLabel, styled as MUIStyled, MenuItem, Select, Grid, styled } from "@mui/material";

export const Wrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 0.125rem;
`;

export const FilterTitle = styled(Box)`
  font-size: 14px;
  color: #000000;
`;

export const FilterContent = styled(Box)`
  width: 100%;
`;

export const FilterForm = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.625rem 0.625rem 0.625rem 0;
  gap: 0.625rem;
`;

export const FilterLabel = styled(InputLabel)``;

export const CustomSelect = MUIStyled(Select)(({ theme }) => ({
  backgroundColor: "#f1faff",
  fontSize: "14px",
  minWidth: "100px",
  height: "39px",
  borderRadius: 2,
  padding: "5px 7px 5px 10px",
  "& .MuiSelect-select": {
    color: "#162859",
  },
  "&::before": {
    display: "none",
  },
  "&::after": {
    display: "none",
  },
}));

export const CustomSelectItem = MUIStyled(MenuItem)(({ theme }) => ({
  backgroundColor: "#ffffff",
  fontSize: 14,
  marginRight: 15,

  ":focus-visible": {
    backgroundColor: "transparent",
    width: "100%",
    minWidth: "100px",
  },

  ":hover": {
    backgroundColor: "#f1faff",
    width: "100%",
    minWidth: "100px",
  },
  "&.Mui-selected": {
    backgroundColor: "transparent   ",
  },
}));
