import { Divider } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Button } from "@alterdomus-analytics/dna-ui";

import ADlogo from "../../assets/images/ADlogo.png";
import avatars from "../../assets/images/avatars.svg";
import clipboard from "../../assets/images/clipboard.svg";
import cvxLogo from "../../assets/images/cvxLogo.png";
import folders from "../../assets/images/folders.svg";
import { Loader } from "../../components/Loader";
import { auth, loginRedirect, loginSilent } from "../../services/auth";
import { BrandHeader, Container, IconBox, Wrapper } from "./styles";

export const LoginPage = () => {
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [searchParams] = useSearchParams();
  const useLoginRedirect = searchParams.get("login") !== null;
  const initialized = useRef(false);

  /**
   * Redirect users to loginRedirect() if they pass in the `loginRedirect` query param. Use useEffect to avoid calling the redirection methods more than once
   */
  useEffect(() => {
    if (!initialized.current && useLoginRedirect) {
      initialized.current = true;
      setIsRedirecting(true);
      loginRedirect();
    }
  }, [useLoginRedirect]);

  /**
   * Attempt to login existing users and redirect them if they are already able
   */
  try {
    loginSilent();
  } catch (err) {
    console.log(err);
  }

  /**
   * Handle how a user logs in when they click "log into your account"
   */
  const handleLogin = async () => {
    setIsAuthenticating(true);
    try {
      await auth();
      setIsAuthenticating(false);
    } catch (error) {
      console.error(error);
      setIsAuthenticating(false);
    }
  };

  // Show a loader if the user is waiting for authentication
  if (isRedirecting) return <Loader />;

  return (
    <Wrapper>
      <BrandHeader>
        <img src={cvxLogo} alt="CVX logo" width="220" height="22" />
        <Divider orientation="vertical" flexItem />
        <h3>an alter domus technology</h3>
      </BrandHeader>
      <Container>
        <h1>CreditVision</h1>
        <p>Monitor and analyze your portfolio, assess credit risk early and capture new investment opportunities</p>
        <Button
          label="Log Into Your Account"
          variantType="primary"
          disabled={isAuthenticating}
          onClick={handleLogin}
          e2e-test-id="cvx-login-button"
        />
        <IconBox>
          <img src={folders} alt="files" />
          <img src={clipboard} alt="clipboard" />
          <img src={avatars} alt="users" />
        </IconBox>
      </Container>
      <BrandHeader>
        <img src={ADlogo} alt="CVX logo" width="148" height="20" />
      </BrandHeader>
    </Wrapper>
  );
};
