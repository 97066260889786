import { FinancialStatements, StatementType } from "../../types";
import { getMonthNameAbbreviated } from "../../utils/monthNames";

export const getDebtStructureConvenantsDataToExcel = async (
  statementType: string,
  data: Array<any>,
  debtCovenantsPeriod?: string,
  debtCovenantsCurrentCovenantPeriod?: string
) => {
  if (!data || data.length === 0) return null;

  const preparedData = [];
  //FIXME when we align all statements response structure we can revert from 2 cases to one and specific type of data
  if (statementType === FinancialStatements.DEBT_STRUCTURE) {
    if (debtCovenantsPeriod?.toLowerCase() === StatementType.ALL_PERIODS) {
      for await (const d of data) {
        if (d.items && d.items.length > 0) {
          // If 'items' property exists, iterate over 'items'
          for await (const i of d.items) {
            if (!i.values || i.values.length === 0) continue;

            const newObject: { [key: string]: any } = {};
            newObject[" "] = i.name;

            for await (const v of i.values) {
              const monthName = await getMonthNameAbbreviated(v.reported_month);
              newObject[monthName] = v.value;
            }

            preparedData.push(newObject);
          }
        } else {
          // If 'items' property doesn't exist, iterate directly over 'data'
          if (!d.values || d.values.length === 0) continue;

          const newObject: { [key: string]: any } = {};
          newObject[" "] = d.name;

          for await (const v of d.values) {
            const monthName = await getMonthNameAbbreviated(v.reported_month);
            newObject[monthName] = v.value;
          }

          preparedData.push(newObject);
        }
      }
    } else {
      for await (const d of data) {
        const newObject: { [key: string]: any } = {};
        newObject[" "] = d.datapoint_name;
        newObject["AMOUNT: Original"] = d.originalAmount || "";
        newObject[`AMOUNT: Current: ${debtCovenantsCurrentCovenantPeriod}`] = d.currentAmount || "";
        newObject["MARGIN: Original"] = d.originalMargin || "";
        newObject[`MARGIN: Current: ${debtCovenantsCurrentCovenantPeriod}`] = d.currentMargin || "";
        newObject["Maturiry Date"] = d.maturityDate || "";
        preparedData.push(newObject);
      }
    }
  } else if (statementType === FinancialStatements.COVENANTS) {
    if (debtCovenantsPeriod?.toLowerCase() === StatementType.ALL_PERIODS) {
      for await (const d of data) {
        if (d.items && d.items.length > 0) {
          // If 'items' property exists, iterate over 'items'
          for await (const i of d.items) {
            if (!i.values || i.values.length === 0) continue;

            const newObject: { [key: string]: any } = {};
            newObject[" "] = i.name;

            for await (const v of i.values) {
              const monthName = await getMonthNameAbbreviated(v.reported_month);
              newObject[monthName] = v.value;
            }

            preparedData.push(newObject);
          }
        } else {
          // If 'items' property doesn't exist, iterate directly over 'data'
          if (!d.values || d.values.length === 0) continue;

          const newObject: { [key: string]: any } = {};
          newObject[" "] = d.name;

          for await (const v of d.values) {
            const monthName = await getMonthNameAbbreviated(v.reported_month);
            newObject[monthName] = v.value;
          }

          preparedData.push(newObject);
        }
      }
    } else {
      for await (const d of data) {
        const newObject: { [key: string]: any } = {};
        newObject[" "] = d.datapoint_name;
        newObject["NET TOTAL LEVERAGE"] = d.netTotalLeverage || "";
        newObject["NET SENIOR LEVERAGE"] = d.netSeniorLeverage || "";
        newObject["INTEREST COVER"] = d.interestCover || "";
        newObject["CASH FLOW COVER"] = d.cashFlowCover || "";
        preparedData.push(newObject);
      }
    }
  }

  return preparedData;
};
