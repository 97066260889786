import { SetStateAction, useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  ButtonWrapper,
  CommentCard,
  EditorWrapper,
  StyledSecondaryButton,
  StyledTertiaryButton,
  StyledTextField,
} from "./styles";
import { EditorProps } from "./types";
import { CardActions, CardContent, CardHeader, Stack, Typography } from "@mui/material";
// import { CaretDown } from "phosphor-react";
import { Base64 } from "js-base64";
import { LinearProgress } from "@alterdomus-analytics/dna-ui";
import { postFormData, updateFormData } from "../../services/analyst_comments";
import { useAppSelector } from "../../redux/hooks";
import { removeHtml, sanitizeText } from "../../utils/auxiliary";

export const Editor = ({ companyId, config, close, user, onCommentAdded, editingComment }: EditorProps) => {
  const [editorData, setEditorData] = useState("");
  const [textfieldData, setTextfieldData] = useState("");
  // const [selectData, setSelectData] = useState("");
  const [visible, setVisible] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [validationError, setValidationError] = useState(false);
  const userState = useAppSelector((state) => state.app.user);

  useEffect(() => {
    let loaderTimeout: NodeJS.Timeout | undefined;

    if (visible) {
      loaderTimeout = setTimeout(() => {
        setVisible(false);
        close(); // Call the close function after the loader finishes
      }, 3000);
    }

    return () => {
      clearTimeout(loaderTimeout);
    };
  }, [visible, close]);

  useEffect(() => {
    if (editingComment) {
      const decodedEditorData = Base64.decode(editingComment.text);
      const decodedReportingPeriod = Base64.decode(editingComment.reporting_period);
      setEditorData(decodedEditorData);
      setTextfieldData(decodedReportingPeriod);
    } else {
      // Reset the form fields when not editing
      setEditorData("");
      setTextfieldData("");
    }
  }, [editingComment]);

  const handleFormSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    const sanitizedText = sanitizeText(editorData);
    const encodedEditorData = Base64.encode(sanitizedText);
    const sanitizedReportPeriod = sanitizeText(textfieldData);
    const encodedReportPeriod = Base64.encode(sanitizedReportPeriod);
    if (editingComment) {
      // Handle editing of an existing comment
      updateFormData(+companyId!, editingComment.id, encodedEditorData, encodedReportPeriod, user, userState.id);
    } else {
      postFormData(+companyId!, encodedEditorData, encodedReportPeriod, user, userState.id);
    }
    setVisible(true);
    onCommentAdded(); // Call the onCommentAdded function provided by the parent to trigger reload

    // Reset the form fields after submission
    setEditorData("");
    setTextfieldData("");
    // Clear the validation error and message
    setValidationError(false);
    setValidationMessage("");
  };

  const handleEditorChange = (value: SetStateAction<string>) => {
    const maxCharacters = 8000;
    // Extract the string from SetStateAction
    const stringValue = typeof value === "string" ? value : value(editorData);
    // Sanitize the value to remove HTML characters so the calculation of chars is precise
    const sanitizedValue = removeHtml(stringValue);
    const remainingCharacters = maxCharacters - sanitizedValue.length;

    if (value.length > maxCharacters) {
      setValidationError(true);
      // Display a validation message
      setValidationMessage(`Comment cannot exceed ${maxCharacters} characters`);
    } else {
      setValidationError(false);
      setValidationMessage(`You have ${remainingCharacters} characters left.`);
    }
    setEditorData(value);
  };
  // const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSelectData(event.target.value);
  // };
  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextfieldData(event.target.value);
  };

  return (
    <CommentCard variant="outlined" sx={{ maxWidth: "100%", backgroundColor: "#FFFFFF" }}>
      {visible ? (
        <Stack>
          <Typography variant="caption">Saving ...</Typography>
          <LinearProgress variant="query" />
        </Stack>
      ) : (
        <form onSubmit={handleFormSubmit}>
          <CardHeader
            title={
              <Stack sx={{ alignItems: "flex-start" }}>
                <Typography variant="caption" component="div" e2e-test-id={`user-analyst-commentary`}>
                  Posting as {user}
                </Typography>
                <StyledTextField
                  id="reporting-period-input"
                  name="input-period-value"
                  variant="outlined"
                  placeholder="Reporting Period"
                  size="small"
                  margin="none"
                  color="info"
                  helperText="Example input: Q1 2022 or Jan 2022"
                  value={textfieldData}
                  onChange={handleTextFieldChange}
                  InputProps={{}}
                />
              </Stack>
            }
          />
          <CardContent sx={{ maxWidth: "100%" }}>
            <EditorWrapper e2e-test-id={`editor-input-commentary`}>
              <ReactQuill {...config} theme="snow" value={editorData} onChange={handleEditorChange} />
            </EditorWrapper>
            {validationMessage.length > 0 && (
              <Typography variant="body2" component="div" mt={"0.5rem"} e2e-test-id={`validation-analyst-commentary`}>
                {validationMessage}
              </Typography>
            )}
          </CardContent>
          <CardActions>
            <ButtonWrapper>
              <StyledSecondaryButton
                label="Save Comment"
                type="submit"
                variantType="secondary"
                disabled={validationError || editorData.length === 0}
                e2e-test-id={`submit-button-commentary`}
              />
            </ButtonWrapper>
            <StyledTertiaryButton
              type="button"
              label="Cancel"
              variantType="tertiary"
              onClick={close}
              e2e-test-id={`cancel-button-commentary`}
            />
          </CardActions>
        </form>
      )}
    </CommentCard>
  );
};
