/**
 * a11yProps: Tab panel id handler
 * @param index {number}
 * @returns {Object}
 */
export const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
