import ApiClient from "../api";
import { FinancialSummary } from "./types";

const getFinancialReportingByCompanyId = async (companyId: number) => {
  try {
    const response = await ApiClient.get<FinancialSummary>(`/financial_summary/${companyId}`);
    return response.data;
  } catch (error) {
    console.error("API error:", error);
    throw new Error("Failed to fetch financial reporting data for deal.");
  }
};

export { getFinancialReportingByCompanyId };
