import { Menu, MenuItem, SidebarNavigation } from "@alterdomus-analytics/dna-ui";
import { Box, styled as MUIStyled } from "@mui/material";
import styled from "styled-components";

export const SidebarNavigationWrapper = MUIStyled(Box)`
position: fixed;
top: 65px;
  width: 78px;
  min-height: calc(100vh - 64px);
  border-right: 1px solid #e0e0e0 !important;
  z-index: 1000;
  background-color: #ffffff;
`;

export const StyledSidebarNavigation = styled(SidebarNavigation)``;

export const ToolsButton = styled.button((props) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 4,
  border: "none",
  background: "transparent",
  width: "100%",
  fontSize: 12,
  paddingTop: 15,
  paddingBottom: 15,
  cursor: "pointer",
  ":hover": {
    backgroundColor: "#DCE8ED",
  },

  svg: {
    color: "#4966d7",
  },
}));

export const StyledMenu = MUIStyled(Menu)(() => ({
  "& .MuiMenu-list": {
    width: 150,
  },
}));

export const StyledMenuItem = styled(MenuItem)``;
