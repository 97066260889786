import { LinearProgress } from "@alterdomus-analytics/dna-ui";
import { LoaderContainer } from "./styles";

export const Loader = () => {
  return (
    <LoaderContainer>
      <LinearProgress variant="query" />
    </LoaderContainer>
  );
};
