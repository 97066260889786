import {
  getIncomeFilters,
  getReportedIncomeByCompanyId,
  getStandardizedIncomeByCompanyId,
} from "../../../../services/income_statement";
import { IncomeStatementProps } from "./types";
import { FinancialStatement } from "../../../../components/FinancialStatement";
import { FinancialStatements } from "../../../../types";

export const IncomeStatement = ({ periodType, companyId, companyName, currency, enableConversions }: IncomeStatementProps) => {
  return (
    <FinancialStatement
      statement={FinancialStatements.INCOME_STATEMENT}
      showSelector={true}
      showBudget={true}
      companyId={companyId}
      companyName={companyName}
      periodType={periodType}
      currency={currency}
      filterDataFn={getIncomeFilters}
      standardizedDataFn={getStandardizedIncomeByCompanyId}
      reportedDataFn={getReportedIncomeByCompanyId}
      enableConversions={enableConversions}
    />
  );
};
