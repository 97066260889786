import { AnalyzerTables } from "../../types";

type CommonExportProps = {
  companyName: string;
  financialStatement: string;
  statementType: string;
  reportingCycle: string;
  financialYear: string;
  currency: string;
  unit?: Units;
  enableConversions?: boolean;
};

export type ExportToFileFinancialProps = CommonExportProps;

export type ExportToFileDebtCovenantsProps = CommonExportProps & {
  debtCovenantsPeriod?: string;
  debtCovenantsCurrentCovenantPeriod?: string;
};
export type ExportStatementsFileProps = CommonExportProps & {
  debtCovenantsPeriod?: string;
  debtCovenantsCurrentCovenantPeriod?: string;
};

export enum ExportToFileType {
  FinancialStatements,
  DebtStructureAndCovenants,
  PortfolioAnalyzer,
}

export type ExportToFileGlobalFiltersProps = {
  funds: MultiSelectItemType[];
  sectors: MultiSelectItemType[];
  countries: MultiSelectItemType[];
  parValueRange: (number | null)[];
  leverageRange: (number | null)[];
  revenueVsBudgetRange: (number | null)[];
  revenueVsLastYearRange: (number | null)[];
};

export type ExporToFileProps = {
  type: ExportToFileType;
  data: ReportSection | any;
  fileName: string;
  fileType: "txt" | "json" | "csv" | "xlsx" | "xml" | "css" | "html";
  showButton?: boolean;
  portfolioAnalyzerTab?: AnalyzerTables;
  financialStatementProps?: ExportToFileFinancialProps;
  debtCovenantsProps?: ExportToFileDebtCovenantsProps;
  globalFiltersProps?: ExportToFileGlobalFiltersProps;
};
