import { Button } from "@alterdomus-analytics/dna-ui";
import styled from "@emotion/styled";
import { Box, Card, CardHeader, Grid, Typography } from "@mui/material";

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PageWrapper = styled.div`
  width: 100%;
  margin-left: 78px;
`;

export const Wrapper = styled(Grid)`
  font-size: 0.875rem;
`;
export const Container = styled(Grid)`
  background-color: #ffffff;
  padding: 0;

  .MuiBox-root.css-1gsv261 > .MuiTabs-root {
    padding-left: 2.5rem;
    border-bottom: 1px solid #c3c9ce;
  }
`;
export const PageHeader = styled(Grid)`
  background-color: #ffffff;
  padding: 0.75rem 2.5rem 0rem 2.5rem;
`;
export const HeaderItem = styled(Grid)`
  font-size: 0.875rem;
`;
export const Title = styled.h1`
  font-weight: 300;
  font-size: 1.75rem;
  line-height: 2.5rem;
  margin-top: 0px;
  margin-bottom: 0px;
`;
export const TabGrid = styled(Grid)`
  min-height: 100vh;
  justify-content: flex-start;
  background-color: #fafafa;
  padding: 1rem 3rem 2rem;
  margin-top: 0px;
  margin-left: -1rem;
`;

export const TabGridItem = styled(Grid)`
  @media (min-width: 1536px) {
    max-width: 1648px;
  }
`;
export const ItemContainer = styled(Grid)``;
export const SubItemContainer = styled(Grid)`
  margin-bottom: 1rem;
`;
export const ChartContainer = styled(Box)`
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  padding: 2rem 1.5rem 1.5rem;
  // bottom-padding adjusted due to "HighCharts.com" title space. Revert to 2rem when not displayed
`;

export const EmptyCommentary = styled(Box)`
  padding: 1.5rem 1.5rem 2rem;
  display: grid;
  align-items: center;
  justify-content: center;
`;
export const IconBox = styled(Box)`
  padding-bottom: 1.5rem;
  display: flex;
  justify-content: center;
`;
export const TextBox = styled(Box)`
  display: grid;
  gap: 16px;
  text-align: center;
  .MuiTypography-h5 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  .MuiTypography-body1 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
`;
export const StyledButton = styled(Button)`
  padding: 8px 16px;
  border-radius: 100px;
  background-color: #1a50dd;
  text-transform: none;
`;
export const DownloadButton = styled(Button)`
  padding: 8px 16px;
  border-radius: 100px;
  text-transform: none;
  color: #1a50dd;
  border-color: #1a50dd;
  //FIXME amend to margin right 0.5rem when element is added to row
  margin-right: 0px;
`;
// Analyst Comments
export const CommentCard = styled(Card)`
  padding: 1.5rem 1.75rem;

  .MuiCardHeader-root {
    padding: 0rem 0.75rem 1rem 0rem;
  }
  .MuiCardContent-root {
    padding: 0rem;
    padding-bottom: 1rem;
  }
  .MuiCardContent-root:last-child {
    padding-bottom: 0.5rem;
  }
  .MuiCardActions-root {
    padding: 0rem;
  }
  .MuiCardHeader-root .MuiLink-root {
    color: #1a50dd;
    padding: 0.5rem 0.75rem;
    margin-right: 1rem;
  }

  .MuiTypography-root,
  .MuiTypography-root > * {
    font-family: "Inter", sans-serif;
    color: #292727;
  }
  .MuiTypography-body1 {
    font-size: 1rem;
  }
  .MuiTypography-h4 {
    font-weight: 600;
    font-size: 22px;
    line-height: 34px;
  }
  .MuiTypography-h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 34px;
  }
  .MuiTypography-body2 {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #586463;
  }
`;
export const StyledTertiaryButton = styled(Button)`
  padding: 8px 16px;
  color: #1a50dd;
  font-size: 12px;
  border-radius: 100px;
  border: none;
  background-color: #e8eeee;
  text-transform: none;
`;
export const StyledSecondaryButton = styled(Button)`
  padding: 8px 16px;
  color: #1a50dd;
  font-size: 12px;
  border-radius: 100px;
  border: 1px solid rgba(26, 80, 221, 0.6);
  background-color: #fff;
  text-transform: none;
`;
export const AnalystCommentButton = styled(Button)`
  padding: 0.5rem 0.5rem;
  color: #1a50dd;
  border-radius: 100px;
  border: none;
  background-color: #fff;
  text-transform: none;
  min-width: 0px;
  :not(:first-of-type) {
    margin-left: -0.185rem;
  }
`;
export const LoaderContainer = styled(Box)`
  height: 100vh;
  padding-top: 2rem;
  background-color: #fafafa;
`;
export const CommentLoader = styled(Box)`
  padding: 1.5rem;
  margin-bottom: 1rem;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  .MuiTypography-caption {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #586463;
  }
`;

export const CompanyPerformanceTitle = styled(Typography)`
  font-size: 22px;
  font-weight: 600;
  line-height: 34px;
`;

export const ChartsHeader = styled(CardHeader)`
  padding: 0;
  margin-top: 20px;
  margin-bottom: 20px;
`;
