import { FormControl, InputBase, MenuItem, styled } from "@mui/material";

export const StyledFormControl = styled(FormControl)({
  margin: 8,
  minWidth: 90,
});

export const StyledMenuItem = styled(MenuItem)({
  fontFamily: "Inter, sans-serif",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  paddingTop: "4px",
  paddingBottom: "4px",
});

export const BootstrapInput = styled(InputBase)(() => ({
  color: "#162859",
  fontFamily: "Inter, sans-serif",
  "label + &": {
    marginTop: "1rem",
  },
  "& .MuiInputBase-input": {
    borderRadius: 3,
    position: "relative",
    backgroundColor: "#ffffff",
    border: "1px solid #ced4da",
    fontSize: 14,
    lineHeight: "20px",
    padding: "6px 24px 6px 8px",
    fontFamily: [
      "Inter",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "& :focus": {
      borderRadius: 4,
      backgroundColor: "#E8EAEE",
      outline: 0,
      border: "none",
      boxShadow: "none",
    },
  },
  "& .MuiSelect-select.MuiSelect-standard.MuiInputBase-input": {
    paddingRight: "34px",
  },
  "& .MuiSelect-icon.MuiSelect-iconStandard": {
    marginRight: "13px",
  },
  "& :hover": {
    backgroundColor: "#E8EAEE",
  },
  "& :active": {
    backgroundColor: "#E8EAEE",
  },
  "& :focus": {
    backgroundColor: "#E8EAEE",
  },
}));
