import { Autocomplete } from "@alterdomus-analytics/dna-ui";
import { Box, FormControlLabel, Grid, Stack, styled } from "@mui/material";

export const StyledWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
`;

export const Wrapper = styled(Box)`
  background-color: #fafafa;
  width: 100%;
  margin-left: 78px;
`;

export const HeaderContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isSticky",
})<{ isSticky?: boolean }>(({ theme, isSticky }) => ({
  ...(isSticky && { position: "sticky", top: 0 }),
  padding: "2.5rem 2rem",
  borderBottom: "1px solid #C3C9CE",
  backgroundColor: theme.palette.common.white,
  zIndex: 100,
  "& .MuiBox-root": {
    "& .MuiTypography-h4": { fontSize: "1.75rem", lineHeight: "2.5rem" },
  },
}));

export const ContainerGrid = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: #fafafa;
  padding: 1.5rem 2rem;
`;

export const Container = styled(Grid)`
  background-color: #ffffff;
  padding: 1.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  width: 100%;
  @media (min-width: 1536px) {
    max-width: 1648px;
  }
`;

export const TabGrid = styled(Grid)`
  margin-top: 0px;
  margin-left: -16px;
`;

export const StyledStack = styled(Stack)`
  margin-bottom: 0.5rem;
  margin-top: -3.6rem;
  margin-left: auto;
  margin-right: -0.5rem;
`;

export const TypesGroupLabel = styled(FormControlLabel)`
  .MuiTypography-root {
    font-size: 14px;
    font-family: "Inter", sans-serif;
  }
  .MuiRadio-root {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export const LoaderContainer = styled(Box)`
  padding-top: 2.75rem;
  background-color: #f4f4f4;
`;

export const SearchBar = styled(Autocomplete)`
  width: calc(100% - 93px);
  & > div > div > fieldset {
    border-color: #b4b7b7;
  }
  & > div > div > input {
    font-size: 14px;
  }
`;
