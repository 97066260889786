import React from "react";
import { UnauthorizedPage } from "../../pages/UnauthorizedPage";
import { store } from "../../redux/store";

export const authGuard = (Component: React.ElementType) => () => {
  const authData = store.getState().app.user.auth;

  if (!authData || !authData.accessToken) {
    return <UnauthorizedPage />;
  }

  const account = store.getState().app.user.auth.account;
  if (!account) {
    return <UnauthorizedPage />;
  }

  return <Component />;
};
