import { DialogContent, Stack, IconButton } from "@mui/material";
import { X } from "phosphor-react";
import { ContentModalProps } from "./types";
import { StyledDialog, StyledDialogTitle } from "./styles";

export const ContentModal = ({ open, title, content, onClose }: ContentModalProps) => {
  return (
    <StyledDialog open={open} onClose={onClose}>
      <Stack direction={"row"} width={"100%"} alignItems={"flex-start"} minWidth={"32rem"}>
        <StyledDialogTitle children={title} />
        <DialogContent> {content} </DialogContent>
        <IconButton aria-label="close" onClick={onClose} sx={{ color: "#292727", marginLeft: "auto", padding: 0 }}>
          <X size={18} stroke="2px" />
        </IconButton>
      </Stack>
    </StyledDialog>
  );
};
