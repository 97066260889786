import { styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

export const StyledDataGrid = styled(DataGrid)(() => ({
  //row-class--${params.row.style}
  color: "#292727",
  webkitFontSmoothing: "auto",
  letterSpacing: "normal",
  fontFamily: "Inter, sans-serif",
  fontSize: "11px",
  borderRadius: 0,
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#F4F4F4",
    borderRadius: 0,
    fontSize: "12px",
    fontWeight: "500",
    "& .cvx-right--header": {
      textAlign: "right",
    },
    "& .header-title--italics": {
      fontStyle: "italic",
      paddingBottom: "13px",
      fontWeight: "400",
    },
    "& .group-header": {
      paddingLeft: 0,
      paddingRight: 0,
      textTransform: "uppercase",
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: "500",
      },
    },
    "& .currency-header": {
      fontStyle: "italic",
      paddingBottom: "0px",
    },
  },
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: "#F4F4F4",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: "1px solid #E6E6E6",
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: "1px solid #E6E6E6",
    "& .cvx-strong-border--bottom": {
      borderBottom: "1px solid #8F8F8F",
    },
  },
  "& .MuiDataGrid-columnHeaderTitle, .MuiDataGrid-cellContent": {
    whiteSpace: "normal",
  },
  "& .MuiDataGrid-sortIcon": {
    color: "#162859",
  },
  "& .MuiDataGrid-row.Mui-selected": {
    backgroundColor: "#F4F4F4",
  },
  "& .MuiDataGrid-cell": {
    color: "#292727",
    fontSize: "12px",
  },
  "& .MuiDataGrid-cell.cvx-strong-border--bottom": {
    borderBottom: "1px solid #8F8F8F",
  },
  "& .MuiDataGrid-cell.cvx-right--cell": {
    textAlign: "right",
  },
  "& .MuiDataGrid-cell.highlighted-cell": {
    fontWeight: "500",
    backgroundColor: "rgba(26, 80, 221, 0.15)",
    border: "0.5px solid #1A50DD",
  },
  "& .MuiDataGrid-cell.cvx-datapoint--cell": {
    fontSize: "14px",
    lineHeight: 1.35,
    padding: "0 5px 0 10px",
  },
  "& .row-class--reported-total, .row-class--reported-subtotal, .row-class--standard-total, .row-class--standard-subtotal": {
    borderBottom: "1px solid #8F8F8F",
    "&:hover": {
      backgroundColor: "#e6e6e6",
    },
    "& .MuiDataGrid-cell": {
      borderBottom: "1px solid #8F8F8F",
    },
    "& .MuiDataGrid-cell.cvx-datapoint--cell": {
      fontWeight: "600",
    },
  },
  "& .row-class--standard-subtotal, .row-class--standard-total": {
    backgroundColor: "#f3f3f4",
  },
  "& .row-class--reported-title, .row-class--standard-title, .row-class--standard-subheader": {
    backgroundColor: "#F3F3F4",
    textTransform: "uppercase",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "#e6e6e6",
    },
    "& .MuiDataGrid-cell": {
      borderRight: "0px",
      fontSize: "12px",
    },
  },
  "& .row-class--period-title, .row-class--standardized-title": {
    backgroundColor: "#3C3C3B",
    fontWeight: 500,
    "&:hover": {
      backgroundColor: "#3C3C3B",
    },
    "& .MuiDataGrid-cell": {
      color: "#ffffff",
      borderRight: "1px solid #3C3C3B",
      borderLeft: "1px solid #3C3C3B",
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
  "& .row-class--margin": {
    "& .MuiDataGrid-cell": {
      fontWeight: "400",
      fontStyle: "italic",
    },
    "& .MuiDataGrid-cell.cvx-datapoint--cell": {
      fontWeight: "400",
      fontStyle: "normal",
    },
  },
  "& .row-class--period-title.Mui-selected, .row-class--standardized-title.Mui-selected": {
    backgroundColor: "#3C3C3B",
    "&:hover": {
      backgroundColor: "#3C3C3B",
    },
  },
}));
