import { Stack, Typography } from "@mui/material";
import { Function } from "phosphor-react";
import { StyledDialogTitle } from "./styles";
import { getMonthName } from "./utils";
import { Units } from "../../types";

interface TraceabilityHeaderProps {
  data_point: string;
  finYear: number;
  month: number;
  currency: string;
  unit: Units;
}

export const TraceabilityHeader = ({ data_point, finYear, month, currency, unit }: TraceabilityHeaderProps) => {
  return (
    <StyledDialogTitle
      children={
        <Stack direction={"row"} width={"100%"} alignItems={"flex-start"}>
          <Stack>
            <Typography display={"flex"} variant="caption" component="div" mr={1.5} mb={0.5} alignItems={"center"}>
              <Function size={14} style={{ marginRight: "0.25rem" }} />
              {"BREAKDOWN OF"}
            </Typography>
            <Typography variant="h3" component="div" mr={1.5}>
              {data_point}
            </Typography>
            <Typography variant="subtitle1" component="div">
              {`${getMonthName(month)} ${finYear}`} - {currency} - {unit}
            </Typography>
          </Stack>
        </Stack>
      }
    />
  );
};
