import { useEffect } from "react";
import { Select, SelectChangeEvent, SelectProps as MUISelectProps } from "@mui/material";
import { CaretDown } from "phosphor-react";
import { BootstrapInput, StyledFormControl, StyledMenuItem } from "./styles";

interface SelectProps extends MUISelectProps {
  options: any[];
  value: any;
  onValueChange: (x: any) => void;
}

export default function BasicSelect({ value, options, onValueChange }: SelectProps) {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    onValueChange(event);
  };

  useEffect(() => {
    if (!options.includes(value)) options.push(value);
  });

  return (
    <StyledFormControl size="small" variant="standard">
      <Select
        labelId="table-select-label"
        id="table-select"
        value={value}
        onChange={handleChange}
        input={<BootstrapInput />}
        IconComponent={CaretDown}
        MenuProps={{
          variant: "menu",
          sx: {
            "& .MuiList-root": {
              paddingTop: "8px",
              paddingBottom: "8px",
              "& .Mui-selected": {
                backgroundColor: "#E8EAEE",
                fontWeight: 600,
              },
            },
          },
        }}
      >
        {options.map((item, key) => {
          return (
            <StyledMenuItem key={key} value={item}>
              FY {item}
            </StyledMenuItem>
          );
        })}
      </Select>
    </StyledFormControl>
  );
}
