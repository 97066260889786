import { useState } from "react";
import { Divider } from "@mui/material";
import { RangeSelect, RangeSelectHeader, RangeSelectModal } from "./styles";

const min = 0;
const max = 70;
const step = 10;

export type PerformanceFilterSliderProps = {
  title: string;
  rangeSelection: (number | null)[];
  marks: { value: number; label: string }[];
  onSelect: (value: number[]) => void;
};

function valueLabelFormat(value: number, marks: { value: number; label: string }[]) {
  if (!marks.length) return "";

  const index = value / step;
  const mark = marks[index];

  return mark.label;
}

function calculateValue(value: number[]) {
  return [value[0], value[1]];
}

function convertToSliderValues(rangeSelection: (number | null)[], marks: { value: number; label: string }[]) {
  let lowerIndex = 0;
  if (rangeSelection[0] !== null) {
    lowerIndex = marks.indexOf(marks.find((m) => m.value === rangeSelection[0]) as { value: number; label: string }) * 10;
  }

  let upperIndex = 70;
  if (rangeSelection[1] !== null) {
    upperIndex = marks.indexOf(marks.find((m) => m.value === rangeSelection[1]) as { value: number; label: string }) * 10;
  }

  return [lowerIndex, upperIndex];
}

export const PerformanceFilterSlider = ({ title, rangeSelection, marks, onSelect }: PerformanceFilterSliderProps) => {
  const [value, setValue] = useState<number[]>(convertToSliderValues(rangeSelection, marks));
  const mappedMarks = marks.map((mark, index) => {
    const value = index * 10;
    return { value: value, label: mark.label };
  });

  const handleChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      setValue(newValue);
      onSelect(calculateValue(newValue));
    }
  };

  const showValueLabel = () => {
    if (!value.length || !mappedMarks.length) return "off";

    return value[0] === mappedMarks[0].value || value[1] === mappedMarks[mappedMarks.length - 1].value ? "auto" : "on";
  };

  return (
    <RangeSelectModal>
      <RangeSelectHeader>{`${title}`}</RangeSelectHeader>
      <Divider></Divider>
      <RangeSelect
        disableSwap={true}
        value={value}
        min={min}
        step={step}
        marks={mappedMarks}
        max={max}
        getAriaValueText={(value: number) => valueLabelFormat(value, marks)}
        valueLabelFormat={(value: number) => valueLabelFormat(value, marks)}
        onChange={handleChange}
        valueLabelDisplay={showValueLabel()}
        aria-labelledby="non-linear-slider"
      />
    </RangeSelectModal>
  );
};

export default PerformanceFilterSlider;
