import { styled } from "@mui/material";
import { ToggleButtonGroup } from "@mui/material";

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  margin: "8px 8px",
  "& .MuiToggleButton-root": {
    color: "#454545",
    webkitFontSmoothing: "auto",
    letterSpacing: "normal",
    fontFamily: "Inter, sans-serif",
    fontSize: "11px",
    lineHeight: "16px",
    border: "1px solid #E9E9E9",
    borderRadius: "4px",
    padding: "0.5rem 0.75rem",
  },
  "& .MuiToggleButton-root.Mui-selected": {
    color: "#313636",
    backgroundColor: "#E9E9E9",
    fontWeight: "bold",
  },
  "& .MuiToggleButton-root.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.54)",
  },
}));
