import { ToolbarSlot, TransformToolbarSlot } from "@react-pdf-viewer/toolbar";

const turnOffFeature = [
  "Download",
  "SwitchTheme",
  "Print",
  "Open",
  "DownloadMenuItem",
  "EnterFullScreen",
  // more actions menu options
  "GoToFirstPageMenuItem",
  "GoToLastPageMenuItem",
  "GoToNextPageMenuItem",
  "GoToPreviousPageMenuItem",
  "OpenMenuItem",
  "PrintMenuItem",
  "RotateBackwardMenuItem",
  "RotateForwardMenuItem",
  "ShowPropertiesMenuItem",
  "SwitchScrollModeMenuItem",
  "SwitchThemeMenuItem",
];

export const toolbarOptions: TransformToolbarSlot = (slot: ToolbarSlot) => ({
  ...slot,
  ...turnOffFeature.reduce<Record<string, () => JSX.Element>>((acc, key) => {
    acc[key] = () => <></>;
    return acc;
  }, {}),
});
