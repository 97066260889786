import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { getTraceabilityData } from "../../services/traceability";
import { FinancialStatements, ReportingPeriods, TraceabilityDetails, TraceabilityParam, Units } from "../../types";
import { convertTableValues } from "../../utils/auxiliary";
import { FullscreenCircularProgress } from "../FullscreenCircularProgress";
import { TraceabilityHeader } from "./TraceabilityHeader";
import TraceabilityRow from "./TraceabilityRow";
import { StyledAccordion, StyledAccordionSummary, StyledAccordionDetails } from "./styles";

type DataTraceabilityProps = {
  companyId: number;
  statement: FinancialStatements;
  period: ReportingPeriods;
  company_period: ReportingPeriods;
  dataPoint: string;
  finYear: number;
  month: number;
  currency: string;
  unit: Units;
  enableConversions?: boolean;
};

const DataTraceability = ({
  companyId,
  statement,
  period,
  company_period,
  dataPoint,
  finYear,
  month,
  currency,
  unit,
  enableConversions,
}: DataTraceabilityProps) => {
  const [traceabllityData, setTraceabllityData] = useState<TraceabilityDetails>();
  const [detailData, setDetailData] = useState<TraceabilityDetails | null>();
  const [expandedAccordion, setExpandedAccordion] = useState<string | false>(false);
  const [loading, isLoading] = useState(true);
  const [loadingDetails, setLoadingDetails] = useState(true);

  const handleExpandRow = (universal_line_item: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setLoadingDetails(true);
    setDetailData(null);
    setExpandedAccordion(isExpanded ? universal_line_item : false);
    if (isExpanded) {
      getTraceabilityData<TraceabilityDetails>({
        companyId: companyId,
        statement: statement,
        period: period,
        data_point: universal_line_item,
        finYear: finYear,
        month: month,
      }).then((data) => {
        setDetailData(data);
        setLoadingDetails(false);
      });
    }
  };

  useEffect(() => {
    getTraceabilityData<TraceabilityDetails>({
      companyId: companyId,
      statement: statement,
      period: period,
      data_point: dataPoint,
      finYear: finYear,
      month: month,
    }).then((data) => {
      convertDadaBasedOnUnit(data).then((result) => {
        setTraceabllityData(result);
        isLoading(false);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPoint]);

  const convertDadaBasedOnUnit = async (data: TraceabilityDetails) => {
    const newConvertedParams: TraceabilityParam[] = [];

    for await (const p of data.params) {
      newConvertedParams.push({ ...p, value: await convertTableValues(p.value, unit, enableConversions, p.style) });
    }

    data.value = await convertTableValues(data.value, unit, enableConversions, data.style);
    data.params = newConvertedParams;
    return data;
  };

  if (loading) return <FullscreenCircularProgress />;

  return (
    <div>
      <TraceabilityHeader
        data_point={traceabllityData?.data_point!}
        currency={currency}
        unit={unit}
        finYear={finYear}
        month={month}
      />
      <Divider />
      {traceabllityData &&
        traceabllityData.params.map((x) => {
          return (
            <Box key={x.index}>
              {x.is_mapping ? (
                <TraceabilityRow
                  data_point={x.name}
                  value={x.value}
                  operator={x.operator}
                  is_mapping={x.is_mapping}
                  companyId={companyId}
                  uuid={x.formula_is_mapping ? x.formula : x.universal_line_item}
                  month={month}
                  finYear={finYear}
                  statement={x.formula_statement ? x.formula_statement : statement}
                  period={period}
                  company_period={company_period}
                  rowStyle={x.style}
                />
              ) : (
                <StyledAccordion
                  elevation={0}
                  expanded={expandedAccordion === x.universal_line_item}
                  onChange={handleExpandRow(x.universal_line_item)}
                  id={`${x.name.replace(" ", "-")}-panel`}
                >
                  <StyledAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${x.name.replace(" ", "-")}-content`}
                    id={`${x.name.replace(" ", "-")}-header`}
                    sx={{
                      padding: 0,
                      "& :before": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <TraceabilityRow
                      data_point={x.name}
                      value={x.value}
                      operator={x.operator}
                      is_mapping={x.is_mapping}
                      companyId={companyId}
                      uuid={x.universal_line_item}
                      month={month}
                      finYear={finYear}
                      statement={x.formula_statement ? x.formula_statement : statement}
                      period={period}
                      company_period={company_period}
                      rowStyle={x.style}
                    />
                  </StyledAccordionSummary>
                  <>
                    {loadingDetails ? (
                      <FullscreenCircularProgress />
                    ) : (
                      <>
                        {detailData && detailData.params.length > 0 ? (
                          <StyledAccordionDetails sx={{ bgcolor: "#4D798E1A" }}>
                            {detailData.params.map((i) => {
                              return (
                                <TraceabilityRow
                                  data_point={i.name}
                                  value={i.value}
                                  operator={i.operator}
                                  is_mapping={i.is_mapping}
                                  key={i.index}
                                  companyId={companyId}
                                  uuid={i.universal_line_item}
                                  month={month}
                                  finYear={finYear}
                                  statement={i.formula_statement ? i.formula_statement : statement}
                                  period={period}
                                  company_period={company_period}
                                  rowStyle={x.style}
                                />
                              );
                            })}
                            <TraceabilityRow
                              data_point={detailData.data_point}
                              value={detailData.value}
                              operator={"="}
                              is_mapping={false}
                              companyId={companyId}
                              uuid={detailData.universal_line_item}
                              month={month}
                              finYear={finYear}
                              statement={statement}
                              period={period}
                              company_period={company_period}
                              rowStyle={x.style}
                            />
                          </StyledAccordionDetails>
                        ) : (
                          <>{"no data"}</>
                        )}
                      </>
                    )}
                  </>
                </StyledAccordion>
              )}
            </Box>
          );
        })}
      <Divider flexItem sx={{ marginBottom: "1.125rem" }} />
      {traceabllityData && (
        <TraceabilityRow
          data_point={traceabllityData.data_point!}
          value={traceabllityData.value!}
          operator={"="}
          is_mapping={false}
          companyId={companyId}
          uuid={traceabllityData.universal_line_item}
          month={month}
          finYear={finYear}
          statement={statement}
          period={period}
          company_period={company_period}
          rowStyle={traceabllityData.style}
          sx={{
            "& .MuiBox-root": {
              "& .MuiTypography-root": {
                fontWeight: 600,
                paddingRight: "0.5rem",
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default DataTraceability;
