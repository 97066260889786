import styled from "@emotion/styled";
import { Grid } from "@mui/material";

export const Wrapper = styled(Grid)`
  background-color: #f5f5f5;
  padding: 1.5rem;
  margin-top: 0px;
  min-height: 100vh;
`;

export const Container = styled(Grid)``;
export const PageHeader = styled(Grid)`
  background-color: #ffffff;
  padding: 1.125rem 1.5rem 0rem 1.5rem;
  border: 1px solid #e2e2e2;
  border-bottom: none;
`;
export const HeaderItem = styled(Grid)`
  font-size: 0.875rem;
`;
export const Title = styled.h1`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 0px;
  margin-bottom: 0px;
`;
