import styled from "styled-components";
import { Select } from "@mui/material";

export const CustomSelect = styled(Select)`
  background-color: #f1faff;
  font-size: 14px;
  min-width: 100px;
  height: 39px;
  border-radius: 2px;
  padding: 5px 7px 5px 10px;
  & .MuiSelect-select {
    color: #162859;
    background-color: #f1faff !important;
  }
  &::before {
    display: none;
  }
  &::after {
    display: none;
  }
`;
