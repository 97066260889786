import { styled as styledMaterial } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";

export const StyledDataGrid = styledMaterial(DataGrid)({
  fontWeight: 400,
  color: "#292727",
  border: "1px solid #e6e6e6",
  borderRadius: 0,
  fontFamily: "Inter, sans-serif",
  fontSize: "11px",
  webkitFontSmoothing: "auto",
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#F4F4F4",
    borderRadius: 0,
    textTransform: "uppercase",
    fontSize: "11px",
    "& .cvx-right--header, .cvx-right--header-lowercase": {
      textAlign: "right",
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        width: "100%",
        justifyContent: "flex-end",
      },
    },
    ".MuiDataGrid-columnHeaderTitleContainerContent": {
      fontWeight: 500,
    },
    "& .cvx-right--header-lowercase": {
      textTransform: "none",
    },
    "& .cvx-double-border--right": {
      borderRight: "3px solid #E6E6E6",
    },
    "& .group-header": {
      paddingLeft: 0,
      paddingRight: 0,
      textTransform: "uppercase",
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: "500",
      },
    },
    "& .MuiDataGrid-columnHeader--emptyGroup": {
      borderRight: "3px solid #E6E6E6",
    },
  },
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: "#F4F4F4",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: "1px solid #E6E6E6",
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: "1px solid #E6E6E6",
  },
  "& .MuiDataGrid-columnHeaderTitle, .MuiDataGrid-cellContent": {
    whiteSpace: "normal",
    lineHeight: 1.43,
  },
  "& .MuiDataGrid-sortIcon": {
    color: "#162859",
  },
  "& .MuiDataGrid-row.Mui-selected": {
    backgroundColor: "#F4F4F4",
  },
  "& .MuiDataGrid-cell": {
    color: "#292727",
    padding: "0.563rem 0.625rem",
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell.cvx-double-border--right": {
    borderRight: "3px solid #E6E6E6",
  },
  "& .MuiDataGrid-cell.table-font--large": {
    fontSize: "12px",
    padding: "1.125rem 0.625rem",
  },
  "& .MuiDataGrid-cell.table-font--large-italic": {
    fontSize: "12px",
    fontWeight: 500,
    fontStyle: "italic",
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
  },
});

export const ExportToFileWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: end;
  top: -40px;
  height: 0;
`;
