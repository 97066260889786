import { DebtCovenantsStatement } from "../../../../components/DebtCovenantsStatement";
import {
  getCovenantsFilters,
  getCurrentCovenantsByCompanyId,
  getReportedCovenantsByCompanyId,
  getStandardizedCovenantsByCompanyId,
} from "../../../../services/covenants";
import { DebtCovenantsStatements, FinancialStatements } from "../../../../types";
import { CovenantsProps } from "./types";

export const Covenants = ({
  periodType,
  companyId,
  companyName,
  currency,
  unit,
  netTotalLeverageData,
  interestCoverData,
  cashCoverData,
  enableConversions,
}: CovenantsProps) => {
  return (
    <>
      <DebtCovenantsStatement
        statement={FinancialStatements.COVENANTS}
        currentStatement={DebtCovenantsStatements.CURRENT_COVENANTS}
        showSelector={true}
        companyId={companyId}
        companyName={companyName}
        periodType={periodType}
        currency={currency}
        filterDataFn={getCovenantsFilters}
        standardizedDataFn={getStandardizedCovenantsByCompanyId}
        reportedDataFn={getReportedCovenantsByCompanyId}
        currentCovDataFn={getCurrentCovenantsByCompanyId}
        currentDataFn={getCurrentCovenantsByCompanyId}
        cashCoverData={cashCoverData}
        interestCoverData={interestCoverData}
        netTotalLeverageData={netTotalLeverageData}
        unit={unit}
        enableConversions={enableConversions}
      />
    </>
  );
};
