import styled from "styled-components";
import { Slider, Box, Typography } from "@mui/material";

export const RangeSelectModal = styled(Box)`
  width: 500px;
  padding: 24px 0px 0px 0px;
  gap: 16px;
`;

export const RangeSelectHeader = styled(Typography)`
  padding: 0px 24px 20px 24px;
  fontweight: 400;
  fontsize: 16px;
  lineheight: 24px;
  fontfamily: Inter;
  gap: 10px;
`;

export const RangeSelect = styled(Slider)`
  width: 434px;
  margin: 48px 32px 40px 32px;
  .MuiSlider-track {
    color: #1a50dd;
    border: none;
    height: 3px;
  }
  .MuiSlider-rail {
    color: #cdcdcd;
    height: 2px;
    border-radius: 0;
  }
  .MuiSlider-thumb {
    color: white;
    height: 17px;
    width: 17px;
    border: 1px solid #1a50dd;
    &:hover {
      box-shadow: none !important;
    }
    &:focus {
      box-shadow: none !important;
    }
    &.Mui-focusVisible {
      box-shadow: none !important;
    }
  }
  .MuiSlider-valueLabel {
    font-family: Inter;
    font-size: 10px;
    font-weight: 600;
    line-height: 20px;
    border-radius: 4px !important;
    background-color: #323030;
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
    &::before {
      display: none;
    }
  }
  .MuiSlider-mark {
    border-radius: 0px;
    border: none;
    height: 7px;
    width: 1px;
    color: #cdcdcd;
    background-color: #cdcdcd;
    margin-top: 7px;
  }
  .MuiSlider-markLabel {
    font-weight: 400;
    line-height: 18px;
    font-size: 10px;
    font-family: Inter;
    color: #979797;
    margin-top: 5px;
    &[data-index="0"] {
      transform: none;
    }
    &[data-index="${({ max }) => (max ? max / 10 : max)}"] {
      transform: translateX(-100%);
    }
  }
`;
