import { Box } from "@mui/material";
import { formatValue } from "../../../utils/auxiliary";
import { DataFormatTypes } from "../../../types";

type FRCellProps = {
  value: number;
  style?: string;
};

export const FRCell = ({ value, style }: FRCellProps) => {
  let outputValue = String(value);
  if (style === "margin") {
    outputValue = formatValue(value, DataFormatTypes.margin);
  } else {
    if (value !== null || value !== undefined) outputValue = formatValue(value, DataFormatTypes.none);
  }

  if (value > 0) {
    return (
      <Box component="span" color={"#292727"}>
        {outputValue}
      </Box>
    );
  } else if (value === null) {
    return (
      <Box component="span" color={"#292727"}>
        -
      </Box>
    );
  }

  return (
    <Box component="span" color={"#9F372F"}>
      {outputValue}
    </Box>
  );
};
