import { Box } from "@mui/material";
import { GridCallbackDetails, GridColumns, GridSortModel, GridValidRowModel } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { getCovenantsByFilters, getDealsByFilters, getRevenueEbitdaByFilters } from "../../services/deals";
import { AnalyzerRow, AnalyzerTables, IRow, PageMetaDataType, ReportingPeriods, RevEbitdaViews } from "../../types";
import { CustomPagination } from "../CustomPagination";
import { Loader } from "../Loader";
import { MultiSelectItemType } from "../MultiSelect";
import { StyledDataGrid } from "./styles";
import {
  columnGroupingModel,
  columnGroupingModelCovenants,
  columns,
  getSecondTabColumns,
  tabColumns,
  thirdTabColumns,
} from "./table.config";

export interface OverviewTableProps {
  loaded: boolean;
  tab: AnalyzerTables;
  view?: RevEbitdaViews;
  portfolioId: string;
  portfolioType: string;
  funds: MultiSelectItemType[];
  sectors: MultiSelectItemType[];
  countries: MultiSelectItemType[];
  search: string;
  parValueRange: (number | null)[];
  leverageRange: (number | null)[];
  revenueVsBudgetRange: (number | null)[];
  revenueVsLastYearRange: (number | null)[];
  setTabCtxFn?: (t: AnalyzerTables) => void;
  period?: ReportingPeriods;
  getExcelExportDetails?: (details: IRow[] | AnalyzerRow[]) => void;
}

const switchColumns = (selectedTable: AnalyzerTables, view?: RevEbitdaViews): GridColumns<GridValidRowModel> => {
  const secondTabColumns = getSecondTabColumns(view ? view : RevEbitdaViews.PERCENTAGE);
  const overviewColumns = [...columns, ...tabColumns];
  const revEbitdaColumns = [...columns, ...secondTabColumns];
  const covenantsColumns = [...columns, ...thirdTabColumns];

  switch (selectedTable) {
    case AnalyzerTables.OVERVIEW:
      return overviewColumns;
    case AnalyzerTables.REVENUE_EBITDA:
      return revEbitdaColumns;
    case AnalyzerTables.COVENANTS:
      return covenantsColumns;
    default:
      return overviewColumns;
  }
};

export const OverviewTable = ({
  loaded,
  tab,
  view,
  portfolioId,
  portfolioType,
  funds,
  sectors,
  countries,
  search,
  parValueRange,
  leverageRange,
  revenueVsBudgetRange,
  revenueVsLastYearRange,
  period,
  setTabCtxFn,
  getExcelExportDetails,
}: OverviewTableProps) => {
  const ROWS_PER_PAGE = 20;
  const [rows, setRows] = useState<IRow[] | AnalyzerRow[]>([]);
  const [pageMetaData, setPageMetaData] = useState<PageMetaDataType>();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [currentSortModel, setCurrentSortModel] = useState<GridSortModel>();

  const tableColumns: GridColumns<GridValidRowModel> = switchColumns(tab, view);

  const setRowsAndPageMetaData = async (page: number, sortModel?: GridSortModel) => {
    setCurrentPage(page);
    switch (tab) {
      case AnalyzerTables.OVERVIEW:
        const deals = await getDealsByFilters(
          portfolioId,
          page,
          funds,
          sectors,
          countries,
          search,
          parValueRange,
          leverageRange,
          revenueVsBudgetRange,
          revenueVsLastYearRange,
          sortModel
        );
        setExcelRows(deals.rows);
        setRows(deals.rows);
        setPageMetaData(deals.meta);
        break;
      case AnalyzerTables.REVENUE_EBITDA:
        const revEbitas = await getRevenueEbitdaByFilters(
          portfolioId,
          page,
          funds,
          sectors,
          countries,
          search,
          parValueRange,
          leverageRange,
          revenueVsBudgetRange,
          revenueVsLastYearRange,
          sortModel,
          period
        );
        setExcelRows(revEbitas.rows);
        setRows(revEbitas.rows);
        setPageMetaData(revEbitas.meta);
        break;
      case AnalyzerTables.COVENANTS:
        const covenants = await getCovenantsByFilters(
          portfolioId,
          page,
          funds,
          sectors,
          countries,
          search,
          parValueRange,
          leverageRange,
          revenueVsBudgetRange,
          revenueVsLastYearRange,
          sortModel
        );
        setExcelRows(covenants.rows);
        setRows(covenants.rows);
        setPageMetaData(covenants.meta);
        break;
    }
    setLoading(false);
  };

  const changePage = async (page: number, details: GridCallbackDetails<any>) => {
    setLoading(true);
    const nextPage = page + 1;
    setCurrentPage(nextPage);
    await setRowsAndPageMetaData(nextPage, currentSortModel);
  };

  const handleSortModelChange = async (model: GridSortModel) => {
    setLoading(true);
    setCurrentSortModel(model);
    setRowsAndPageMetaData(currentPage, model);
  };
  const setExcelRows = (rows: IRow[] | AnalyzerRow[]) => {
    if (getExcelExportDetails) {
      getExcelExportDetails(rows);
    }
  };
  useEffect(() => {
    setTabCtxFn!(tab);
    setLoading(true);
    setRowsAndPageMetaData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    portfolioId,
    funds,
    sectors,
    countries,
    search,
    parValueRange,
    leverageRange,
    revenueVsBudgetRange,
    revenueVsLastYearRange,
    period,
    tab,
  ]);

  if (loading) return <Loader />;
  return (
    <>
      <div style={{ display: "flex", width: "100%" }}>
        <Box sx={{ flexGrow: 1 }}>
          <StyledDataGrid
            autoHeight
            disableColumnSelector
            headerHeight={tab === AnalyzerTables.OVERVIEW ? 50 : tab === AnalyzerTables.COVENANTS ? 40 : 32}
            experimentalFeatures={{ columnGrouping: true }}
            rows={rows}
            getRowHeight={() => "auto"}
            getEstimatedRowHeight={() => 54}
            rowsPerPageOptions={[ROWS_PER_PAGE]}
            rowCount={pageMetaData?.itemCount}
            columns={tableColumns}
            columnGroupingModel={tab === AnalyzerTables.REVENUE_EBITDA ? columnGroupingModel : columnGroupingModelCovenants}
            pageSize={ROWS_PER_PAGE}
            paginationMode="server"
            sortingMode="server"
            sortModel={currentSortModel}
            onSortModelChange={(model) => handleSortModelChange(model)}
            onPageChange={changePage}
            disableColumnMenu={true}
            disableSelectionOnClick={true}
            components={{ Pagination: CustomPagination }}
            initialState={{ pagination: { page: currentPage - 1 } }}
          />
        </Box>
      </div>
    </>
  );
};
