import {
  GridColDef,
  GridColumnGroupHeaderParams,
  GridColumnGroupingModel,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { CovenantsCell } from "./CovenantsCell";
import { DebtCell } from "./DebtCell";
import { formatTableDate } from "../../utils/formatDate";
import { Units } from "../../types";

const getColumnGroupingModelCov = (currentPeriod: string) => {
  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: `FY`,
      headerAlign: "center",
      headerClassName: "group-header",
      renderHeaderGroup: (params: GridColumnGroupHeaderParams) => {
        return <span e2e-test-id={"statement-current-period-value"}>{currentPeriod}</span>;
      },
      children: [
        { field: "datapoint_name" },
        { field: "netTotalLeverage" },
        { field: "netSeniorLeverage" },
        { field: "interestCover" },
        { field: "cashFlowCover" },
      ],
    },
  ];
  return columnGroupingModel;
};

const getColumnGroupingModelDebt = (currentPeriod: string) => {
  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: `amount`,
      headerAlign: "center",
      headerClassName: "group-header",
      headerName: "Amount",
      children: [{ field: "originalAmount" }, { field: "currentAmount" }],
    },
    {
      groupId: `margin`,
      headerAlign: "center",
      headerClassName: "group-header",
      headerName: "Margin",
      children: [{ field: "originalMargin" }, { field: "currentMargin" }],
    },
  ];
  return columnGroupingModel;
};

export const getCovenantsColumns = (currentPeriod: string, unit: Units, currency: string) => {
  const COVcolumns: GridColDef[] = [
    {
      field: "datapoint_name",
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <div e2e-test-id={"statement-currency"}>
            {currency} <span e2e-test-id={"statement-unit"}>{unit}</span>
          </div>
        );
      },
      cellClassName: "cvx-datapoint--cell",
      headerClassName: "header-title--italics",
      width: 320,
      sortable: false,
    },
    {
      field: "netTotalLeverage",
      headerName: "Net Total Leverage",
      headerClassName: "cvx-right--header",
      align: "right",
      flex: 0.9,
      minWidth: 244,
      sortable: false,
      cellClassName: "table-font--large",
      renderCell: (params: GridRenderCellParams) => {
        return <CovenantsCell value={params.value} name={params.row.datapoint_name} />;
      },
    },
    {
      field: "netSeniorLeverage",
      headerName: "Net Senior Leverage",
      headerClassName: "cvx-right--header",
      align: "right",
      flex: 0.9,
      minWidth: 244,
      sortable: false,
      cellClassName: "table-font--large",
      renderCell: (params: GridRenderCellParams) => {
        return <CovenantsCell value={params.value} name={params.row.datapoint_name} />;
      },
    },
    {
      field: "interestCover",
      headerName: "Interest Cover",
      headerClassName: "cvx-right--header",
      align: "right",
      flex: 0.9,
      minWidth: 244,
      sortable: false,
      cellClassName: "table-font--large",
      renderCell: (params: GridRenderCellParams) => {
        return <CovenantsCell value={params.value} name={params.row.datapoint_name} />;
      },
    },
    {
      field: "cashFlowCover",
      headerName: "Cash Flow Cover",
      headerClassName: "cvx-right--header",
      align: "right",
      flex: 0.9,
      minWidth: 244,
      sortable: false,
      cellClassName: "table-font--large",
      renderCell: (params: GridRenderCellParams) => {
        return <CovenantsCell value={params.value} name={params.row.datapoint_name} />;
      },
    },
  ];
  return COVcolumns;
};

export const getDebtColumns = (currentPeriod: string, currency: string, unit: Units, lastYearsPeriod?: string) => {
  const DEBTcolumns: GridColDef[] = [
    {
      field: "datapoint_name",
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <div e2e-test-id={"statement-currency"}>
            {currency} <span e2e-test-id={"statement-unit"}>{unit}</span>
          </div>
        );
      },
      cellClassName: "cvx-datapoint--cell",
      headerClassName: "header-title--italics",
      width: 320,
      sortable: false,
    },
    {
      field: "originalAmount",
      renderHeader: (params: GridColumnHeaderParams) => {
        //return <span e2e-test-id={"original-period"}>Original: {lastYearsPeriod}</span>;
        return <span e2e-test-id={"original-period"}>Original</span>;
      },
      headerClassName: "cvx-right--header-lowercase",
      align: "right",
      flex: 0.9,
      minWidth: 195,
      sortable: false,
      cellClassName: "table-font--large",
      renderCell: (params: GridRenderCellParams) => {
        return <DebtCell value={params.value} name={params.row.datapoint_name} style={params.row.style} />;
      },
    },
    {
      field: "currentAmount",
      renderHeader: (params: GridColumnHeaderParams) => {
        return <span e2e-test-id={"current-period"}>Current: {currentPeriod}</span>;
      },
      headerClassName: "cvx-right--header-lowercase",
      align: "right",
      flex: 0.9,
      minWidth: 195,
      sortable: false,
      cellClassName: "table-font--large",
      renderCell: (params: GridRenderCellParams) => {
        return <DebtCell value={params.value} name={params.row.datapoint_name} style={params.row.style} />;
      },
    },
    {
      field: "originalMargin",
      renderHeader: (params: GridColumnHeaderParams) => {
        // return <span e2e-test-id={"original-period"}>Original: {lastYearsPeriod}</span>;
        return <span e2e-test-id={"original-period"}>Original</span>;
      },
      headerClassName: "cvx-right--header-lowercase",
      align: "right",
      flex: 0.9,
      minWidth: 195,
      sortable: false,
      cellClassName: "table-font--large-italic",
      renderCell: (params: GridRenderCellParams) => {
        return <DebtCell value={params.value} name={params.row.datapoint_name} field={params.field} style={params.row.style} />;
      },
    },
    {
      field: "currentMargin",
      renderHeader: (params: GridColumnHeaderParams) => {
        return <span e2e-test-id={"current-period"}>Current: {currentPeriod}</span>;
      },
      headerClassName: "cvx-right--header-lowercase",
      align: "right",
      flex: 0.9,
      minWidth: 195,
      sortable: false,
      cellClassName: "table-font--large-italic",
      renderCell: (params: GridRenderCellParams) => {
        return <DebtCell value={params.value} name={params.row.datapoint_name} field={params.field} style={params.row.style} />;
      },
    },
    {
      field: "maturityDate",
      headerName: "Maturity Date",
      headerClassName: "cvx-right--header",
      align: "right",
      flex: 0.9,
      minWidth: 195,
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        if (params.value == null) {
          return "";
        }
        return `${formatTableDate(new Date(params.value))}`;
      },
    },
  ];
  return DEBTcolumns;
};

export const columnGroupingModelCov = (
  currentPeriod: string //FIXME replace with period + year when filter is added
) => getColumnGroupingModelCov(currentPeriod);

export const columnGroupingModelDebt = (
  currentPeriod: string //FIXME replace with period + year when filter is added
) => getColumnGroupingModelDebt(currentPeriod);

// Static row data
export const CVrows = [
  {
    id: 1,
    datapoint_name: "Credit Vision Calculations",
    netTotalLeverage: 1.5,
    netSeniorLeverage: 15.0,
    interestCover: 5.5,
    cashFlowCover: 1.0,
  },
  {
    id: 2,
    datapoint_name: "Company Case",
    netTotalLeverage: 1.5,
    netSeniorLeverage: 15.0,
    interestCover: 5.5,
    cashFlowCover: 1.0,
  },
  {
    id: 3,
    datapoint_name: "Compliance Certificate",
    netTotalLeverage: 1.5,
    netSeniorLeverage: 15.0,
    interestCover: 5.5,
    cashFlowCover: 1.0,
  },
  {
    id: 4,
    datapoint_name: "Covenant Test",
    netTotalLeverage: 1.5,
    netSeniorLeverage: 15.0,
    interestCover: 5.5,
    cashFlowCover: 1.0,
  },
  {
    id: 5,
    datapoint_name: "Headroom vs Covenant (%)",
    netTotalLeverage: 1.5,
    netSeniorLeverage: 15.0,
    interestCover: 5.5,
    cashFlowCover: 1.0,
    style: "reported-subtotal",
  },
];

export const DSrows = [
  {
    id: 1,
    datapoint_name: "drawn RCF / Capex",
    originalAmount: null,
    currentAmount: 6.0,
    originalMargin: null,
    currentMargin: 3.0,
    maturityDate: " ",
  },
  {
    id: 2,
    datapoint_name: "Term Loan",
    originalAmount: 54.5,
    currentAmount: 54.5,
    originalMargin: 6.5,
    currentMargin: 7.0,
    maturityDate: "AUG 2025",
  },
  {
    id: 3,
    datapoint_name: "Finance Leases",
    originalAmount: 54.5,
    currentAmount: 54.5,
    originalMargin: 6.5,
    currentMargin: 7.0,
    maturityDate: "AUG 2025",
  },
  {
    id: 4,
    datapoint_name: "Senior C",
    originalAmount: null,
    currentAmount: 6.0,
    originalMargin: null,
    currentMargin: 3.0,
    maturityDate: " ",
  },
  {
    id: 5,
    datapoint_name: "Other Senior Debt",
    originalAmount: null,
    currentAmount: 6.0,
    originalMargin: null,
    currentMargin: 3.0,
    maturityDate: " ",
  },
  {
    id: 6,
    datapoint_name: "Total Senior Debt",
    originalAmount: 56.6,
    currentAmount: 61.8,
    originalMargin: null,
    currentMargin: null,
    maturityDate: " ",
    style: "standard-subtotal",
  },
  {
    id: 7,
    datapoint_name: "Second Lien",
    originalAmount: 1.1,
    currentAmount: null,
    originalMargin: 3.0,
    currentMargin: null,
    maturityDate: " ",
  },
  {
    id: 8,
    datapoint_name: "Subordinated",
    originalAmount: 2.0,
    currentAmount: 1.0,
    originalMargin: 3.0,
    currentMargin: 4.0,
    maturityDate: "APR 2024",
  },
  {
    id: 9,
    datapoint_name: "Total Subordinated Debt",
    originalAmount: 3.1,
    currentAmount: 1.0,
    originalMargin: null,
    currentMargin: null,
    maturityDate: " ",
    style: "standard-subtotal",
  },
  {
    id: 10,
    datapoint_name: "TOTAL DEBT",
    originalAmount: 59.7,
    currentAmount: 62.8,
    originalMargin: null,
    currentMargin: null,
    maturityDate: " ",
    style: "standard-total",
  },
  {
    id: 11,
    datapoint_name: "Cash and Cash Equivalents",
    originalAmount: 2.0,
    currentAmount: 4.0,
    originalMargin: null,
    currentMargin: null,
    maturityDate: "DEC 2023",
  },
  {
    id: 12,
    datapoint_name: "Net Senior Debt",
    originalAmount: 54.6,
    currentAmount: 57.8,
    originalMargin: null,
    currentMargin: null,
    maturityDate: " ",
    style: "standard-subtotal",
  },
  {
    id: 13,
    datapoint_name: "Net Total Debt",
    originalAmount: 57.7,
    currentAmount: 58.8,
    originalMargin: null,
    currentMargin: null,
    maturityDate: " ",
    style: "standard-subtotal",
  },
];
