import { getKpiFilters, getReportedKpiByCompanyId, getStandardizedKpiByCompanyId } from "../../../../services/kpi_statement";
import { KpiStatementProps } from "./types";
import { FinancialStatement } from "../../../../components/FinancialStatement";
import { FinancialStatements } from "../../../../types";

export const KpiStatement = ({ periodType, companyId, companyName, currency, enableConversions }: KpiStatementProps) => {
  return (
    <FinancialStatement
      statement={FinancialStatements.KPI_STATEMENT}
      showSelector={true}
      showBudget={false}
      companyId={companyId}
      companyName={companyName}
      periodType={periodType}
      currency={currency}
      filterDataFn={getKpiFilters}
      standardizedDataFn={getStandardizedKpiByCompanyId}
      reportedDataFn={getReportedKpiByCompanyId}
      enableConversions={enableConversions}
    />
  );
};
