import { Button } from "@alterdomus-analytics/dna-ui";
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ADlogo from "../../assets/images/ADlogo.png";
import avatars from "../../assets/images/avatars.svg";
import clipboard from "../../assets/images/clipboard.svg";
import cvxLogo from "../../assets/images/cvxLogo.png";
import folders from "../../assets/images/folders.svg";
import { BrandHeader, Container, IconBox, Wrapper } from "./styles";

export const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <BrandHeader>
        <img src={cvxLogo} alt="CVX logo" width="220" height="22" />
        <Divider orientation="vertical" flexItem />
        <h3>an alter domus technology</h3>
      </BrandHeader>
      <Container>
        <h1>There seems to be a problem</h1>
        <p>Please try again later.</p>
        <Button
          label="Back to Home Page"
          variantType="primary"
          href="/analyzer"
          e2e-test-id="cvx-login-button"
          onClick={() => navigate("/analyzer")}
        />
        <IconBox>
          <img src={folders} alt="files" />
          <img src={clipboard} alt="clipboard" />
          <img src={avatars} alt="users" />
        </IconBox>
      </Container>
      <BrandHeader>
        <img src={ADlogo} alt="CVX logo" width="148" height="20" />
      </BrandHeader>
    </Wrapper>
  );
};
