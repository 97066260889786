import { Breadcrumbs } from "@alterdomus-analytics/dna-ui";
import styled from "styled-components";


export const StyledBreadcrumbs = styled(Breadcrumbs)`
    font-size: 0.875rem;
    .MuiBreadcrumbs-li, .MuiBreadcrumbs-li p {
        font-size: 0.75rem;
    }
    .MuiBreadcrumbs-li span {
        color: #162859;
        font-weight: 500;
        text-decoration: none !important;
    } 
    .MuiBreadcrumbs-li p span {
        color: #586463;
        text-transform: uppercase;
    }       
`