import { Dialog, DialogTitle } from "@mui/material";
import styled from "@emotion/styled";

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root.MuiDialog-paper {
    padding: 2.75rem 2.5rem;
  }
  .MuiDialogContent-root {
    padding: 0;
  }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  padding: 0 0 5px;
  .MuiTypography-root,
  .MuiTypography-root > * {
    font-family: "Inter", sans-serif;
    color: #292727;
  }
  .MuiTypography-caption {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
  .MuiTypography-h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 34px;
    margin-bottom: 1rem;
  }
  .MuiTypography-subtitle1 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`;
