import { Box } from "@mui/material";
import styled from "@emotion/styled";

export const EmptyTable = styled(Box)`
  padding: 1.55rem 1.5rem 2rem;
  display: grid;
  align-items: center;
  justify-content: center;
  height: 60vh;
  border-radius: 4px;
`;

export const IconBox = styled(Box)`
  padding-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
  align-self: end;
  height: 6rem;
`;
export const TextBox = styled(Box)`
  display: grid;
  gap: 16px;
  align-self: start;
  text-align: center;
  .MuiTypography-h5 {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }
  .MuiTypography-body1 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
`;
