import styled from "@emotion/styled";
import { Grid } from "@mui/material";

export const Container = styled("div")(() => ({
  position: "relative",
  // hide more actions button
  "& .rpv-toolbar__right > .rpv-toolbar__item:last-child > div": {
    display: "none",
  },
  overflow: "auto",
  backgroundColor: "var(--rpv-default-layout__toolbar-background-color)",
  border: "1px solid rgba(0, 0, 0, 0.3)",
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  borderRight: 0,
}));

export const ToolbarContainer = styled("div")(() => ({
  alignItems: "center",
  display: "flex",
  padding: 8,
}));

export const ViewerContainer = styled("div")(() => ({
  flex: 1,
  overflow: "hidden",
}));

export const ContainerWrapper = styled.div`
  width: 100%;
`;

export const Wrapper = styled(Grid)`
  font-size: 0.875rem;
`;
export const HeaderContainer = styled(Grid)`
  background-color: #ffffff;
  padding: 0;

  .MuiBox-root.css-1gsv261 > .MuiTabs-root {
    padding-left: 2.5rem;
    border-bottom: 1px solid #c3c9ce;
  }
`;
export const PageHeader = styled(Grid)`
  background-color: #ffffff;
  padding: 0.75rem 2.5rem 0rem 2.5rem;
  max-width: 50%;
  min-height: 100px;
  align-items: center;
  width: 100%;
`;
export const HeaderItem = styled(Grid)`
  font-size: 0.875rem;
  width: 25%;
  max-hight: 200px;
`;

export const HeaderButton = styled(Grid)`
  font-size: 0.875rem;
`;
export const Title = styled.h1`
  font-weight: 300;
  font-size: 1.75rem;
  line-height: 2.5rem;
  margin-top: 0px;
  margin-bottom: 0px;
`;
