import { CustomSelect } from "./styles";
import PerformanceRangeSelector from "../PerformanceFilterSlider";
import { useState, useEffect } from "react";

export type PerformanceFilterSelectProps = {
  title: string;
  rangeSelection: (number | null)[];
  marks: { value: number; label: string }[];
  onSelectClosed: (rangeValues: (number | null)[]) => void;
};

export const PerformanceFilterSelect = ({ title, rangeSelection, marks, onSelectClosed }: PerformanceFilterSelectProps) => {
  const [selectedValues, setSelectedMappedValues] = useState<(number | null)[]>(rangeSelection);

  const onRangeSelect = (values: number[]) => {
    if (!marks.length) return;

    let mappedValues: (number | null)[] = [];
    if (values.length && (values[0] !== marks[0].value || values[1] !== marks[marks.length - 1].value)) {
      mappedValues = values.map((v) => {
        const index = v / 10;
        if (index === 0 || index === marks.length - 1) {
          return null;
        }
        return marks[index].value;
      });
    }

    setSelectedMappedValues(mappedValues);
  };

  const handleClose = () => {
    onSelectClosed(selectedValues);
  };

  useEffect(() => {
    setSelectedMappedValues(rangeSelection);
  }, [rangeSelection]);

  const getSelectedLabel = () => {
    if (!marks.length || !rangeSelection.length) return title;

    let label = title;
    const lowerBoundIsDefault = rangeSelection[0] === null;
    const upperBoundIsDefault = rangeSelection[1] === null;

    if (lowerBoundIsDefault && !upperBoundIsDefault) {
      label = `${title} \u2264 ${rangeSelection[1]}`;
    } else if (!lowerBoundIsDefault && upperBoundIsDefault) {
      label = `${title} \u2265 ${rangeSelection[0]}`;
    } else if (!lowerBoundIsDefault && !upperBoundIsDefault) {
      label = `${title} (${rangeSelection[0]}  -  ${rangeSelection[1]})`;
    }

    return label;
  };

  const isActive = () => {
    if (!marks.length || !rangeSelection.length) return false;

    const lowerBoundIsDefault = rangeSelection[0] === null;
    const upperBoundIsDefault = rangeSelection[1] === null;

    return !lowerBoundIsDefault || !upperBoundIsDefault;
  };

  return (
    <CustomSelect
      displayEmpty
      renderValue={getSelectedLabel}
      variant="standard"
      onClose={handleClose}
      disabled={marks.length === 0}
      sx={{ fontWeight: `${isActive() ? "bold" : "normal"}` }}
    >
      <PerformanceRangeSelector
        title={title}
        rangeSelection={selectedValues}
        marks={marks}
        onSelect={(values) => onRangeSelect(values)}
      />
    </CustomSelect>
  );
};
