import { ReportingPeriods } from "../types";

export const getReportingPeriodAbbreviation = (period: ReportingPeriods) => {
  switch (period) {
    case ReportingPeriods.Quarterly:
      return "QTLY";
    case ReportingPeriods.Monthly:
      return "MTHLY";
    case ReportingPeriods.LTM:
      return "LTM";
    case ReportingPeriods.YTD:
      return "YTD";
    default:
      return "";
  }
};

export const getReportingPeriodAbbreviationLegacy = (value?: string) => {
  switch (value) {
    case "q":
      return "QTLY";
    case "m":
      return "MTHLY";
    case "quarterly":
      return "QTLY";
    case "monthly":
      return "MTHLY";
    default:
      return "";
  }
};

export const getReportingPeriodByAbbreviation = async (value?: string) => {
  switch (value) {
    case "q":
      return ReportingPeriods.Quarterly;
    case "m":
      return ReportingPeriods.Monthly;
    default:
      return null;
  }
};
