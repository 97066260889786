import { Popover, Stack } from "@mui/material";
import { DownloadSimple } from "@phosphor-icons/react";
import React, { useState } from "react";
import { FileIconWrapper, FileWrapper, FileXlsIcon, LinkToExcel, StyledSecondaryButton } from "./styles";
import { ExporToFileProps, ExportToFileType } from "./types.d";
import { AnalyzerRow, AnalyzerTables, ReportSection } from "../../types";
import { exportRevenueEbitdaCovenantsOverviewDataToExcel } from "./helpers/revenue-ebitda-covenants-overview";
import { exportFinancialDebtStructureCovenantsStatementsDataToExcel } from "./helpers/financial-debt-structure-covenants";

export const ExportToFile = ({
  type,
  data,
  fileName,
  fileType,
  showButton,
  portfolioAnalyzerTab,
  financialStatementProps,
  debtCovenantsProps,
  globalFiltersProps,
}: ExporToFileProps) => {
  const [isOpened, setIsOpened] = useState(false);
  const popoverId = isOpened ? `simple-popover` : undefined;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpenExportToExcel = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpened(true);
  };

  const handleClose = () => {
    setIsOpened(false);
    setAnchorEl(null);
  };

  const exportData = async (data: Array<ReportSection | any>) => {
    switch (fileType) {
      case "xlsx":
        await exportDataToExcel(data);
        break;
      default:
        break;
    }
    handleClose();
  };

  const exportDataToExcel = async (data: Array<unknown>) => {
    if (type === ExportToFileType.FinancialStatements || type === ExportToFileType.DebtStructureAndCovenants) {
      let statementProps: any = {};
      switch (type) {
        case ExportToFileType.FinancialStatements:
          statementProps = financialStatementProps;
          break;
        case ExportToFileType.DebtStructureAndCovenants:
          statementProps = debtCovenantsProps;
      }
      await exportFinancialDebtStructureCovenantsStatementsDataToExcel(
        fileName,
        data as Array<ReportSection>,
        statementProps!,
        type
      );
    } else if (type === ExportToFileType.PortfolioAnalyzer) {
      await exportRevenueEbitdaCovenantsOverviewDataToExcel(
        fileName,
        data as Array<AnalyzerRow>,
        globalFiltersProps!,
        portfolioAnalyzerTab as AnalyzerTables
      );
    }
  };

  return (
    <>
      <Stack direction="row" sx={{ alignItems: "center" }} onClick={handleOpenExportToExcel}>
        {!showButton && (
          <DownloadSimple
            size={14}
            color="#1A50DD"
            style={{
              marginRight: ".5rem",
              background: "rgba(26, 80, 221, 0.1)",
              borderRadius: "50%",
              margin: "0.25rem",
              padding: "0.325rem",
              boxSizing: "initial",
            }}
            e2e-test-id="financial-statement-export-to-excel"
          />
        )}
        {showButton && (
          <StyledSecondaryButton
            title="Export"
            label="Export"
            color="primary"
            startIcon={<DownloadSimple size={16} color="#1a50dd" />}
          />
        )}
      </Stack>

      <Popover
        id={popoverId}
        open={isOpened}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <FileWrapper>
          <FileIconWrapper>
            <FileXlsIcon focusable={false} />
          </FileIconWrapper>
          <LinkToExcel onClick={() => exportData(data)}>Excel</LinkToExcel>
        </FileWrapper>
      </Popover>
    </>
  );
};
