import { Pagination } from "@mui/material";
import { gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector } from "@mui/x-data-grid";


export function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Pagination
        variant="outlined"
        shape="rounded"
        page={page + 1}
        count={pageCount}
        onChange={(event, value) => apiRef.current.setPage(value -1)}
      />
    );
  }