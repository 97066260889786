import { Button } from "@alterdomus-analytics/dna-ui";
import { Box, Card, AccordionSummary, Accordion, AccordionDetails, Stack } from "@mui/material";
import styled from "@emotion/styled";

export const StyledAccordion = styled(Accordion)`
  padding: 1.5rem 1.5rem 2rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #e2e2e2;
  .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
    min-height: 20px;
  }
`;
export const StyledAccordionSummary = styled(AccordionSummary)`
  align-items: flex-start;
  padding: 0;

  .MuiAccordionSummary-expandIconWrapper {
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  .MuiCardHeader-root {
    padding: 0;
  }
  .MuiTypography-root,
  .MuiTypography-root > * {
    font-family: "Inter", sans-serif;
    color: #292727;
  }
  .MuiTypography-body1 {
    font-size: 1rem;
  }
  .MuiTypography-h4 {
    font-weight: 600;
    font-size: 22px;
    line-height: 34px;
  }
  .MuiTypography-h5 {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
  .MuiTypography-body2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #586463;
  }
`;
export const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
  max-width: 82%;
  .MuiTypography-root,
  .MuiTypography-root > * {
    font-family: "Inter", sans-serif;
    color: #292727;
  }
  .MuiTypography-body1 {
    font-size: 1rem;
  }
`;
export const CommentPreview = styled(Box)`
  max-width: 82%;
`;

export const CommentCard = styled(Card)`
  .MuiCardContent-root {
    padding: 0rem;
    padding-bottom: 1rem;
  }
  .MuiCardActions-root {
    padding: 0rem;
  }
  .MuiCardActions-root > :last-of-type {
    margin-left: 0px;
  }
  .MuiCardHeader-root .MuiLink-root {
    color: #162859;
  }
`;
export const StyledTertiaryButton = styled(Button)`
  padding: 0.5rem 0.75rem;
  color: #1a50dd;
  border-radius: 100px;
  border: none;
  background-color: #fff;
  text-transform: none;
  margin-left: -1rem;
`;
export const ModalActions = styled(Stack)`
  .MuiButtonBase-root.MuiButton-root {
    padding: 0.625rem 1.5rem;
    border-radius: 100px;
    background-color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    :hover {
      background-color: rgba(22, 40, 89, 0.08);
    }
    :active {
      background-color: rgba(22, 40, 89, 0.12);
    }
  }
  .MuiButton-root.MuiButton-text {
    color: #586463;
    border: none;
  }
  .MuiButton-root.MuiButton-outlined {
    color: #9f372f;
    border: 1px solid #67150e;
  }
`;
