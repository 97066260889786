import { StyledConfirmationModal } from './styles';

export default function useConfirmationModal(
    isOpen: boolean, 
    title: string, 
    text: string, 
    onConfirm: ()=>void, 
    onCancel: ()=>void,
){
    const handleConfirm = () => onConfirm()
    const handleCancel = () => onCancel()
    
    const ConfirmationDialog = () => {
        return(
            <StyledConfirmationModal
                open={isOpen}
                title={title}
                text={text}
                onCancel={handleCancel}
                onConfirm={handleConfirm}
            />
        )
    }
    
    return {
        ConfirmationDialog,
    }
}