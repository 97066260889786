import { Viewer, Worker, PageLayout, OpenFile, LoadError } from "@react-pdf-viewer/core";
import { LinearProgress } from "@alterdomus-analytics/dna-ui";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { highlightPlugin, HighlightArea, RenderHighlightsProps } from "@react-pdf-viewer/highlight";
import { toolbarOptions } from "./toolbarOptions";

import { Container, ToolbarContainer, ViewerContainer } from "./styles";

import { Buffer } from "buffer";
import { getFile } from "../../../services/files";
// react pdf viewer styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import React from "react";
import { DownloadSimple } from "@phosphor-icons/react";

import { DownloadButton } from "../../../pages/CompanyPage/styles";
import { HeaderItem, Title, ContainerWrapper, HeaderButton } from "./styles";
import { Stack, Typography } from "@mui/material";

interface HighlightProps {
  id: number;
  content: string;
  highlightAreas: HighlightArea[];
}

export const PdfViewer = () => {
  const { hash } = useParams();
  const decoded = Buffer.from(hash!, "base64").toString("utf-8");
  const { cellInfo } = JSON.parse(decoded);

  const pdfCellInfo: HighlightProps[] = [
    {
      id: cellInfo.dataPoint,
      content: cellInfo.value,
      highlightAreas: [
        {
          height: cellInfo.coordinates.bottomCoordinate - cellInfo.coordinates.topCoordinate,
          left: cellInfo.coordinates.leftCoordinate,
          pageIndex: cellInfo.coordinates.pageNumber,
          top: cellInfo.coordinates.topCoordinate,
          width: cellInfo.coordinates.rightCoordinate - cellInfo.coordinates.leftCoordinate,
        },
      ],
    },
  ];

  const [viewPdf, setViewPdf] = useState<Uint8Array | string>("");
  const [highlightValue] = useState<HighlightProps[]>(pdfCellInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [hasErr, setHasErr] = useState(false);

  const toolbarPluginInstance = toolbarPlugin({
    getFilePlugin: {
      fileNameGenerator: (file: OpenFile) => {
        return cellInfo.fileName;
      },
    },
  });

  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
  const { Download } = toolbarPluginInstance.getFilePluginInstance;

  const renderHighlights = (props: RenderHighlightsProps) => (
    <div>
      {highlightValue?.map((note) => {
        return (
          <React.Fragment key={note.id}>
            {note.highlightAreas
              .filter((area) => area.pageIndex === props.pageIndex)
              .map((area, idx) => (
                <div
                  key={idx}
                  style={Object.assign(
                    {},
                    {
                      background: "rgba(254, 255, 9)",
                      opacity: 0.5,
                    },
                    props.getCssProperties(area, props.rotation)
                  )}
                />
              ))}
          </React.Fragment>
        );
      })}
    </div>
  );

  const renderError = (error: LoadError) => {
    if (error) {
      setHasErr(true);
    }
    //TODO: With the help of UI create a customise error
    let message = `Could not load pdf document for file id: ${
      cellInfo.fileId != null ? cellInfo.fileId : "No file id was found"
    }. Error: ${error.message}  `;

    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#e53e3e",
            borderRadius: "0.25rem",
            color: "#fff",
            padding: "0.5rem",
          }}
        >
          <Typography variant="h6" component="div" e2e-test-id={"pdf-view-error"} sx={{ color: "#fff" }}>
            {message}
          </Typography>
        </div>
      </div>
    );
  };

  //Add marging between pages
  const pageLayout: PageLayout = {
    transformSize: ({ size }) => ({
      height: size.height + 20,
      width: size.width + 20,
    }),
  };
  useEffect(() => {
    const getPdfData = async () => {
      setIsLoading(true);
      try {
        const fileContent = await getFile(cellInfo.fileId);
        if (fileContent) {
          const fileBlob = new Blob([fileContent], { type: "application/pdf" });

          const reader = new FileReader();
          reader.readAsDataURL(fileBlob);
          reader.onload = (e) => {
            if (e.target) {
              setViewPdf(e.target.result as Uint8Array);
            }
          };

          setIsLoading(false);
        }
      } catch (error) {
        setHasErr(true);
      }
    };

    getPdfData();
  }, [cellInfo.fileId]);

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
  });

  return (
    <div>
      {isLoading && <LinearProgress variant="query" />}

      {!isLoading && viewPdf && (
        <ContainerWrapper>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
            sx={{ width: "100%", maxHeight: "150px", padding: "2rem" }}
          >
            <HeaderItem item xs={12}>
              <Title e2e-test-id={"pdf-viewer-title"}>{cellInfo.fileName}</Title>
            </HeaderItem>
            <HeaderButton item xs={12}>
              <Download>
                {(props) => (
                  <DownloadButton
                    label="DOWNLOAD"
                    variantType="secondary"
                    sx={{ marginRight: "0.5rem" }}
                    onClick={props.onClick}
                    disabled={hasErr}
                    startIcon={<DownloadSimple size={16} />}
                    e2e-test-id={`pdf-viwer-${cellInfo.dataPoint}-${cellInfo.fieldName}-download-button`}
                  />
                )}
              </Download>
            </HeaderButton>
          </Stack>

          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
            <Container className="rpv-core__viewer rpv-core__viewer--dark">
              <ToolbarContainer>
                <Toolbar>{renderDefaultToolbar(toolbarOptions)}</Toolbar>
              </ToolbarContainer>
              <ViewerContainer>
                {viewPdf && (
                  <Viewer
                    fileUrl={viewPdf}
                    plugins={[toolbarPluginInstance, highlightPluginInstance]}
                    theme="dark"
                    initialPage={cellInfo.coordinates.pageNumber}
                    pageLayout={pageLayout}
                    renderError={renderError}
                    e2e-test-id={`pdf-viwer-${cellInfo.dataPoint}-${cellInfo.fieldName}-document`}
                  />
                )}
              </ViewerContainer>
            </Container>
          </Worker>
        </ContainerWrapper>
      )}
    </div>
  );
};
