import { Button } from "@alterdomus-analytics/dna-ui";
import styled from "@emotion/styled";
import { FileXls } from "@phosphor-icons/react";

export const FileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  padding: 10px 15px 10px 15px;
  width: 100%;
`;

export const FileIconWrapper = styled.div`
  background-color: rgba(26, 80, 221, 0.1);
  border-radius: 30px;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 8px;
`;

export const FileXlsIcon = styled(FileXls)`
  margin-right: 5px;
  width: 18px;
  color: #1a50dd;
  display: flex;
  margin-left: 5px;
`;

export const LinkToExcel = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #313636;
  text-decoration: none;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export const StyledSecondaryButton = styled(Button)`
  padding: 8px 16px;
  color: #1a50dd;
  font-size: 12px;
  border-radius: 100px;
  border: 1px solid rgba(26, 80, 221, 0.6);
  background-color: #fff;
  text-transform: none;
  height: 34px;
  width: 93px;
`;
