import { SelectProps } from "@mui/material";
import { useEffect, useState } from "react";
import { BootstrapInput, CustomSelect, CustomSelectItem, Item, ItemDescription } from "./styles";

export type MultiSelectItemType = {
  id: string;
  description: string;
};

export interface MultiSelectProps extends SelectProps {
  label: string;
  collection: MultiSelectItemType[];
  values: MultiSelectItemType[];
  onSelectClosed: (items: MultiSelectItemType[]) => void;
}

export const MultiSelect = ({ label, collection, values, onSelectClosed }: MultiSelectProps) => {
  const [selectedItems, setSelectedItems] = useState<MultiSelectItemType[]>([]);

  useEffect(() => {
    setSelectedItems(values);
  }, [values]);

  const handleClose = () => {
    onSelectClosed(selectedItems);
  };

  const handleItemClicked = (e: React.MouseEvent<HTMLElement, MouseEvent>, index: number) => {
    const value = collection[index];
    if (value) {
      const found = selectedItems.find((s) => s.id === value.id);
      if (found) {
        setSelectedItems((prev) => prev.filter((item) => item.id !== value.id));
      } else {
        setSelectedItems((prev) => [...prev, value]);
      }
    }
  };

  return (
    <CustomSelect
      multiple
      displayEmpty
      value={selectedItems}
      input={<BootstrapInput />}
      // onChange={handleChange}
      onClose={handleClose}
      renderValue={() => (selectedItems.length > 0 ? `${label} (${selectedItems.length})` : `${label}`)}
      sx={{ fontWeight: `${selectedItems.length > 0 ? "bold" : "normal"}` }}
    >
      {collection &&
        collection.map((i, index) => (
          <CustomSelectItem key={index} value={i as any} onClick={(e) => handleItemClicked(e, index)}>
            <Item id={`checkbox_${i.id}_${index}`} value={i.id} checked={selectedItems.findIndex((s) => s.id === i.id) > -1} />
            <ItemDescription primary={i.description} />
          </CustomSelectItem>
        ))}
    </CustomSelect>
  );
};
