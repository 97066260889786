import { Button, HeaderNavigation } from "@alterdomus-analytics/dna-ui";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Menu, MenuItem, styled, Box } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Bell } from "phosphor-react";

export const StickyHeaderNavigation = styled(HeaderNavigation)(() => ({
  zIndex: 1000,
}));

export const HeaderUserButton = styled(Button)`
  font-weight: 400;
  font-size: 13px;
  color: #ffffff;
  text-transform: none;
`;

export const HeaderUserButtonIcon = styled(ExpandMoreIcon)`
  color: #ffffff;
  font-weight: 400;
  font-size: 13px;
`;

export const HeaderUserMenu = styled(Menu)``;

export const HeaderUserMenuItem = styled(MenuItem)`
  color: #292727;
  font-weight: 400;
  font-size: 12px;
`;

export const HeaderUserMenuDivider = styled(Divider)`
  max-width: 60%;
  margin-left: 15px;
  text-align: center;
`;

export const HeaderAppsButton = styled(Button)`
  color: #e0e0e0;
  width: 24px;
  height: 24px;
`;

export const HeaderAppsButtonIcon = styled(Box)`
  color: #e0e0e0;
  width: 21px;
  height: 21px;
`;

export const HeaderNotificationsIcon = styled(Bell)`
  color: #e0e0e0;
  width: 21px;
  height: 21px;
`;

export const HeaderDivider = styled(Box)`
  width: 1px;
  height: 20px;
  background-color: #8a8a8a;
`;
