import { EmptyTable, IconBox, TextBox } from "./style";
import { Typography } from "@mui/material";
import noTasks from "../../assets/images/noTasks.svg";

interface NotTableDataProps {
  title?: string;
  content?: string;
  backgroundColor?: "#ffffff" | "#fafafa" | string;
  border?: string;
  button?: JSX.Element;
}
export const NotTableData = ({ title, content, backgroundColor, border, button }: NotTableDataProps) => {
  return (
    <EmptyTable sx={{ backgroundColor: { backgroundColor }, border: { border } }}>
      <IconBox>
        <img src={noTasks} alt="No Table Data" />
      </IconBox>
      <TextBox>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="body1">{content}</Typography>
        {button && <div>{button}</div>}
      </TextBox>
    </EmptyTable>
  );
};
