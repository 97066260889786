import { Box } from "@mui/material";
import { GridRowHeightParams } from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";
import { DebtCovenantsStatements, Units, ReportSessionItemStyles } from "../../types";
import { convertTableValues } from "../../utils/auxiliary";
import { StyledDataGrid } from "./styles";
import { columnGroupingModelCov, columnGroupingModelDebt, getCovenantsColumns, getDebtColumns } from "./table.config";
import { NotTableData } from "../NotTableData";

interface ReportedRowHeight extends GridRowHeightParams {
  style?: string;
}

type CurrentPeriodTableProps = {
  currentStatement: DebtCovenantsStatements;
  currentCovPeriod: string;
  currentPeriod: string;
  unit: Units;
  currency: string;
  originalPeriod: string | undefined;
  data: any;
  enableConversions?: boolean;
};

export const CurrentPeriodTable = ({
  currentStatement,
  currentCovPeriod,
  currentPeriod,
  unit,
  currency,
  originalPeriod,
  data,
  enableConversions,
}: CurrentPeriodTableProps) => {
  const [covRows, setCovRows] = useState<any[]>([]);
  const [debtRows, setDebtRows] = useState<any[]>([]);

  const applyConversions = useCallback(
    async (data: any[]) => {
      if (data.length === 0) return data;

      const convertedDataPromises = data.map(async (d) => {
        const convertedCurrentAmount = await convertTableValues(d.currentAmount, unit, enableConversions, d.style);
        const convertedOriginalAmount = await convertTableValues(d.originalAmount, unit, enableConversions, d.style);

        return {
          ...d,
          currentAmount: convertedCurrentAmount,
          originalAmount: convertedOriginalAmount,
        };
      });

      const convertedData = await Promise.all(convertedDataPromises);
      return convertedData;
    },
    [enableConversions, unit]
  );

  useEffect(() => {
    applyConversions(data).then((dataAfterConversion) => {
      currentStatement === DebtCovenantsStatements.CURRENT_COVENANTS
        ? setCovRows(dataAfterConversion)
        : setDebtRows(dataAfterConversion);
    });
  }, [applyConversions, currentStatement, data, unit, enableConversions]);

  if (data === null) return <div></div>;

  return (
    <>
      {!data.length ? (
        <NotTableData
          title="Sorry, there's no data to display here at the moment."
          content="We don't seem to have any data available for this report."
        />
      ) : (
        <div style={{ display: "flex", height: "100%" }}>
          <Box sx={{ flexGrow: 1 }}>
            <StyledDataGrid
              experimentalFeatures={{ columnGrouping: true }}
              rows={currentStatement === DebtCovenantsStatements.CURRENT_COVENANTS ? covRows : debtRows}
              columns={
                currentStatement === DebtCovenantsStatements.CURRENT_COVENANTS
                  ? getCovenantsColumns(currentCovPeriod, unit, currency)
                  : getDebtColumns(currentPeriod, currency, unit, originalPeriod)
              }
              columnGroupingModel={
                currentStatement === DebtCovenantsStatements.CURRENT_COVENANTS
                  ? columnGroupingModelCov(currentCovPeriod)
                  : columnGroupingModelDebt(currentPeriod)
              }
              autoHeight
              headerHeight={32}
              disableColumnMenu={true}
              disableColumnFilter
              sortingMode="server"
              hideFooter={true}
              getRowClassName={(params) => `row-class--${params.row.style}`}
              getRowHeight={(values: ReportedRowHeight) => {
                const rowStyle = values.model.style;
                const rowName = values.model.datapoint_name;

                if (
                  rowStyle === ReportSessionItemStyles.REPORTED_TOTAL ||
                  rowStyle === ReportSessionItemStyles.STANDARD_TOTAL ||
                  rowStyle === ReportSessionItemStyles.REPORTED_SUBTOTAL ||
                  rowStyle === ReportSessionItemStyles.STANDARD_SUBTOTAL
                ) {
                  return 52;
                } else if (
                  rowStyle === ReportSessionItemStyles.REPORTED_TITLE ||
                  rowStyle === ReportSessionItemStyles.STANDARD_TITLE ||
                  rowStyle === ReportSessionItemStyles.PERIOD_TITLE
                ) {
                  return 36;
                } else if (rowName !== undefined && rowName.length < 44) {
                  return 32;
                } else {
                  return "auto";
                }
              }}
            />
          </Box>
        </div>
      )}
    </>
  );
};
