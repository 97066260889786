import ToggleButton from "@mui/material/ToggleButton";
import { PeriodSelectorProps } from "./types";
import { FinancialStatements, ReportingPeriods } from "../../types";
import { StyledToggleButtonGroup } from "./styles";

export const PeriodSelector = ({ 
  statement, 
  reportingCycle, 
  value, 
  onValueChange, 
  periodHasData 
}: PeriodSelectorProps) => {
  const handleChange = (event: React.MouseEvent<HTMLElement>, value: ReportingPeriods | null) => {
    if (value !== null) {
      onValueChange(value);
    }
  };

  if (statement === FinancialStatements.COVENANTS) {
    return (
      <StyledToggleButtonGroup
        value={value}
        exclusive
        onChange={handleChange}
        aria-label="covenants reporting cycle"
        sx={{ marginLeft: 0 }}
        e2e-test-id={"DCstatement-reportingPeriod-toggle"}
      >
        {reportingCycle === ReportingPeriods.Monthly && (
          <ToggleButton value={ReportingPeriods.Monthly} disabled={!periodHasData?.hasMonthly}>
            MTHLY
          </ToggleButton>
        )}
        <ToggleButton value={ReportingPeriods.Quarterly} disabled={!periodHasData?.hasQuarterly}>
          QTLY
        </ToggleButton>
      </StyledToggleButtonGroup>
    );
  }

  return (
    <StyledToggleButtonGroup
      value={value}
      exclusive
      onChange={handleChange}
      aria-label="reporting cycle"
      e2e-test-id={"statement-reportingPeriod-toggle"}
    >
      {reportingCycle === ReportingPeriods.Monthly && (
        <ToggleButton value={ReportingPeriods.Monthly} disabled={!periodHasData?.hasMonthly}>
          MTHLY
        </ToggleButton>
      )}

      <ToggleButton value={ReportingPeriods.Quarterly} disabled={!periodHasData?.hasQuarterly}>
        QTLY
      </ToggleButton>
      <ToggleButton value={ReportingPeriods.YTD} disabled={!periodHasData?.hasYtd}>
        YTD
      </ToggleButton>
      <ToggleButton value={ReportingPeriods.LTM} disabled={!periodHasData?.hasLtm}>
        LTM
      </ToggleButton>
    </StyledToggleButtonGroup>
  );
};
