import { BalanceSheetProps } from "./types";
import { FinancialStatement } from "../../../../components/FinancialStatement";
import { FinancialStatements } from "../../../../types";
import {
  getBalanceSheetFilters,
  getReportedBalanceSheetByCompanyId,
  getStandardizedBalanceSheetByCompanyId,
} from "../../../../services/balance_sheet";

export const BalanceSheet = ({ periodType, companyId, companyName, currency, enableConversions }: BalanceSheetProps) => {
  return (
    <FinancialStatement
      statement={FinancialStatements.BALANCE_SHEET}
      showSelector={false}
      showBudget={false}
      companyId={companyId}
      companyName={companyName}
      periodType={periodType}
      currency={currency}
      filterDataFn={getBalanceSheetFilters}
      standardizedDataFn={getStandardizedBalanceSheetByCompanyId}
      reportedDataFn={getReportedBalanceSheetByCompanyId}
      enableConversions={enableConversions}
    />
  );
};
