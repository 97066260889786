import { FilterOptions } from "./types";
import ApiClient from "../api";
import { ReportingPeriods, StatementType } from "../../types";

const getReportedCashFlowByCompanyId = async <T>(
  companyId: number,
  financialYear: number,
  period: string,
  isPeriodSelected: boolean
) => {
  try {
    const response = await ApiClient.get<T>(
      `/cash_flow/${companyId}/reported?financialYear=${financialYear}&period=${period}&isPeriodSelected=${isPeriodSelected}`
    );
    return response.data;
  } catch (error) {
    console.error("API error:", error);
    throw new Error("Failed to fetch reported income data for deal.");
  }
};

const getStandardizedCashFlowByCompanyId = async <T>(
  companyId: number,
  financialYear: number,
  period: string,
  isPeriodSelected: boolean
) => {
  try {
    const response = await ApiClient.get<T>(
      `/cash_flow/${companyId}/standarized?financialYear=${financialYear}&period=${period}&isPeriodSelected=${isPeriodSelected}`
    );

    return response.data;
  } catch (error) {
    console.error("API error:", error);
    throw new Error("Failed to fetch standardized financial data for deal.");
  }
};

const getCashFlowFilters = async (companyId: number, type: StatementType, period: ReportingPeriods) => {
  try {
    const response = await ApiClient.get(`/cash_flow/${companyId}/filters?type=${type}&period=${period}`);
    return response.data as FilterOptions;
  } catch (error) {
    console.error("API error:", error);
    throw new Error("Failed to fetch reported financial data for deal.");
  }
};

export { getReportedCashFlowByCompanyId, getStandardizedCashFlowByCompanyId, getCashFlowFilters };
