import styled from "styled-components";

interface ChartWrapperProps {
  width?: string;
}

export const ChartWrapper = styled.div<ChartWrapperProps>`
  width: ${(props) => props.width || "100%"};
  height: 100%;
`;
