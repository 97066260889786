import { Tab, styled } from "@mui/material";

export const StyledTab = styled(Tab)({
  fontFamily: "Inter, sans-serif",
  fontSize: "12px",
  fontWeight: 400,
  alignItems: "center",
  letterSpacing: "0.02em",
  fontStyle: "normal",
  lineHeight: "16px",
  textTransform: "capitalize",
  borderRadius: "8px 8px 0px 0px",
  marginRight: ".5rem",
  border: `1px solid #D4D6D6`,
  borderBottom: 0,
  color: "#586463",
  backgroundColor: "#F3F3F4",
  padding: "0.625rem 1rem",
  minHeight: "38px",
  "&.Mui-selected": {
    backgroundColor: "#FFFFFF",
    color: "#292727",
    fontWeight: 600,
  },
});
