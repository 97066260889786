import { GridFour, House, PlusCircle } from "phosphor-react";
import { useEffect, useState } from "react";
import { getTools } from "../../services/tools";
import { Tool } from "../../types";
import { SidebarNavigationWrapper, StyledMenu, StyledMenuItem, StyledSidebarNavigation, ToolsButton } from "./styles";
import { NavigationItem } from "@alterdomus-analytics/dna-ui";

export const SideBarMenu = () => {
  const [tools, setTools] = useState<Tool[]>([]);

  // const open = Boolean(anchorEl);
  const loadTools = async () => {
    const tools = await getTools();
    setTools(tools as Tool[]);
  };

  useEffect(() => {
    loadTools();
  }, []);

  // TODO Commentted till enable the CVX-849 (https://adanalytics.atlassian.net/browse/CVX-849)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenTool = (url: string) => {
    window.open(url, "_blank");
  };

  const open = Boolean(anchorEl);
  const menuList = [
    {
      text: "Portfolio",
      icon: <House size={24} color="#4966D7" />,
      href: "/analyzer",
    },
    // {
    //   text: "Activity",
    //   icon: <Folders size={24} color="#4966D7" />,
    // },
    {
      text: "Upload",
      icon: <PlusCircle size={24} color="#4966D7" />,
      href: "/upload",
    },
  ] as any[];

  let key = 0;
  return (
    <SidebarNavigationWrapper>
      <StyledSidebarNavigation />
      {menuList.map(({ text, icon, ...props }, index) => (
        <NavigationItem key={index} text={text} icon={icon} {...props} />
      ))}
      {tools && tools.length > 0 && (
        <ToolsButton onClick={handleClick}>
          <GridFour size={24}></GridFour>My Tools
        </ToolsButton>
      )}
      <StyledMenu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        {tools &&
          tools.length > 0 &&
          tools.map((t) => <StyledMenuItem key={++key} label={t.name} onClick={() => handleOpenTool(t.url)} />)}
      </StyledMenu>
    </SidebarNavigationWrapper>
  );
};
