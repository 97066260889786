import { Box, Card, TextField } from "@mui/material";
import styled from "@emotion/styled";
import { Button } from "@alterdomus-analytics/dna-ui";

export const EditorWrapper = styled(Box)`
  .quill {
    background-color: #fff;
    .ql-toolbar {
      border-top: 1px solid #b4b7b7;
      border-left: 1px solid #b4b7b7;
      border-right: 1px solid #b4b7b7;
      border-radius: 4px 4px 0 0;
      .ql-formats {
        align-items: center;
        .ql-list .ql-stroke {
          stroke: #586463;
          stroke-width: 1.1;
        }
        button {
          height: 28px;
        }
      }
      button > i {
        font-size: 24px;
        color: #586463;
      }
    }
    .ql-container {
      min-height: 90px;
      border: 1px solid #b4b7b7;
      border-radius: 0 0 4px 4px;
    }
  }
`;

export const CommentCard = styled(Card)`
  padding: 1.5rem 1.5rem 2rem;

  .MuiCardHeader-root {
    padding: 0;
  }
  .MuiCardContent-root {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .MuiCardActions-root {
    padding: 0rem;
  }
  .MuiCardHeader-root .MuiLink-root {
    color: #162859;
  }

  .MuiTypography-root,
  .MuiTypography-root > * {
    font-family: "Inter", sans-serif;
    color: #292727;
  }
  .MuiTypography-body1 {
    font-size: 1rem;
  }
  .MuiTypography-h4 {
    font-weight: 600;
    font-size: 22px;
    line-height: 34px;
  }
  .MuiTypography-h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 34px;
  }
  .MuiTypography-caption,
  .MuiTypography-body2 {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #586463;
  }
  .MuiTypography-body2 {
    font-weight: 400;
  }
`;

// export const StyledFormControl = styled(FormControl)({
//   minWidth: 200,
//   marginTop: "0.5rem",
// });

// Styles for Select input case
// export const StyledMenuItem = styled(MenuItem)({
//   fontFamily: "Inter, sans-serif",
//   fontSize: "14px",
//   fontWeight: 400,
//   lineHeight: "20px",
//   paddingTop: "4px",
//   paddingBottom: "4px",
// });

// export const BootstrapInput = styled(InputBase)(() => ({
//   color: "#162859",
//   fontFamily: "Inter, sans-serif",
//   "label + &": {
//     marginTop: "3rem",
//   },
//   "& .MuiInputBase-input": {
//     borderRadius: 3,
//     position: "relative",
//     backgroundColor: "#ffffff",
//     border: "1px solid #B4B7B7",
//     fontSize: 14,
//     lineHeight: "20px",
//     padding: "6px 24px 6px 8px",
//     fontFamily: [
//       "Inter",
//       "-apple-system",
//       "BlinkMacSystemFont",
//       '"Segoe UI"',
//       '"Helvetica Neue"',
//       "Arial",
//       "sans-serif",
//       '"Apple Color Emoji"',
//       '"Segoe UI Emoji"',
//       '"Segoe UI Symbol"',
//     ].join(","),
//     "& :focus": {
//       borderRadius: 4,
//       backgroundColor: "#E8EAEE",
//       outline: 0,
//       border: "none",
//       boxShadow: "none",
//     },
//   },
//   "& .MuiSelect-select.MuiSelect-standard.MuiInputBase-input": {
//     color: "#8B9493",
//     paddingRight: "34px",
//     fontSize: "12px",
//     lineHeight: "16px",
//   },
//   "& .MuiSelect-icon.MuiSelect-iconStandard": {
//     marginRight: "13px",
//   },
//   "& :hover": {
//     backgroundColor: "#E8EAEE",
//   },
//   "& :active": {
//     backgroundColor: "#E8EAEE",
//   },
//   "& :focus": {
//     backgroundColor: "#E8EAEE",
//   },
// }));

export const StyledTextField = styled(TextField)({
  minWidth: 200,
  marginTop: "0.5rem",
  "& .MuiFormLabel-root.MuiInputLabel-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#0B77B1",
    borderWidth: "1px",
  },
  "& .MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#0B77B1",
  },
  "& .MuiFormHelperText-root": {
    color: "#586463",
    margin: 0,
    marginTop: "0.5rem",
  },
});

export const StyledSecondaryButton = styled(Button)`
  padding: 8px 16px;
  color: #1a50dd;
  border-radius: 100px;
  border: 1px solid rgba(26, 80, 221, 0.6);
  background-color: #fff;
  text-transform: none;
`;

export const StyledTertiaryButton = styled(Button)`
  padding: 8px 16px;
  color: #1a50dd;
  border-radius: 100px;
  border: none;
  background-color: #fff;
  text-transform: none;
`;
export const ButtonWrapper = styled(Box)`
  .MuiButtonBase-root.Mui-disabled {
    color: #1a50dd80;
    border: none;
    background-color: rgba(26, 80, 221, 0.15);
  }
`;
