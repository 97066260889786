import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { PersistorState, createMigrate, persistReducer, persistStore } from "redux-persist";
import authReducer from "./reducers/authReducer";
import companyReducer from "./reducers/companyReducer";
import portfolioReducer from "./reducers/portfolioReducer";

const STATE_VERSION = 6;

const migrations = {
  5: (state: any) => {
    return {
      ...state,
      analyzerPageFilter: {
        ...state.analyzerPageFilter,
        selectedSectors: [],
      },
      selectedCompany: {
        ...state.selectedCompany,
        internal_rating: 0,
      },
      selectedPortfolio: {
        ...state.selectedPortfolio,
        data: state.selectedPortfolio.selectedPortfolio,
      },
    };
  },
};

const persistedReducer = persistReducer(
  {
    key: "root",
    storage,
    version: STATE_VERSION,
    migrate: createMigrate(migrations, { debug: true }),
  },
  combineReducers({
    user: authReducer,
    selectedCompany: companyReducer,
    selectedPortfolio: portfolioReducer,
  })
);

export const store = configureStore({
  reducer: {
    app: persistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type PersistedRootState = RootState & { _persist: PersistorState };
export const getAuthAcessToken = (state: RootState): string | null => {
  return state.app.user.auth.accessToken;
};
export default persistStore(store);
