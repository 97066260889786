import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PortfolioSelectedState, SetSelectedPortfolioPayload } from "../definitions";

const initialState: PortfolioSelectedState = {
  data: { id: "0", description: "None selected" },
};

const portfolioSlice = createSlice({
  name: "portfolioSlice",
  initialState,
  reducers: {
    setSelectedPortfolio: (state, action: PayloadAction<SetSelectedPortfolioPayload>) => {
      return {
        ...state,
        data: action.payload.data,
      };
    },
  },
});

export default portfolioSlice.reducer;
export const { setSelectedPortfolio } = portfolioSlice.actions;
