import { Box, Link } from "@mui/material";
import { formatValue } from "../../utils/auxiliary";
import { DataFormatTypes } from "../../types";
import { MouseEventHandler } from "react";

type DataCellProps = {
  value: number | string;
  testId: string;
  format?: DataFormatTypes;
  sx?: Object;
};

/**
 * Cell renderer component for any non-link string or number
 *
 * @param value the value to be displayed
 * @param testId the e2e testing id for playwright tests
 * @param format (margin, ratio, or none) indicates the unit symbol in the formatted value
 * @param sx the style object
 * @returns
 */
export const DataCell = ({ value, testId, format, sx }: DataCellProps) => {
  let formattedValue = !value && value !== 0 ? "-" : `${value}`;

  if (format != null) {
    const numberValue = parseFloat(formattedValue);
    const isNegative = !isNaN(numberValue) && numberValue < 0;
    const color = isNegative ? "#9F372F" : undefined;
    sx = sx ? { ...sx, color } : { color };
    formattedValue = formatValue(numberValue, format);
  }

  return (
    <Box component="span" e2e-test-id={testId} sx={sx}>
      {formattedValue}
    </Box>
  );
};

type LinkCellProps = {
  value: number | string;
  testId: string;
  link: string | Function;
  format?: DataFormatTypes;
  style?: any;
};

/**
 * Cell renderer component for any link string or number
 *
 * @param value the numeric value to be formatted and displayed
 * @param testId the e2e testing id for playwright tests
 * @param link either an href string or a function to be called onClick
 * @param format (margin, ratio, or none) indicates the unit symbol in the formatted value
 * @param style the style object
 * @returns
 */
export const LinkCell = ({ value, testId, format, link, style }: LinkCellProps) => {
  let formattedValue = !value && value !== 0 ? "-" : `${value}`;

  if (format != null) {
    const numberValue = parseFloat(formattedValue);
    formattedValue = formatValue(numberValue, format);
  }

  let onClick = undefined;
  let href = undefined;
  if (typeof link === "string") {
    href = link;
  } else {
    onClick = link;
  }

  return (
    <Link
      component="span"
      e2e-test-id={testId}
      color={style?.color}
      underline={style?.underline}
      sx={style}
      href={href}
      onClick={onClick as MouseEventHandler<HTMLAnchorElement>}
    >
      {value}
    </Link>
  );
};
