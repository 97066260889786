import { LinearProgress } from "@alterdomus-analytics/dna-ui";
import { Radio, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import { getBudgetIncomeStatementByCompany } from "../../services/income_statement";
import {
  FilterOptions,
  FinancialStatements,
  ReportingPeriods,
  StatementType,
  Units,
  PeriodHasData,
  SimplifiedFinancialStatement,
  FinancialStatementType,
  ReportLineItem,
} from "../../types";
import { ExportToFile } from "../ExportToFile";
import { PeriodSelector } from "../PeriodSelector";
import BasicSelect from "../Select";
import { StatementTable } from "../StatementTable";
import {
  CustomRadioGroup,
  ExportToFileWrapper,
  LoaderContainer,
  StatementContainer,
  StyledStack,
  TypesGroupLabel,
  TypesGroupLabelWrapper,
} from "./styles";
import { FinancialStatementProps } from "./types";
import { ExportToFileType } from "../ExportToFile/types.d";

export const FinancialStatement = ({
  companyId,
  companyName,
  statement,
  periodType,
  currency,
  showSelector,
  showBudget,
  filterDataFn,
  standardizedDataFn,
  reportedDataFn,
  enableConversions,
}: FinancialStatementProps) => {
  //const state = useAppSelector((state) => state.app.selectedCompany);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>();
  const [data, setData] = useState<ReportLineItem[] | null>(null);
  const [reportUnit, setReportUnit] = useState<Units>();
  const [reportToShow, setReportToShow] = useState<StatementType>(StatementType.STANDARDIZED);
  const [selectedFinancialYear, setSelectedFinancialYear] = useState<number>(filterOptions?.financial_years[0]!);
  const [selectedPeriod, setSelectedPeriod] = useState<ReportingPeriods>(periodType);
  const [loading, setLoading] = useState(true);
  const [isPeriodSelected, setIsPeriodSelected] = useState(false);
  const [periodHasData, setPeriodHasData] = useState<PeriodHasData>();

  //FIXME The below 2 different statement types and function to setData based on them will be removed after alignment of all statements
  // Define a union type for the different types of financial statements
  type FinancialStatementResponse = FinancialStatementType | SimplifiedFinancialStatement;

  function isFinancialStatement(report: FinancialStatementResponse): report is FinancialStatementType {
    return "sections" in report;
  }
  // Define a function to handle setting data based on the type of report
  function setDataBasedOnReportType(report: FinancialStatementResponse) {
    if (isFinancialStatement(report)) {
      // FinancialStatement response
      setData(report.sections);
    } else {
      // SimplifiedFinancialStatement response (Balance Sheets)
      setData(report.items);
    }
  }

  const fetchStandarizedFinancials = () => {
    if (selectedFinancialYear) {
      standardizedDataFn(companyId, selectedFinancialYear, selectedPeriod, isPeriodSelected).then((report) => {
        setReportUnit(report.unit);
        setDataBasedOnReportType(report); // Set data based on the type of report
        setPeriodHasData(report.periodTypesData);
        if (report.periodTypesData?.deafultPeriod) {
          setSelectedPeriod(report.periodTypesData?.deafultPeriod as ReportingPeriods);
        }
        setLoading(false);
      });
    }
  };

  const fetchReportedFinancials = () => {
    if (selectedFinancialYear) {
      reportedDataFn(companyId, selectedFinancialYear, selectedPeriod, isPeriodSelected).then((report) => {
        setReportUnit(report.unit);
        setDataBasedOnReportType(report); // Set data based on the type of report
        setPeriodHasData(report.periodTypesData);
        if (report.periodTypesData?.deafultPeriod) {
          setSelectedPeriod(report.periodTypesData?.deafultPeriod as ReportingPeriods);
        }
        setLoading(false);
      });
    }
  };

  const fetchBudget = () => {
    if (selectedFinancialYear) {
      getBudgetIncomeStatementByCompany(companyId, selectedFinancialYear, selectedPeriod, isPeriodSelected).then((report) => {
        setReportUnit(report.unit);
        setDataBasedOnReportType(report);
        setPeriodHasData(report.periodTypesData);
        if (report.periodTypesData?.deafultPeriod) {
          setSelectedPeriod(report.periodTypesData?.deafultPeriod as ReportingPeriods);
        }
      });
      setLoading(false);
    }
  };

  const fetchFinancials = () => {
    switch (reportToShow) {
      case StatementType.STANDARDIZED:
        fetchStandarizedFinancials();
        break;
      case StatementType.REPORTED:
        fetchReportedFinancials();
        break;
      case StatementType.BUDGET:
        fetchBudget();
        break;
    }
    setIsPeriodSelected(false);
  };

  const getReportName = () => {
    let reportType = "";
    let statementType = "";

    switch (reportToShow) {
      case StatementType.STANDARDIZED:
        reportType = "Standardized";
        break;
      case StatementType.REPORTED:
        reportType = "Reported";
        break;
      case StatementType.BUDGET:
        reportType = "Budget";
        break;
      default:
        reportType = "";
    }

    switch (statement) {
      case FinancialStatements.BALANCE_SHEET:
        statementType = "BalanceSheet";
        break;
      case FinancialStatements.CASH_FLOW:
        statementType = "CashFlow";
        break;
      case FinancialStatements.COVENANTS:
        statementType = "Covenants";
        break;
      case FinancialStatements.DEBT_STRUCTURE:
        statementType = "DebtStructure";
        break;
      case FinancialStatements.INCOME_STATEMENT:
        statementType = "IncomeStatement";
        break;
      case FinancialStatements.KPI_STATEMENT:
        statementType = "KpiStatement";
        break;
      default:
        break;
    }

    return `${reportType}_${statementType}`;
  };

  const handleViewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReportToShow((event.target as HTMLInputElement).value as StatementType);
  };

  const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedFinancialYear(event.target.value as number);
  };

  const handlePeriodChange = (value: ReportingPeriods) => {
    setSelectedPeriod(value);
    setIsPeriodSelected(true);
  };

  useEffect(() => {
    filterDataFn(companyId, reportToShow, selectedPeriod).then((filters) => {
      setFilterOptions(filters);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    if (filterOptions && filterOptions.financial_years && filterOptions.financial_years.length > 0) {
      setSelectedFinancialYear(filterOptions.financial_years[0]);
    }
    if (filterOptions && !filterOptions?.financial_years.length) {
      setData([]);
      setLoading(false);
    }
  }, [filterOptions]);

  useEffect(() => {
    if (statement === FinancialStatements.KPI_STATEMENT) {
      setReportToShow(StatementType.REPORTED);
    }
    setLoading(true);
    fetchFinancials();

    return () => {
      setData(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, reportToShow, selectedFinancialYear, selectedPeriod]);

  return (
    <>
      {loading ? (
        <LoaderContainer>
          <LinearProgress variant="query" />
        </LoaderContainer>
      ) : (
        <StatementContainer>
          {statement === FinancialStatements.KPI_STATEMENT ? (
            <CustomRadioGroup
              value={reportToShow}
              onChange={handleViewChange}
              name="radio-report-type"
              sx={{ marginLeft: "-0.5rem" }}
            >
              <StyledStack direction={"row"} pb={"0.875rem"}>
                <TypesGroupLabelWrapper>
                  {filterOptions && (
                    <BasicSelect
                      value={selectedFinancialYear}
                      options={filterOptions.financial_years}
                      onValueChange={handleSelectChange}
                      e2e-test-id={"statement-FY-select"}
                    />
                  )}

                  {showSelector && (
                    <PeriodSelector
                      statement={statement}
                      reportingCycle={periodType}
                      value={selectedPeriod}
                      onValueChange={handlePeriodChange}
                      periodHasData={periodHasData}
                    />
                  )}
                </TypesGroupLabelWrapper>
                <ExportToFileWrapper>
                  <ExportToFile
                    type={ExportToFileType.FinancialStatements}
                    financialStatementProps={{
                      companyName: companyName!,
                      currency,
                      financialStatement: statement,
                      financialYear: selectedFinancialYear?.toString(),
                      reportingCycle: selectedPeriod,
                      statementType: reportToShow,
                      unit: reportUnit,
                      enableConversions,
                    }}
                    data={data}
                    fileName={`${companyName}_${getReportName()}_${selectedFinancialYear}.xlsx`}
                    fileType="xlsx"
                  />
                </ExportToFileWrapper>
              </StyledStack>
            </CustomRadioGroup>
          ) : (
            <CustomRadioGroup value={reportToShow} onChange={handleViewChange} name="radio-report-type">
              <StyledStack direction={"row"} pb={"0.875rem"}>
                <TypesGroupLabelWrapper>
                  <TypesGroupLabel
                    value={StatementType.STANDARDIZED}
                    control={<Radio />}
                    label="Show Standardized"
                    e2e-test-id={"statement-standardized-radio"}
                  />
                  <TypesGroupLabel
                    value={StatementType.REPORTED}
                    control={<Radio />}
                    label="Show Reported"
                    e2e-test-id={"statement-reported-radio"}
                  />
                  {showBudget && (
                    <TypesGroupLabel
                      value={StatementType.BUDGET}
                      control={<Radio />}
                      label="Show Budget"
                      e2e-test-id={"statement-budget-radio"}
                    />
                  )}
                  {filterOptions && (
                    <BasicSelect
                      value={selectedFinancialYear}
                      options={filterOptions.financial_years}
                      onValueChange={handleSelectChange}
                      e2e-test-id={"statement-FY-select"}
                    />
                  )}
                  {showSelector && (
                    <PeriodSelector
                      statement={statement}
                      reportingCycle={periodType}
                      value={selectedPeriod}
                      onValueChange={handlePeriodChange}
                      periodHasData={periodHasData}
                    />
                  )}
                </TypesGroupLabelWrapper>

                <ExportToFileWrapper>
                  <ExportToFile
                    type={ExportToFileType.FinancialStatements}
                    financialStatementProps={{
                      companyName: companyName!,
                      currency,
                      financialStatement: statement,
                      financialYear: selectedFinancialYear?.toString(),
                      reportingCycle: selectedPeriod,
                      statementType: reportToShow,
                      unit: reportUnit,
                      enableConversions,
                    }}
                    data={data}
                    fileName={`${companyName}_${getReportName()}_${selectedFinancialYear}.xlsx`}
                    fileType="xlsx"
                  />
                </ExportToFileWrapper>
              </StyledStack>
            </CustomRadioGroup>
          )}
          <StatementTable
            companyId={companyId}
            statement={statement}
            mode={reportToShow}
            period={selectedPeriod}
            company_period={periodType}
            unit={reportUnit!}
            currency={currency}
            financialYear={selectedFinancialYear!}
            data={data!}
            enableConversions={enableConversions}
          />
        </StatementContainer>
      )}
    </>
  );
};
