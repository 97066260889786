import { GridColDef, GridColumnGroupingModel, GridRenderCellParams } from "@mui/x-data-grid";
import { Tag } from "@alterdomus-analytics/dna-ui";
import { Box } from "@mui/material";
import { DataFormatTypes, RevEbitdaViews } from "../../types";
import { DataCell, LinkCell } from "../CellRenderers/";
import { formatTableDate } from "../../utils/formatDate";

const getTestId = (row?: string, isRowLabel?: boolean, column?: string, isColumnLabel?: boolean) => {
  const rowString = row ? row.toLowerCase().replace(" ", "-") : "";
  const columnString = column ? column.toLowerCase().replace(" ", "-") : "";

  let id = !!isRowLabel ? `row-${rowString}` : !!isColumnLabel ? `column-${columnString}` : `${rowString}-${columnString}`;
  return id;
};

export const columns: GridColDef[] = [
  {
    field: "company_trade_name",
    headerName: "Portfolio Company",
    width: 230,
    renderCell: (params: GridRenderCellParams) => {
      const value = params.row.company_trade_name;
      const testId = getTestId(params.row.company_trade_name, true);
      const link = () => {
        window.location.href = `/company/${params.row.company_id || params.row.id}`;
      };
      const style = { color: "#1A50DD", underline: "hover", fontWeight: "500", cursor: "pointer" };
      return <LinkCell value={value} testId={testId} link={link} style={style} />;
    },
    cellClassName: "table-font--large",
  },
  {
    field: "period",
    headerName: "Period",
    flex: 1.1,
    minWidth: 106,
    cellClassName: "table-font--large",
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <DataCell
          value={formatTableDate(new Date(params.value))}
          testId={getTestId(params.row.company_trade_name, false, "period", false)}
        />
      );
    },
  },
  {
    field: "currency",
    headerName: "Currency",
    headerClassName: "cvx-double-border--right",
    cellClassName: "cvx-double-border--right",
    flex: 1,
    minWidth: 106,
    renderCell: (params: GridRenderCellParams) => {
      return <DataCell value={params.value} testId={getTestId(params.row.company_trade_name, false, "currency", false)} />;
    },
  },
];

const seniorityColors = (seniority: string): "primary" | "secondary" | "success" | "default" | "info" | "warning" | undefined => {
  switch (seniority) {
    case "Subordinated":
      return "secondary";
    case "Senior":
      return "success";
    default:
      return "success";
  }
};

export const tabColumns: GridColDef[] = [
  {
    field: "sponsors",
    headerName: "Sponsor",
    flex: 1.5,
    minWidth: 106,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      return <DataCell value={params.value} testId={getTestId(params.row.company_trade_name, false, "sponsors", false)} />;
    },
  },
  {
    field: "sector",
    headerName: "Sector",
    flex: 1.5,
    minWidth: 106,
    renderCell: (params: GridRenderCellParams) => {
      return <DataCell value={params.value} testId={getTestId(params.row.company_trade_name, false, "sector", false)} />;
    },
  },
  {
    field: "country",
    headerName: "Country",
    flex: 0.8,
    minWidth: 106,
    renderCell: (params: GridRenderCellParams) => {
      return <DataCell value={params.value} testId={getTestId(params.row.company_trade_name, false, "country", false)} />;
    },
  },
  {
    field: "par_value",
    headerName: "PAR Value (in M)",
    flex: 1,
    minWidth: 106,
    headerClassName: "cvx-right--header",
    align: "right",
    cellClassName: "table-font--large",
    renderCell: (params: GridRenderCellParams) => {
      return <DataCell value={params.value} testId={getTestId(params.row.company_trade_name, false, "par-value", false)} />;
    },
  },
  {
    field: "all_in_rate",
    headerName: "All In Rate",
    flex: 0.95,
    minWidth: 106,
    headerClassName: "cvx-right--header",
    align: "right",
    cellClassName: "table-font--large",
    renderCell: (params: GridRenderCellParams) => {
      return (
        <DataCell
          value={params.value}
          testId={getTestId(params.row.company_trade_name, false, "all-in-rate", false)}
          format={DataFormatTypes.margin}
        />
      );
    },
  },
  {
    field: "seniority",
    headerName: "Seniority",
    flex: 2,
    minWidth: 106,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const seniority = params.row.seniority;
      return (
        <Box
          e2e-test-id={getTestId(params.row.company_trade_name, false, "seniority", false)}
          sx={{ display: "flex", flexWrap: "wrap", marginRight: -4, marginBottom: -0.5, overflow: "hidden", width: "100%" }}
        >
          {seniority.map((item: string, index: number) => (
            <Tag
              key={index}
              label={item}
              colorVariant={seniorityColors(item)}
              style={{ marginRight: 4, marginBottom: 4, fontSize: "12px" }}
            />
          ))}
        </Box>
      );
    },
  },
  {
    field: "internal_rating",
    headerName: "Internal Rating",
    flex: 0.9,
    minWidth: 106,
    headerClassName: "cvx-right--header",
    align: "right",
    cellClassName: "table-font--large",
    renderCell: (params: GridRenderCellParams) => {
      return <DataCell value={params.value} testId={getTestId(params.row.company_trade_name, false, "internal-rating", false)} />;
    },
  },
  {
    field: "funds",
    headerName: "Fund",
    flex: 0.9,
    minWidth: 106,
    cellClassName: "table-font--large",
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const arrayToString = params.value?.length ? params.value.join(", ") : "-";
      return <DataCell value={arrayToString} testId={getTestId(params.row.company_trade_name, false, "fund", false)} />;
    },
  },
];

export const columnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: `revenue-group`,
    headerName: "Revenue (in m)",
    headerAlign: "center",
    headerClassName: "group-header",
    children: [{ field: "revenueActual" }, { field: "revenueVsBudget" }, { field: "revenueVsLastYear" }],
  },
  {
    groupId: `ebitda-group`,
    headerName: "EBITDA (in m)",
    headerAlign: "center",
    headerClassName: "group-header",
    children: [{ field: "ebitdaActual" }, { field: "ebitdaVsBudget" }, { field: "ebitdaVsLastYear" }],
  },
  {
    groupId: `leverage-group`,
    headerName: "LEVERAGE",
    headerAlign: "center",
    headerClassName: "group-header",
    children: [{ field: "leverageOpening" }, { field: "leverageCurrent" }],
  },
];

const switchBudgetHeaderName = (view: RevEbitdaViews): string => {
  switch (view) {
    case RevEbitdaViews.PERCENTAGE:
      return "vsBudget";
    case RevEbitdaViews.AMOUNT:
      return "Budget";
    default:
      return "vsBudget";
  }
};

const switchLastYearHeaderName = (view: RevEbitdaViews): string => {
  switch (view) {
    case RevEbitdaViews.PERCENTAGE:
      return "vsLastYear";
    case RevEbitdaViews.AMOUNT:
      return "LastYear";
    default:
      return "vsLastYear";
  }
};

export const getSecondTabColumns = (view: RevEbitdaViews) => {
  const secondTabColumns: GridColDef[] = [
    {
      field: "revenueActual",
      headerName: "Actual",
      headerClassName: "cvx-right--header-lowercase",
      align: "right",
      flex: 1,
      minWidth: 106,
      cellClassName: "table-font--large",
      renderCell: (params: GridRenderCellParams) => {
        return <DataCell value={params.value} testId="" format={DataFormatTypes.none} />;
      },
    },
    {
      field: "revenueVsBudget",
      headerName: "vsBudget",
      renderHeader: () => switchBudgetHeaderName(view),
      headerClassName: "cvx-right--header-lowercase",
      align: "right",
      flex: 1,
      minWidth: 106,
      cellClassName: "table-font--large-italic",
      renderCell: (params: GridRenderCellParams) => {
        const format = view === RevEbitdaViews.PERCENTAGE ? DataFormatTypes.margin : DataFormatTypes.none;
        return <DataCell value={params.value} testId="" format={format} />;
      },
    },
    {
      field: "revenueVsLastYear",
      headerName: "vsLastYear",
      renderHeader: () => switchLastYearHeaderName(view),
      headerClassName: "cvx-right--header-lowercase",
      align: "right",
      flex: 1,
      minWidth: 106,
      cellClassName: "table-font--large-italic",
      renderCell: (params: GridRenderCellParams) => {
        const format = view === RevEbitdaViews.PERCENTAGE ? DataFormatTypes.margin : DataFormatTypes.none;
        return <DataCell value={params.value} testId="" format={format} />;
      },
    },
    {
      field: "ebitdaActual",
      headerName: "Actual",
      headerClassName: "cvx-right--header-lowercase",
      align: "right",
      flex: 1,
      minWidth: 106,
      cellClassName: "table-font--large",
      renderCell: (params: GridRenderCellParams) => {
        return <DataCell value={params.value} testId="" format={DataFormatTypes.none} />;
      },
    },
    {
      field: "ebitdaVsBudget",
      headerName: "vsBudget",
      renderHeader: () => switchBudgetHeaderName(view),
      headerClassName: "cvx-right--header-lowercase",
      align: "right",
      flex: 1,
      minWidth: 106,
      cellClassName: "table-font--large-italic",
      renderCell: (params: GridRenderCellParams) => {
        const format = view === RevEbitdaViews.PERCENTAGE ? DataFormatTypes.margin : DataFormatTypes.none;
        return <DataCell value={params.value} testId="" format={format} />;
      },
    },
    {
      field: "ebitdaVsLastYear",
      headerName: "vsLastYear",
      renderHeader: () => switchLastYearHeaderName(view),
      headerClassName: "cvx-right--header-lowercase",
      align: "right",
      flex: 1,
      minWidth: 106,
      cellClassName: "table-font--large-italic",
      renderCell: (params: GridRenderCellParams) => {
        const format = view === RevEbitdaViews.PERCENTAGE ? DataFormatTypes.margin : DataFormatTypes.none;
        return <DataCell value={params.value} testId="" format={format} />;
      },
    },
    {
      field: "leverageOpening",
      headerName: "Opening",
      headerClassName: "cvx-right--header-lowercase",
      align: "right",
      flex: 1,
      minWidth: 106,
      cellClassName: "table-font--large",
      renderCell: (params: GridRenderCellParams) => {
        return <DataCell value={params.value} testId="" format={DataFormatTypes.ratio} />;
      },
    },
    {
      field: "leverageCurrent",
      headerName: "Current",
      headerClassName: "cvx-right--header-lowercase",
      align: "right",
      flex: 1,
      minWidth: 106,
      cellClassName: "table-font--large",
      renderCell: (params: GridRenderCellParams) => {
        return <DataCell value={params.value} testId="" format={DataFormatTypes.ratio} />;
      },
    },
  ];
  return secondTabColumns;
};

export const columnGroupingModelCovenants: GridColumnGroupingModel = [
  {
    groupId: `cov-leverage-group`,
    headerName: "Leverage Ratio",
    headerAlign: "center",
    headerClassName: "group-header",
    children: [
      { field: "opening_leverage" },
      { field: "current_leverage" },
      { field: "leverage_covenant_test" },
      { field: "leverage_headroom" },
    ],
  },
  {
    groupId: `interest-cover-group`,
    headerName: "Interest Cover",
    headerAlign: "center",
    headerClassName: "group-header",
    children: [{ field: "interest_current" }, { field: "interest_covenant_test" }, { field: "interest_headroom" }],
  },
  {
    groupId: `cash-cover-group`,
    headerName: "Cash Cover",
    headerAlign: "center",
    headerClassName: "group-header",
    children: [{ field: "cash_current" }, { field: "cash_covenant_test" }, { field: "cash_headroom" }],
  },
];

export const thirdTabColumns: GridColDef[] = [
  {
    field: "opening_leverage",
    headerName: "Opening",
    headerClassName: "cvx-right--header-lowercase",
    align: "right",
    flex: 0.9,
    minWidth: 88,
    cellClassName: "table-font--large",
    renderCell: (params: GridRenderCellParams) => {
      return <DataCell value={params.value} testId="" format={DataFormatTypes.ratio} />;
    },
  },
  {
    field: "current_leverage",
    headerName: "Current",
    headerClassName: "cvx-right--header-lowercase",
    align: "right",
    flex: 0.9,
    minWidth: 88,
    cellClassName: "table-font--large",
    renderCell: (params: GridRenderCellParams) => {
      return <DataCell value={params.value} testId="" format={DataFormatTypes.ratio} />;
    },
  },
  {
    field: "leverage_covenant_test",
    headerName: "Covenant Test",
    headerClassName: "cvx-right--header-lowercase",
    align: "right",
    flex: 0.9,
    minWidth: 88,
    cellClassName: "table-font--large",
    renderCell: (params: GridRenderCellParams) => {
      return <DataCell value={params.value} testId="" format={DataFormatTypes.ratio} />;
    },
  },
  {
    field: "leverage_headroom",
    headerName: "Headroom",
    headerClassName: "cvx-right--header-lowercase",
    align: "right",
    flex: 0.9,
    minWidth: 88,
    cellClassName: "table-font--large-italic",
    renderCell: (params: GridRenderCellParams) => {
      return <DataCell value={params.value} testId="" format={DataFormatTypes.margin} />;
    },
  },
  {
    field: "interest_current",
    headerName: "Current",
    headerClassName: "cvx-right--header-lowercase",
    align: "right",
    flex: 0.9,
    minWidth: 88,
    cellClassName: "table-font--large",
    renderCell: (params: GridRenderCellParams) => {
      return <DataCell value={params.value} testId="" format={DataFormatTypes.ratio} />;
    },
  },
  {
    field: "interest_covenant_test",
    headerName: "Covenant Test",
    headerClassName: "cvx-right--header-lowercase",
    align: "right",
    flex: 0.9,
    minWidth: 88,
    cellClassName: "table-font--large",
    renderCell: (params: GridRenderCellParams) => {
      return <DataCell value={params.value} testId="" format={DataFormatTypes.ratio} />;
    },
  },
  {
    field: "interest_headroom",
    headerName: "Headroom",
    headerClassName: "cvx-right--header-lowercase",
    align: "right",
    flex: 0.9,
    minWidth: 88,
    cellClassName: "table-font--large-italic",
    renderCell: (params: GridRenderCellParams) => {
      return <DataCell value={params.value} testId="" format={DataFormatTypes.margin} />;
    },
  },
  {
    field: "cash_current",
    headerName: "Current",
    headerClassName: "cvx-right--header-lowercase",
    align: "right",
    flex: 0.9,
    minWidth: 88,
    cellClassName: "table-font--large",
    renderCell: (params: GridRenderCellParams) => {
      return <DataCell value={params.value} testId="" format={DataFormatTypes.ratio} />;
    },
  },
  {
    field: "cash_covenant_test",
    headerName: "Covenant Test",
    headerClassName: "cvx-right--header-lowercase",
    align: "right",
    flex: 0.9,
    minWidth: 88,
    cellClassName: "table-font--large",
    renderCell: (params: GridRenderCellParams) => {
      return <DataCell value={params.value} testId="" format={DataFormatTypes.ratio} />;
    },
  },
  {
    field: "cash_headroom",
    headerName: "Headroom",
    headerClassName: "cvx-right--header-lowercase",
    align: "right",
    flex: 0.9,
    minWidth: 88,
    cellClassName: "table-font--large-italic",
    renderCell: (params: GridRenderCellParams) => {
      return <DataCell value={params.value} testId="" format={DataFormatTypes.margin} />;
    },
  },
];
