import React from "react";
import { useIdleTimer } from "react-idle-timer";
import { handleSessionExpired } from ".";

export const idleGuard = (Component: React.ElementType) => () => {
  const logout = () => {
    handleSessionExpired();
  };

  const handleOnIdle = (event?: Event) => {
    logout();
  };

  useIdleTimer({
    timeout: 3600000, //1 hour or 3,600,000 milliseconds
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return <Component />;
};
