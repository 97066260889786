import { Button, FormControlLabel, Stack, Grid, Box } from "@mui/material";
import { TextField, styled as styledMaterial } from "@mui/material";
import styled from "@emotion/styled";

export const BootstrapTextField = styledMaterial(TextField)(() => ({
  color: "#162859",
  fontFamily: "Inter, sans-serif",
  "label + &": {
    marginTop: "1rem",
  },
  "& .MuiInputBase-input": {
    borderRadius: 3,
    position: "relative",
    backgroundColor: "#ffffff",
    border: "1px solid #ced4da",
    fontSize: 14,
    lineHeight: "20px",
    padding: "6px 24px 6px 8px",
    fontFamily: [
      "Inter",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "& :focus": {
      borderRadius: 4,
      backgroundColor: "#E8EAEE",
      outline: 0,
      border: "none",
      boxShadow: "none",
    },
  },
  "& .MuiSelect-select.MuiSelect-standard.MuiInputBase-input": {
    paddingRight: "34px",
  },
  "& .MuiSelect-icon.MuiSelect-iconStandard": {
    marginRight: "13px",
  },
  "& :hover": {
    backgroundColor: "#E8EAEE",
  },
  "& :active": {
    backgroundColor: "#E8EAEE",
  },
  "& :focus": {
    backgroundColor: "#E8EAEE",
  },
}));

export const StatementContainer = styledMaterial(Grid)`
  padding: 1rem 1.5rem 1.5rem;
  background-color: #ffffff;
  border: 1px solid #e2e2e2;
  font-family: "Inter", sans-serif;
`;
export const ButtonStack = styledMaterial(Stack)`
  padding-bottom: 1rem;
`;
export const StyledStack = styledMaterial(Stack)`
  margin-bottom: 0;
  margin-top: 0;
`;
export const TypesGroupLabel = styledMaterial(FormControlLabel)`
  .MuiTypography-root {
    font-size: 14px;
    font-family: "Inter", sans-serif;
  }
  .MuiRadio-root {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export const PeriodButton = styledMaterial(Button)(() => ({
  color: "#313636",
  fontFamily: "Inter, sans-serif",
  fontSize: 12,
  fontWeight: 400,
  textTransform: "none",
  padding: "8px 12px",
  border: "1px solid #d7d7d7",
  borderRadius: 6,
  "&.MuiButton-outlined": {
    color: "#162859",
    border: "1px solid #162859",
  },
}));

export const LoaderContainer = styledMaterial(Box)`
  height: 100vh;
  padding-top: 3rem;
  background-color: #fff;
`;

export const TypesGroupLabelWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const ExportToFileWrapper = styled.div`
  display: flex;
  flex: 0;
`;

export const ChartWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
  gap: 20px;
`;
export const ItemContainer = styled(Grid)`
  margin-top: 0rem;
  padding-top: 1.5rem;
`;
export const SubItemContainer = styled(Grid)`
  margin-top: 0rem;

  > * {
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    border-top: 4px solid #e0e0e0;
    padding: 2rem 1.5rem 1.5rem;
  }
`;
