import {
  getCashFlowFilters,
  getReportedCashFlowByCompanyId,
  getStandardizedCashFlowByCompanyId,
} from "../../../../services/cash_flow";
import { CashFlowProps } from "./types";
import { FinancialStatement } from "../../../../components/FinancialStatement";
import { FinancialStatements } from "../../../../types";

export const CashFlow = ({ periodType, companyId, companyName, currency, enableConversions }: CashFlowProps) => {
  return (
    <FinancialStatement
      statement={FinancialStatements.CASH_FLOW}
      showSelector={true}
      showBudget={false}
      companyId={companyId}
      companyName={companyName}
      periodType={periodType}
      currency={currency}
      filterDataFn={getCashFlowFilters}
      standardizedDataFn={getStandardizedCashFlowByCompanyId}
      reportedDataFn={getReportedCashFlowByCompanyId}
      enableConversions={enableConversions}
    />
  );
};
