import axios from "axios";
import { apiInterceptor, apiResponseInterceptor } from "./interceptor";

const ApiClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URI,
  headers: {},
});

const handleError = async () => {
  window.location.href = "/error";
};

const handleUnauthorizedError = async () => {
  window.location.href = "/internal-unauthorized";
};

ApiClient.interceptors.request.use();
ApiClient.interceptors.request.use(apiInterceptor, (error) => Promise.reject(error));
ApiClient.interceptors.response.use(apiResponseInterceptor, async (error) => {
  if (error.response.status === 401) {
    await handleUnauthorizedError();
  }

  if (error.response.status >= 400) {
    await handleError();
  }

  Promise.reject(error);
});
export default ApiClient;
