import { Tool } from "../../types";
import ApiClient from "../api";

export const getTools = async () => {
  try {
    const response = await ApiClient.get(`/tools`);
    return response.data.tools as Tool[];
  } catch (error) {
    throw error;
  }
};
