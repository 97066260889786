import { FilterOptions } from "./types";
import ApiClient from "../api";
import { ReportingPeriods, StatementType } from "../../types";

const getReportedDebtStructureByCompanyId = async <T>(companyId: number, financialYear: number, period: string) => {
  try {
    const response = await ApiClient.get<T>(`/debt/${companyId}/reported?financialYear=${financialYear}&period=${period}`);
    return response.data;
  } catch (error) {
    console.error("API error:", error);
    throw new Error("Failed to fetch reported income data for deal.");
  }
};

const getStandardizedDebtStructureByCompanyId = async <T>(companyId: number, financialYear: number, period: string) => {
  try {
    const response = await ApiClient.get<T>(`/debt/${companyId}/standarized?financialYear=${financialYear}&period=${period}`);

    return response.data;
  } catch (error) {
    console.error("API error:", error);
    throw new Error("Failed to fetch standardized financial data for deal.");
  }
};

const getCurrentDebtByCompanyId = async <T>(companyId: number, financialYear: number, period: string, dates: number[]) => {
  try {
    const response = await ApiClient.get<T>(
      //FIXME - we need to define current Period's annotation.
      `/debt/${companyId}/debt-breakdown?financialYear=${financialYear}&dates=${dates[0]}&dates=${dates[1]}&dates=${dates[2]}&dates=${dates[3]}&period=${period}`
    );
    return response.data;
  } catch (error) {
    console.error("API error:", error);
    throw new Error("Failed to fetch current debt data for deal.");
  }
};

const getDebtStructureFilters = async (
  companyId: number,
  type: StatementType,
  period: ReportingPeriods,
) => {
  try {
    const response = await ApiClient.get(`/debt/${companyId}/filters?type=${type}&period=${period}`);
    return response.data as FilterOptions;
  } catch (error) {
    console.error("API error:", error);
    throw new Error("Failed to fetch reported financial data for deal.");
  }
};

export {
  getReportedDebtStructureByCompanyId,
  getStandardizedDebtStructureByCompanyId,
  getCurrentDebtByCompanyId,
  getDebtStructureFilters,
};
