import styled from "@emotion/styled";
import { Button, FormControlLabel, Stack, Grid, Box, RadioGroup } from "@mui/material";

export const StatementContainer = styled(Grid)`
  padding: 1.5rem 1.5rem 1.5rem;
  background-color: #ffffff;
  border: 1px solid #e2e2e2;
  font-family: "Inter", sans-serif;
`;
export const ButtonStack = styled(Stack)`
  padding-bottom: 1rem;
  border-bottom: 1px solid #e2e2e2;
`;
export const StyledStack = styled(Stack)`
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`;
export const TypesGroupLabel = styled(FormControlLabel)`
  .MuiTypography-root {
    font-size: 14px;
    font-family: "Inter", sans-serif;
  }
  .MuiRadio-root {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;
export const PeriodButton = styled(Button)`
  color: #007db7;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-transform: none;
  padding: 8px 12px;
  border: 1px solid #007db7;
  border-radius: 6px;
`;
export const LoaderContainer = styled(Box)`
  height: 100vh;
  padding-top: 3rem;
  background-color: #ffffff;
`;

export const CustomRadioGroup = styled(RadioGroup)`
  display: flex;
  justify-content: space-between;
`;

export const TypesGroupLabelWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const ExportToFileWrapper = styled.div`
  display: flex;
  flex: 0;
`;
