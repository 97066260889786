import { Box, CircularProgress } from "@mui/material";

export const FullscreenCircularProgress = () => {
  return (
    <Box
      position="fixed"
      top="50%"
      left="50%"
      sx={{ transform: "translate(-50%, -50%)" }}
    >
      <CircularProgress size={100} />
    </Box>
  );
};
