import { Button } from "@alterdomus-analytics/dna-ui";
import { Card, Box } from "@mui/material";
import styled from "styled-components";

export const CommentCard = styled(Card)`
  padding: 1.5rem 1.75rem;
  .MuiCardHeader-root {
    padding: 0rem 0.75rem 1rem 0rem;
  }
  .MuiCardContent-root {
    padding: 0rem;
    padding-bottom: 1rem;
  }
  .MuiCardActions-root {
    padding: 0rem;
  }
  .MuiCardHeader-root .MuiLink-root {
    color: #1a50dd;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
  .MuiTypography-root,
  .MuiTypography-root > * {
    font-family: "Inter", sans-serif;
    color: #292727;
  }
  .MuiTypography-body1 {
    font-size: 1rem;
  }
  .MuiTypography-h4 {
    font-weight: 600;
    font-size: 22px;
    line-height: 34px;
  }
  .MuiTypography-h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 34px;
  }
  .MuiTypography-body2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #586463;
  }
`;

export const ModalButton = styled(Button)`
  padding: 6px 10px;
  background-color: #e7eeee;
`;

export const EditorWrapper = styled(Box)`
  .quill {
    background-color: #fff;
    .ql-toolbar {
      border: 1px solid #1a50dd;;
      border-radius: 4px 4px 0 0;
      .ql-formats {
        align-items: center;
        .ql-list .ql-stroke {
          stroke: #586463;
          stroke-width: 1.1;
        }
        button {
          height: 28px;
        }
      }
      button > i {
        font-size: 24px;
        color: #586463;
      }
    }
    .ql-container.ql-snow {
      font-size: inherit;
      border-color: #1a50dd;
    }
    .ql-container {'
      min-height: 90px;
      border: 1px solid #b4b7b7;
      border-radius: 0 0 4px 4px;
    }
  }
`;

export const StyledTertiaryButton = styled(Button)`
  padding: 0.5rem 0.75rem;
  color: #1a50dd;
  border-radius: 100px;
  border: none;
  background-color: #fff;
  text-transform: none;
  min-width: 82px;
  margin-top: -0.5rem;
  margin-right: -0.25rem;
`;

export const OutlinedButton = styled(Button)`
  margin-top: 24px;
  padding: 8px 16px;
  color: #1a50dd;
  border-radius: 100px;
  border: 1px solid rgba(26, 80, 221, 0.6);
  background-color: #fff;
  text-transform: none;
`;

export const PrimaryButton = styled(Button)`
  padding: 8px 16px;
  color: white;
  border-radius: 100px;
  background-color: #1a50dd;
  border: none;
  text-transform: none;
  &:hover {
    background-color: #162859eb;
    border: none;
  }
  &.Mui-disabled {
    color: #1a50dd80;
    border: none;
    background-color: rgba(26, 80, 221, 0.15);
  }
`;
