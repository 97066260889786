import { Link, Box } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Asterisk, Divide, Equals, Minus, Plus } from "phosphor-react";
import { Buffer } from "buffer";
import { StyledStack, Text } from "./styles";
import { DataFormatTypes, FinancialStatements, ReportingPeriods } from "../../types";
import { StackProps } from "@mui/system";
import { formatValue } from "../../utils/auxiliary";

interface TraceabilityRowProps extends StackProps {
  data_point: string;
  value: number;
  is_mapping: boolean;
  operator?: string;
  companyId: number;
  uuid: string;
  month: number;
  finYear: number;
  statement: string;
  period: ReportingPeriods;
  company_period: ReportingPeriods;
  rowStyle?: string;
}

const TraceabilityRow = ({
  data_point,
  value,
  is_mapping,
  operator,
  companyId,
  uuid,
  month,
  finYear,
  statement,
  period,
  company_period,
  rowStyle,
}: TraceabilityRowProps) => {
  const handleOpenReportedWindow = (uuid: string, month: number, year: number, statement: string) => {
    const data = { companyId, uuid, month, year, statement, period, company_period };
    const encodedData = Buffer.from(JSON.stringify(data)).toString("base64");
    window.open(`/view_reported/${companyId}/${encodedData}`, "_blank", "width=1300,height=500,scrollbars=yes,resizable=yes");
  };

  let icon;

  switch (operator) {
    case "=":
      icon = <Equals size={10} />;
      break;
    case "+":
      icon = <Plus size={10} />;
      break;
    case "-":
      icon = <Minus size={10} />;
      break;
    case "*":
      icon = <Asterisk size={10} />;
      break;
    case "/":
      icon = <Divide size={10} />;
      break;
    default:
      icon = <Box sx={{ minWidth: "0.875rem" }} />;
  }

  let outputValue = String(value);
  if (rowStyle === "margin") {
    outputValue = formatValue(value, DataFormatTypes.margin);
  } else if (statement === FinancialStatements.COVENANTS && rowStyle === "ratio") {
    outputValue = formatValue(value, DataFormatTypes.ratio);
  } else {
    if (value !== null || value !== undefined) outputValue = formatValue(value, DataFormatTypes.none);
  }

  return (
    <StyledStack
      sx={{ width: "100%", padding: "0.5rem 0" }}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      e2e-test-id={`traceability-row-${data_point}`}
    >
      <Box sx={{ minWidth: "0.875rem" }}>{icon}</Box>
      <Divider orientation="vertical" sx={{ marginLeft: "0.375rem" }} />
      <Text p={"0 0.5rem"}> {data_point} </Text>

      <Box ml={"auto"}>
        {is_mapping ? (
          <Link
            e2e-test-id={`traceability-value-${data_point}`}
            color="#1A50DD"
            underline="none"
            sx={{ cursor: "pointer" }}
            onClick={() => handleOpenReportedWindow(uuid, month, finYear, statement)}
          >
            <Text sx={{ fontStyle: rowStyle === "margin" ? "italic" : "normal" }}>{outputValue}</Text>
          </Link>
        ) : (
          <Text e2e-test-id={`traceability-row-${data_point}`} sx={{ fontStyle: rowStyle === "margin" ? "italic" : "normal" }}>
            {" "}
            {outputValue}{" "}
          </Text>
        )}
      </Box>
    </StyledStack>
  );
};

export default TraceabilityRow;
