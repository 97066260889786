import { AnalyzerRow } from "../../types";

export const getOverviewDataToExcel = async (data: Array<AnalyzerRow>) => {
  if (!data || data.length === 0) return null;

  const preparedData: Array<AnalyzerRow> = [];

  for await (const d of data) {
    const newObject: { [key: string]: any } = {};
    newObject["Portfolio Company"] = d.company_name;
    newObject["Period"] = d.period || "";
    newObject["Currency"] = d.currency || "";
    newObject["Sponsor"] = d.sponsors ? d.sponsors.toString() : "";
    newObject["Sector"] = d.sector || "";
    newObject["Country"] = d.country || "";
    newObject["Par Value (In M)"] = d.par_value || "";
    newObject["All in Rate"] = d.all_in_rate || "";
    newObject["Seniority"] = d.seniority ? d.seniority.toString() : "";
    newObject["Internal Rating"] = d.internal_rating || "";
    newObject["Fund"] = d.funds ? d.funds.toString() : "";
    preparedData.push(newObject);
  }

  return preparedData;
};
