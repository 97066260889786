import { Link, Popover, Stack } from "@mui/material";
import { FileArrowDown } from "phosphor-react";
import React, { useState } from "react";
import { getFile } from "../../../services/files";
import { FileIcon, FileIconWrapper, FileWrapper, LinkToPdf } from "./styles";

type PdfFilesProps = {
  index: number;
  columnName: string;
  files?: [string, string][];
};

export const PdfFiles = ({ index, columnName, files }: PdfFilesProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const id = isOpen ? `simple-popover-${index}` : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  const openFile = (fileName: string, fileUrl: string) => {
    setTimeout(async () => {
      setAnchorEl(null);
      setIsOpen(false);

      const fileId = fileUrl.split("=")[1];
      const fileContent = await getFile(fileId);

      // Create a Blob URL and initiate a download
      const url = window.URL.createObjectURL(new Blob([fileContent], { type: "application/octet-stream" }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      // Clean up the URL and remove the temporary link
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    }, 500);
  };

  return (
    <Link color="inherit" underline="none" sx={{ cursor: "pointer" }}>
      <>
        <Stack direction="row" sx={{ alignItems: "center" }} onClick={handleClick}>
          {files && files.length > 0 && (
            <FileArrowDown
              size={14}
              color="#1A50DD"
              style={{
                marginRight: ".5rem",
                background: "rgba(26, 80, 221, 0.1)",
                borderRadius: "50%",
                margin: "0.25rem",
                padding: "0.325rem",
                boxSizing: "initial",
              }}
            />
          )}
          <div e2e-test-id={`statement-monthcolumn-${index}`}>{columnName}</div>
        </Stack>
        {files && files.length > 0 && (
          <Popover
            id={id}
            open={isOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            {files.map(
              ([key, value]) =>
                value && (
                  <FileWrapper>
                    <FileIconWrapper>
                      <FileIcon focusable={false} />
                    </FileIconWrapper>
                    <LinkToPdf onClick={() => openFile(key, value)}>{key}</LinkToPdf>
                  </FileWrapper>
                )
            )}
          </Popover>
        )}
      </>
    </Link>
  );
};
