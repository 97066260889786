import FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { AnalyzerRow, AnalyzerTables } from "../../../types";
import { ExportToFileGlobalFiltersProps } from "../types";
import { getRevenueEbitdaDataToExcel } from "../../../services/export_to_excel/revenue_ebitda";
import { getOverviewDataToExcel } from "../../../services/export_to_excel/overview";
import { getCovenantsDataToExcel } from "../../../services/export_to_excel/covenants";

export const exportRevenueEbitdaCovenantsOverviewDataToExcel = async (
  fileName: string,
  data: Array<AnalyzerRow>,
  props: ExportToFileGlobalFiltersProps,
  statement: AnalyzerTables
) => {
  let preparedData;

  switch (statement) {
    case AnalyzerTables.OVERVIEW:
      preparedData = await getOverviewDataToExcel(data);
      break;
    case AnalyzerTables.REVENUE_EBITDA:
      preparedData = await getRevenueEbitdaDataToExcel(data);
      break;
    case AnalyzerTables.COVENANTS:
      preparedData = await getCovenantsDataToExcel(data);
      break;
  }

  if (!preparedData) {
    //FIXME we need to return some error message to the user.
    return null;
  }

  await generate(fileName, preparedData, props, statement);
};

const getMetadata = async (props: ExportToFileGlobalFiltersProps) =>
  // passar os filtros (nome e valores)
  {
    const { funds, sectors, countries, parValueRange, leverageRange, revenueVsBudgetRange, revenueVsLastYearRange } = props;

    const fundsSelected = funds.map((item) => item?.description).toString();
    const sectorsSelected = sectors.map((item) => item?.description).toString();
    const countriesSelected = countries.map((item) => item?.description).toString();

    return [
      {
        name: "Funds",
        value: fundsSelected,
      },
      {
        name: "Sectors",
        value: sectorsSelected,
      },
      {
        name: "Countries",
        value: countriesSelected,
      },
      {
        name: "Par Value Range",
        value: getRangeValues(parValueRange),
      },
      {
        name: "Leverage Range",
        value: getRangeValues(leverageRange),
      },
      {
        name: "Revenue Vs Budget Range",
        value: getRangeValues(revenueVsBudgetRange),
      },
      {
        name: "Revenue Vs Last Year Range",
        value: getRangeValues(revenueVsLastYearRange),
      },
    ];
  };

export const generate = async (
  fileName: string,
  preparedData: Array<AnalyzerRow>,
  props: ExportToFileGlobalFiltersProps,
  statement: AnalyzerTables
) => {
  const metadata = await getMetadata(props);

  // Defining the width of the columns
  const headerColWidths = await getHeaderColumnWidths();
  const dataColWidths =
    statement === (AnalyzerTables.OVERVIEW || AnalyzerTables.REVENUE_EBITDA)
      ? await getDataColumnWidths()
      : await getCovenantsDataColumnWidths();

  const mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const wsMetadata = XLSX.utils.json_to_sheet(metadata, { skipHeader: true });
  const wsData = XLSX.utils.json_to_sheet(preparedData);

  // Apply column widths to the worksheet
  wsMetadata["!cols"] = headerColWidths;
  wsData["!cols"] = dataColWidths;

  // Apply number format to data cells
  const range = XLSX.utils.decode_range(`B2:Z${preparedData.length + 1}`);
  const numberFormat = "#,##0.00";

  for (let R = range.s.r; R <= range.e.r; ++R) {
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = { c: C, r: R };
      const cellRef = XLSX.utils.encode_cell(cellAddress);
      const cell = wsData[cellRef];
      if (cell && cell.t === "n") {
        cell.z = numberFormat; // Apply number format
      }
    }
  }

  const wb = { Sheets: { Data: wsData, Metadata: wsMetadata }, SheetNames: ["Data", "Metadata"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const excelBlob = new Blob([excelBuffer], { type: mimeType });

  FileSaver.saveAs(excelBlob, fileName);
};

const getHeaderColumnWidths = async () => {
  return [{ wch: 30 }, { wch: 30 }];
};

//Overview & revenue-ebitda
const getDataColumnWidths = async () => {
  return [
    { wch: 40 },
    { wch: 15 },
    { wch: 15 },
    { wch: 20 },
    { wch: 20 },
    { wch: 15 },
    { wch: 15 },
    { wch: 10 },
    { wch: 30 },
    { wch: 15 },
    { wch: 30 },
  ];
};

//Covenants
const getCovenantsDataColumnWidths = async () => {
  return [
    { wch: 40 },
    { wch: 15 },
    { wch: 15 },
    { wch: 25 },
    { wch: 25 },
    { wch: 28 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
  ];
};

const getRangeValues = (range: any[]) => {
  let startingRange: string = "";
  let finishedRange: string = "";

  if (!range || (range[0] == null && range[1] == null)) {
    return "Unset";
  } else if (range && range[0] != null && range[1] != null) {
    startingRange = range[0].toString();
    finishedRange = range[1].toString();
  } else if (range && range[0] != null && range[1] == null) {
    startingRange = range[0].toString();
    finishedRange = "Unset";
  } else if (range && range[0] == null && range[1] != null) {
    startingRange = "Unset";
    finishedRange = range[1].toString();
  }

  return `${startingRange} - ${finishedRange}`;
};
