import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { ConfirmationModalProps } from "./types";

export const ConfirmationModal = (props: ConfirmationModalProps) => {
    const { open, title, text, onCancel, onConfirm } = props;

    return(
        <Dialog
            open={open}
            onClose={onCancel}
        >
            <DialogTitle> { title } </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    { text }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={onConfirm} 
                    autoFocus
                > 
                    Yes
                </Button>
                <Button onClick={onCancel}> No </Button>
            </DialogActions>
        </Dialog>
    )
}