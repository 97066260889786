import { Box } from "@mui/material";
import { formatValue } from "../../../utils/auxiliary";
import { DataFormatTypes } from "../../../types";

type DebtCellProps = {
  value: number;
  name: string;
  field?: string;
  style?: string;
};

export const DebtCell = ({ value, name, field, style }: DebtCellProps) => {
  let outputValue = String(value);

  if (style === "ratio") {
    outputValue = formatValue(value, DataFormatTypes.ratio);
  } else {
    if (value !== null || value !== undefined) outputValue = formatValue(value);
  }

  if (!value) {
    return null;
  }

  if (field === "originalMargin" || field === "currentMargin") {
    if (value > 0) {
      return <Box component="span">{formatValue(value, DataFormatTypes.margin)}</Box>;
    } else if (value === null) {
      return <Box component="span">-</Box>;
    }
    return <Box component="span" color={"#9F372F"}>{`(${formatValue(value, DataFormatTypes.margin)})`}</Box>;
  }

  if (value > 0) {
    return <Box component="span">{outputValue}</Box>;
  } else if (value === null) {
    return <Box component="span">-</Box>;
  }

  return <Box component="span" color={"#9F372F"}>{`(${outputValue})`}</Box>;
};
