import { ReportingPeriods } from "../../types";
import ApiClient from "../api";

interface TraceabilityReqBodyDto {
  companyId: number;
  statement: string;
  period: ReportingPeriods;
  data_point: string;
  finYear: number;
  month: number;
  style?: string;
}

const getTraceabilityData = async <T>(payload: TraceabilityReqBodyDto) => {
  try {
    const response = await ApiClient.post<T>(`/traceability/${payload.companyId}`, payload);
    return response.data;
  } catch (error) {
    console.error("API error:", error);
    throw new Error("Failed to fetch traceability data");
  }
};

export { getTraceabilityData };
