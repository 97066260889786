import { FinancialStatements, ReportingPeriods, StatementType } from "../../types";

export const getSubsectionDescription = async (subsection: string) => {
  switch (subsection) {
    case FinancialStatements.INCOME_STATEMENT:
      return "Income Statements";
    case FinancialStatements.BALANCE_SHEET:
      return "Balance Sheet";
    case FinancialStatements.CASH_FLOW:
      return "Cash Flow";
    case FinancialStatements.COVENANTS:
      return "Covenants";
    case FinancialStatements.DEBT_STRUCTURE:
      return "Debt Structure";
    case FinancialStatements.KPI_STATEMENT:
      return "KPI Statement";
    default:
      return subsection;
  }
};

export const getStatementDescription = async (statement: string, debtCovenantsPeriod?: string) => {
  if (debtCovenantsPeriod) {
    switch (debtCovenantsPeriod.toLowerCase()) {
      case StatementType.CURRENT_PERIOD:
        return "Current Period";
      case StatementType.ALL_PERIODS:
        return "All Periods";
    }
  }

  switch (statement.toLowerCase()) {
    case StatementType.BUDGET:
      return "Budget";
    case StatementType.REPORTED:
      return "Reported";
    case StatementType.STANDARDIZED:
      return "Standardized";
    default:
      return statement;
  }
};

export const getReportingCycleDescription = async (reportingCycle: string) => {
  switch (reportingCycle) {
    case ReportingPeriods.Monthly:
      return "Monthly";
    case ReportingPeriods.Quarterly:
      return "Quarterly";
    case ReportingPeriods.YTD:
      return "YTD";
    case ReportingPeriods.LTM:
      return "LTM";
    default:
      return reportingCycle;
  }
};
