import { Box } from "@mui/material";

type CovenantsCellProps = {
  value: number;
  name: string;
};

const boldStyledRow = "Headroom vs Covenant (%)";

export const CovenantsCell = ({ value, name }: CovenantsCellProps) => {
  const formattedValue = Math.abs(Number(value)).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  if (value > 0 && name !== boldStyledRow) {
    return <Box component="span">{formattedValue}x</Box>;
  } else if (value < 0 && name !== boldStyledRow) {
    return <Box component="span" color={"#9F372F"}>{`(${formattedValue}x)`}</Box>;
  } else if (value > 0 && name === boldStyledRow) {
    return (
      <Box component="span" sx={{ fontStyle: "italic" }}>
        {formattedValue}%
      </Box>
    );
  } else if (value < 0 && name === boldStyledRow) {
    return <Box component="span" color={"#9F372F"}>{`(${formattedValue}%)`}</Box>;
  }
  return (
    <Box component="span" sx={{ fontStyle: "italic" }}>
      -
    </Box>
  );
};
