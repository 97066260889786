import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { default as MUITabs } from "@mui/material/Tabs";
import { a11yProps } from "../../utils/a11yProps";
import { TabProps } from "./types";
import { StyledTab } from "./styles";

const TabPanel = ({ ...props }) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
};

/**
 * ### `Tabs`
 */
export default function TabsStatements({
  labels,
  content,
  ...props
}: TabProps) {
  const [value, setValue] = useState(0);

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <MUITabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            style: { display: "none" },
          }}
          sx={{ minHeight: "38px" }}
        >
          {labels?.map((labelItem: string, index: number) => (
            <StyledTab
              key={labelItem}
              label={labelItem}
              {...a11yProps(index)}
            />
          ))}
        </MUITabs>
      </Box>
      {content?.map(
        (
          contentItem:
            | JSX.Element
            | React.ReactNode
            | React.ReactNode[]
            | string,
          index: number
        ) => (
          <TabPanel key={index} value={value} index={index}>
            {contentItem}
          </TabPanel>
        )
      )}
    </Box>
  );
}
