import { Grid, Typography, styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

export const StyledDataGrid = styled(DataGrid)(() => ({
  //row-class--${params.row.style}
  color: "#292727",
  webkitFontSmoothing: "auto",
  letterSpacing: "normal",
  fontFamily: "Inter, sans-serif",
  fontSize: "11px",
  borderRadius: 0,
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#EBEBEB", //New bground color FIXME check for other statements
    borderRadius: 0,
    fontSize: "12px",
    fontWeight: "500",
    textTransform: "uppercase",
    "& .cvx-right--header, .cvx-right--header-lowercase": {
      textAlign: "right",
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        width: "100%",
        justifyContent: "flex-end",
      },
    },
    "& .cvx-right--header-lowercase": {
      textTransform: "none",
    },
    "& .header-title--italics": {
      fontStyle: "italic",
      fontWeight: "400",
      textTransform: "none",
    },
    "& .group-header": {
      paddingLeft: 0,
      paddingRight: 0,
      textTransform: "uppercase",
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: "500",
      },
    },
    "& .currency-header": {
      fontStyle: "italic",
      paddingBottom: "0px",
    },
  },
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: "#F4F4F4",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: "1px solid #E4E4E4",
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: "1px solid #E4E4E4",
    "& .cvx-strong-border--bottom": {
      borderBottom: "1px solid #8F8F8F",
    },
  },
  "& .MuiDataGrid-columnHeaderTitle, .MuiDataGrid-cellContent": {
    whiteSpace: "normal",
  },
  "& .MuiDataGrid-sortIcon": {
    color: "#162859",
  },
  "& .MuiDataGrid-row.Mui-selected": {
    backgroundColor: "#F4F4F4",
  },
  "& .MuiDataGrid-cell": {
    color: "#292727",
    fontSize: "12px",
  },
  "& .MuiDataGrid-cell.cvx-strong-border--bottom": {
    borderBottom: "1px solid #8F8F8F",
  },
  "& .MuiDataGrid-cell.cvx-right--cell": {
    textAlign: "right",
  },
  "& .MuiDataGrid-cell.cvx-datapoint--cell": {
    fontSize: "14px",
    lineHeight: 1.2,
    padding: "0 5px 0 10px",
  },
  "& .row-class--reported-title, .row-class--standard-title": {
    backgroundColor: "#F3F3F4",
    textTransform: "uppercase",
    fontWeight: 500,
    "&:hover": {
      backgroundColor: "#e6e6e6",
    },
    "& .MuiDataGrid-cell": {
      borderRight: "0px",
      fontSize: "12px",
      lineHeight: "16px",
    },
  },
  "& .row-class--period-title, .row-class--standardized-title": {
    backgroundColor: "#3C3C3B",
    fontWeight: 500,
    "&:hover": {
      backgroundColor: "#3C3C3B",
    },
    "& .MuiDataGrid-cell": {
      color: "#ffffff",
      borderRight: "1px solid #3C3C3B",
      borderLeft: "1px solid #3C3C3B",
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
  "& .row-class--period-title.Mui-selected, .row-class--standardized-title.Mui-selected": {
    backgroundColor: "#3C3C3B",
    "&:hover": {
      backgroundColor: "#3C3C3B",
    },
  },
  "& .row-class--margin": {
    "& .MuiDataGrid-cell": {
      fontWeight: "400",
      fontStyle: "italic",
    },
    "& .MuiDataGrid-cell.cvx-datapoint--cell": {
      fontWeight: "400",
      fontStyle: "normal",
    },
  },
  "& .row-class--current-total": {
    "& .MuiDataGrid-cell": {
      fontWeight: "600",
    },
    "& .MuiDataGrid-cell.cvx-datapoint--cell": {
      fontWeight: "600",
    },
  },
}));

export const StatementContainer = styled(Grid)`
  padding: 1rem 1.5rem 1.5rem;
  background-color: #ffffff;
  border: 1px solid #e2e2e2;
  font-family: "Inter", sans-serif;
`;

export const StatementTitle = styled(Typography)`
  padding-bottom: 1rem;
  font-family: "Inter", sans-serif;
  font-weight: 600;
`;
