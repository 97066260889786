import { Button, Modal } from "@alterdomus-analytics/dna-ui";
import { CardActions, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { CaretCircleDown, DotOutline } from "@phosphor-icons/react";
import parse from "html-react-parser";
import { Base64 } from "js-base64";
import { useState } from "react";
import { sanitizeText } from "../../utils/auxiliary";
import {
  CommentPreview,
  ModalActions,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledTertiaryButton,
} from "./styles";
import { AnalystCommentProps } from "./types";

export const AnalystComments = ({
  cId,
  index,
  title,
  subtitle,
  titleHeadingLevel = "h5",
  date,
  text,
  commentDelete,
  commentEdit,
}: AnalystCommentProps) => {
  const [expanded, setExpanded] = useState<string | false>("");
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };
  const handleDeleteComment = () => {
    commentDelete();
    toggleModal();
  };

  const MAX_HEADER_LENGTH = 350; // Maximum characters to show in the header
  // Decode comment & reporting period
  const decodedText = text ? Base64.decode(text) : "";
  const sanitizedText = sanitizeText(decodedText); // Sanitize text before parsing
  const truncatedText = sanitizedText.substring(0, MAX_HEADER_LENGTH) + "..."; // Show X lines of comment in header before expand

  return (
    <div>
      <StyledAccordion
        expanded={expanded === `panel${index + 1}`}
        onChange={handleChange(`panel${index + 1}`)}
        elevation={0}
        sx={{
          "&.Mui-expanded:last-of-type": {
            marginBottom: "1rem",
          },
        }}
      >
        <StyledAccordionSummary
          aria-controls={`panel${index + 1}d-content`}
          id={`panel${index + 1}d-header`}
          expandIcon={<CaretCircleDown size={30} color="#162859" weight="thin" />}
        >
          <CardHeader
            title={
              <>
                <Stack direction="row" sx={{ alignItems: "center" }}>
                  <Typography variant={titleHeadingLevel} component="div" mr={1.5} e2e-test-id={`acomment-title-panel${cId}`}>
                    {title}
                  </Typography>
                  <Typography variant="body2" component="div" e2e-test-id={`acomment-subtitle-panel${cId}`}>
                    {subtitle}
                  </Typography>
                  <DotOutline size={16} color="#586463" weight="fill" />
                  <Typography variant="body2" component="div" e2e-test-id={`acomment-subtitle-panel${cId}`}>
                    {date}
                  </Typography>
                </Stack>
                <CommentPreview sx={{ paddingTop: expanded === `panel${index + 1}` ? 0 : "1rem" }}>
                  {expanded === `panel${index + 1}` ? null : (
                    <>
                      <Typography variant="body1" e2e-test-id={`acomment-preview-panel${cId}`}>
                        {parse(truncatedText)}
                        {/* if panel not expanded show truncated comment */}
                      </Typography>
                      <CardActions>
                        <StyledTertiaryButton
                          type="button"
                          label="Edit"
                          variantType="tertiary"
                          onClick={commentEdit}
                          e2e-test-id={`edit-button-acomment${cId}`}
                        />
                        <DotOutline size={16} color="#586463" weight="fill" />
                        <StyledTertiaryButton
                          type="button"
                          label="Delete"
                          variantType="tertiary"
                          onClick={toggleModal}
                          e2e-test-id={`delete-button-acomment${cId}`}
                        />
                      </CardActions>
                    </>
                  )}
                </CommentPreview>
              </>
            }
          />
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <CardContent>
            <Typography variant="body1" e2e-test-id={`acomment-content-panel${cId}`}>
              {parse(sanitizedText)}
            </Typography>
          </CardContent>
          <CardActions>
            <StyledTertiaryButton
              type="button"
              label="Edit"
              variantType="tertiary"
              onClick={commentEdit}
              e2e-test-id={`edit-button-acomment${cId}`}
            />
            <DotOutline size={16} color="#586463" weight="fill" />
            <StyledTertiaryButton
              type="button"
              label="Delete"
              variantType="tertiary"
              onClick={toggleModal}
              e2e-test-id={`delete-button-acomment${cId}`}
            />
          </CardActions>
        </StyledAccordionDetails>
      </StyledAccordion>
      <Modal
        open={showModal}
        onClose={toggleModal}
        maxWidth={"sm"}
        title="Delete this Comment?"
        children="This will delete this comment permanently. You cannot undo this action"
        footerSlot={
          <ModalActions flexDirection={"row"} justifyContent={"flex-end"} gap={"1rem"} padding={"1rem 2rem"}>
            <Button
              type="button"
              label="Cancel"
              variantType="tertiary"
              onClick={toggleModal}
              e2e-test-id={`cancel-button-acomment-modal-${cId}`}
            />
            <Button
              type="button"
              label="Delete"
              variantType="secondary"
              onClick={handleDeleteComment}
              e2e-test-id={`delete-button-acomment-modal-${cId}`}
            />
          </ModalActions>
        }
      />
    </div>
  );
};
