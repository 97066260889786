import { SeriesOptionsType } from "highcharts";
import { ChartAxisProps, ChartDataSeries, ChartType } from "../../../components/Chart";
import { ReportingPeriods } from "../../../types";
import { getMonthNameAbbreviated } from "../../../utils/monthNames";
import ApiClient from "../../api";

export type RevenuePerformanceChartData = {
  financialYear: number;
  series: Array<ChartDataSeries>;
  xAxis: ChartAxisProps;
  yAxis: ChartAxisProps;
};

export type RevenuePerformanceDBData = {
  actual: {
    dataSeries: Array<RevenuePerformanceDetail>;
  };
  budget: {
    dataSeries: Array<RevenuePerformanceDetail>;
  };
  lastYear: {
    dataSeries: Array<RevenuePerformanceDetail>;
  };
};

/* export type RevenuePerformanceDBData = {
  [key: string]: {
    dataSeries: Array<RevenuePerformanceDetail>;
  };
};
 */
export type RevenuePerformanceDetail = {
  financialYear: number;
  quarter: number;
  month: number;
  year: number;
  value: number;
};

export const getRevenuePerformanceChartData = async (
  companyId: number,
  reportingCycle: ReportingPeriods,
  financialYear: number,
  enableConversions: boolean = false,
  unit: string = ""
) => {
  const queryString = `?type=${
    ChartType.REVENUE_PERFORMANCE
  }&companyId=${companyId}&reportingCycle=${reportingCycle}&financialYear=${financialYear}&enableConversions=${
    enableConversions ? "true" : "false"
  }&unit=${unit ? unit : ""}`;

  const seriesFromDB = await ApiClient.get(`/charts${queryString}`);

  if (!seriesFromDB.data) return null;

  let xAxis: ChartAxisProps | null = null;
  const xAxisTitles = await getXAxisTitles(seriesFromDB.data, reportingCycle);

  if (xAxisTitles) {
    xAxis = {
      titles: xAxisTitles,
      colors: [],
    };
  }

  const yAxis: ChartAxisProps = {
    titles: [],
    colors: [],
  };

  const series = await getSeries(seriesFromDB.data, reportingCycle);

  return {
    financialYear,
    series,
    xAxis,
    yAxis,
  } as RevenuePerformanceChartData;
};

const getXAxisTitles = async (data: RevenuePerformanceDBData, reportingCycle: ReportingPeriods) => {
  let detailToUse: RevenuePerformanceDetail[];

  // using the first arrays with data
  if (data.actual.dataSeries?.length > 0) {
    detailToUse = data.actual.dataSeries;
  } else if (data.budget.dataSeries?.length > 0) {
    detailToUse = data.budget.dataSeries;
  } else if (data.lastYear.dataSeries?.length > 0) {
    detailToUse = data.lastYear.dataSeries;
  } else {
    return [];
  }

  detailToUse = detailToUse.sort((a, b) => (a.month < b.month ? -1 : a.month > b.month ? 1 : 0));

  if (reportingCycle === ReportingPeriods.Quarterly) {
    let dataToReturn = [];
    for (const detail in detailToUse) {
      dataToReturn.push(`${await getMonthNameAbbreviated(detailToUse[detail].month)}`);
    }
    return dataToReturn;
  } else if (reportingCycle === ReportingPeriods.Monthly) {
    let dataToReturn = [];
    for (const detail in detailToUse) {
      dataToReturn.push(`${await getMonthNameAbbreviated(detailToUse[detail].month)}`);
    }
    return dataToReturn;
  } else {
    return [];
  }
};

// Use getSeries to decorate the series with specific elements to the chart.
const getSeries = async (data: RevenuePerformanceDBData, reportingCycle: ReportingPeriods) => {
  const series: SeriesOptionsType[] = [];
  series.push({
    animation: false,
    name: "Actual",
    data: await getRawDataFromSeries(data.actual.dataSeries, reportingCycle),
    color: "#4873E4",
    type: "column",
    borderRadius: 0,
  });
  series.push({
    animation: false,
    name: "Budget",
    data: await getRawDataFromSeries(data.budget.dataSeries, reportingCycle),
    color: "#143CA6",
    type: "column",
    borderRadius: 0,
  });
  series.push({
    animation: false,
    name: "Last Year",
    data: await getRawDataFromSeries(data.lastYear.dataSeries, reportingCycle),
    color: "#A3B9F1",
    type: "column",
    borderRadius: 0,
  });
  return series;
};

const getRawDataFromSeries = async (data: RevenuePerformanceDetail[], reportingCycle: ReportingPeriods) => {
  if (!data || data.length === 0) return [];

  let rawDataToReturn = [];

  switch (reportingCycle) {
    case ReportingPeriods.Quarterly:
      for (const rawData in data) {
        rawDataToReturn.push(data[rawData].value);
      }
      return rawDataToReturn;
    case ReportingPeriods.Monthly:
      for (const rawData in data) {
        rawDataToReturn.push(data[rawData].value);
      }
      return rawDataToReturn;
    default:
      return [];
  }
};
