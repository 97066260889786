import { AnalyzerRow } from "../../types";

export const getRevenueEbitdaDataToExcel = async (data: Array<AnalyzerRow>) => {
  if (!data || data.length === 0) return null;

  const preparedData: Array<AnalyzerRow> = [];

  for await (const d of data) {
    const newObject: { [key: string]: any } = {};
    newObject["Portfolio Company"] = d.company_name;
    newObject["Period"] = d.period || "";
    newObject["Currency"] = d.currency || "";
    newObject["Revenue (In M) - Actual"] = d.revenueActual || "";
    newObject["Revenue (In M) - vsBudget"] = d.revenueVsBudget || "";
    newObject["Revenue (In M) - vsLastYear"] = d.revenueVsLastYear || "";
    newObject["Ebitda (In M) - Actual"] = d.ebitdaActual || "";
    newObject["Ebitda (In M) - vsBudget"] = d.ebitdaVsBudget || "";
    newObject["Ebitda (In M) - vsLastYear"] = d.ebitdaVsLastYear || "";
    newObject["Leverage - Opening"] = d.leverageOpening || "";
    newObject["Leverage - Current"] = d.leverageCurrent || "";
    preparedData.push(newObject);
  }

  return preparedData;
};
