import { ReportingPeriods, StatementType } from "../../types";
import ApiClient from "../api";
import { FilterOptions } from "./types";

const getReportedBalanceSheetByCompanyId = async <T>(companyId: number, financialYear: number) => {
  try {
    const response = await ApiClient.get<T>(`/balance_sheets/${companyId}/reported?financialYear=${financialYear}`);
    return response.data;
  } catch (error) {
    console.error("API error:", error);
    throw new Error("Failed to fetch reported financial data for deal.");
  }
};

const getStandardizedBalanceSheetByCompanyId = async <T>(companyId: number, financialYear: number) => {
  try {
    const response = await ApiClient.get<T>(`/balance_sheets/${companyId}/standarized?financialYear=${financialYear}`);
    return response.data;
  } catch (error) {
    console.error("API error:", error);
    throw new Error("Failed to fetch standardized financial data for deal.");
  }
};

const getBalanceSheetFilters = async (companyId: number, type: StatementType, period: ReportingPeriods) => {
  try {
    const response = await ApiClient.get(`/balance_sheets/${companyId}/filters?type=${type}&period=${period}`);
    return response.data as FilterOptions;
  } catch (error) {
    console.error("API error:", error);
    throw new Error("Failed to fetch reported financial data for deal.");
  }
};

export { getReportedBalanceSheetByCompanyId, getStandardizedBalanceSheetByCompanyId, getBalanceSheetFilters };
