import ApiClient from "../api";

const getFile = async (fileId: string) => {
  try {
    const response = await ApiClient.get(`/files/${fileId}`, { responseType: "blob" });
    return response.data as BlobPart;
  } catch (error) {
    console.error("API error:", error);
    throw new Error("Failed to fetch file");
  }
};

export { getFile };
