import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CVXHeaderLogo from "../../assets/images/cvx-header-logo.svg";
import { useAppSelector } from "../../redux/hooks";
import { logOut } from "../../services/auth";
import useConfirmationModal from "../ConfirmationModal/useConfirmationModal";
import {
  HeaderDivider,
  HeaderUserButton,
  HeaderUserButtonIcon,
  HeaderUserMenu,
  HeaderUserMenuItem,
  StickyHeaderNavigation,
} from "./styles";

export const AppHeader = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.app.user);
  const [showModal, setShowModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const userName = user.name ? user.name : "";

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = async () => {
    try {
      await logOut();
      navigate("/login");
    } catch (error) {
      console.error(error);
    }
  };

  const { ConfirmationDialog } = useConfirmationModal(
    showModal,
    "Logout?",
    "Are you sure you want to log out?",
    () => handleLogOut(),
    () => setShowModal(false)
  );

  return (
    <>
      <StickyHeaderNavigation
        position="sticky"
        logo={{ href: "/analyzer", alt: "Portfolio", src: CVXHeaderLogo }}
        children={
          <>
            <HeaderDivider />
            <HeaderUserButton onClick={handleClick} endIcon={<HeaderUserButtonIcon />} label={userName} variantType="tertiary" />
            <HeaderUserMenu open={open} id="basic-menu" anchorEl={anchorEl} onClose={handleClose}>
              <HeaderUserMenuItem onClick={() => setShowModal(true)}>Logout</HeaderUserMenuItem>
            </HeaderUserMenu>
          </>
        }
      />
      <ConfirmationDialog />
    </>
  );
};
