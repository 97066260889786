import { AppWrapper } from "@alterdomus-analytics/dna-ui";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import { RoutesComponent } from "./routes";

const queryClient = new QueryClient();

// Install heap:
if (process.env.REACT_APP_HEAP_ID) {
  const heapNode: Element = document.createElement("script");
  heapNode.innerHTML = `window.heap=window.heap||[],
    heap.load=function(e,t){
        window.heap.appid=e,
        window.heap.config=t=t||{};
        var r=document.createElement("script");
        r.type="text/javascript",
        r.async=!0,
        r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";
        var a=document.getElementsByTagName("script")[0];
        a.parentNode.insertBefore(r,a);
        for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=[
          "addEventProperties",
          "addUserProperties",
          "clearEventProperties",
          "identify",
          "resetIdentity",
          "removeEventProperty",
          "setEventProperties",
          "track",
          "unsetEventProperty"
        ],o=0;o<p.length;o++)heap[p[o]]=n(p[o])
      };
    heap.load("${process.env.REACT_APP_HEAP_ID}");`;
  document.head.appendChild(heapNode);
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient} contextSharing={true}>
          <AppWrapper>
            <RoutesComponent />
          </AppWrapper>
        </QueryClientProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
