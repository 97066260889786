import { DebtStructureProps } from "./types";
import { DebtCovenantsStatements, FinancialStatements } from "../../../../types";
import {
  getDebtStructureFilters,
  getReportedDebtStructureByCompanyId,
  getStandardizedDebtStructureByCompanyId,
} from "../../../../services/debt_structure";
import { DebtCovenantsStatement } from "../../../../components/DebtCovenantsStatement";
import { getCurrentDebtByCompanyId } from "../../../../services/debt_structure";
import { getCurrentCovenantsByCompanyId } from "../../../../services/covenants";

export const DebtStructure = ({ periodType, companyId, companyName, currency, enableConversions }: DebtStructureProps) => {
  return (
    <DebtCovenantsStatement
      statement={FinancialStatements.DEBT_STRUCTURE}
      currentStatement={DebtCovenantsStatements.CURRENT_DEBT}
      showSelector={false}
      companyId={companyId}
      companyName={companyName}
      periodType={periodType}
      currency={currency}
      filterDataFn={getDebtStructureFilters}
      standardizedDataFn={getStandardizedDebtStructureByCompanyId}
      reportedDataFn={getReportedDebtStructureByCompanyId}
      currentDataFn={getCurrentDebtByCompanyId}
      currentCovDataFn={getCurrentCovenantsByCompanyId}
      enableConversions={enableConversions}
    />
  );
};
