import { AnalystComment, AnalystCommentsByYear } from "../../types";
import ApiClient from "../api";

const postFormData = async (companyId: number, editorData: string, selectData: string, userName?: string, userId?: string) => {
  const createAnalystCommentaryDto = {
    deal_id: companyId,
    user_id: userId,
    user_name: userName,
    reporting_period: selectData,
    text: editorData,
  };

  try {
    await ApiClient.post(`/analyst_commentary/${companyId}`, createAnalystCommentaryDto);
  } catch (err) {
    console.error("API error:");
    console.error(err);
    throw new Error("Failed to post form data.");
  }
};

const updateFormData = async (
  companyId: number,
  commentId: number,
  editorData: string,
  selectData: string,
  userName?: string,
  userId?: string
) => {
  const updateAnalystCommentaryDto = {
    user_id: userId,
    user_name: userName,
    reporting_period: selectData,
    text: editorData,
  };

  try {
    const response = await ApiClient.patch(`/analyst_commentary/${companyId}/${commentId}`, updateAnalystCommentaryDto);
    return response.data;
  } catch (err) {
    console.error("API error:");
    console.error(err);
    throw new Error("Failed to update form data.");
  }
};

const getLatestAnalystById = async (companyId: number) => {
  try {
    const response = await ApiClient.get<AnalystComment[]>(`/analyst_commentary/${companyId}`);
    return response.data;
  } catch (err) {
    console.error("api error:");
    console.error(err);
    throw Error("Failed to get latest analyst comments.");
  }
};

const deleteAnalystById = async (companyId: number, id: number) => {
  try {
    const response = await ApiClient.delete<AnalystComment[]>(`/analyst_commentary/${companyId}/${id}`);
    return response.data;
  } catch (err) {
    console.error("api error:");
    console.error(err);
    throw Error("Failed to delete analyst comment.");
  }
};

const getAllAnalystById = async (companyId: number) => {
  try {
    const response = await ApiClient.get<AnalystCommentsByYear>(`/analyst_commentary/all/${companyId}`);
    return response.data;
  } catch (err) {
    console.error("api error:");
    console.error(err);
    throw Error("Failed to get all analyst comments for the latest year.");
  }
};

const getAllAnalystByIdAndYear = async (companyId: number, selectedYear: number) => {
  try {
    const response = await ApiClient.get<AnalystComment[]>(`/analyst_commentary/all/${companyId}/${selectedYear}`);
    return response.data;
  } catch (err) {
    console.error("api error:");
    console.error(err);
    throw Error("Failed to get all analyst comments for the latest year.");
  }
};

const getYearFilterOptions = async (companyId: number) => {
  try {
    const response = await ApiClient.get<number[]>(`/analyst_commentary/filter/${companyId}`);
    return response.data;
  } catch (err) {
    console.error("api error:");
    console.error(err);
    throw Error("Failed to get all analyst comments for the latest year.");
  }
};

export {
  postFormData,
  updateFormData,
  getLatestAnalystById,
  deleteAnalystById,
  getAllAnalystById,
  getYearFilterOptions,
  getAllAnalystByIdAndYear,
};
