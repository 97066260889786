import styled from "@emotion/styled";
import { Card } from "@mui/material";

export const DealSummaryCard = styled(Card)`
  padding: 0rem;

  .MuiCardHeader-root {
    padding: 1rem 1rem;
    border-bottom: 1px solid #e2e2e2;
  }
  .MuiCardContent-root {
    padding: 0rem;
  }

  .MuiTypography-root,
  .MuiTypography-root > * {
    font-family: "Inter", sans-serif;
  }
  .MuiTypography-h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #586463;
  }
`;