import { Checkbox, InputBase, ListItemText, styled as MUIStyled, MenuItem, Select } from "@mui/material";
import { MultiSelectItemType } from ".";

export const BootstrapInput = MUIStyled(InputBase)(({ theme }) => ({
  fontFamily: "Inter, sans-serif",
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiOutlinedInput-input": {
    backgroundColor: "#f1faff",
    borderColor: "#f1faff",
    fontSize: 14,
    lineHeight: 20,
    padding: "10px 26px 10px 12px",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#f1faff",
    },
  },
}));

export const CustomSelect = MUIStyled(Select<MultiSelectItemType[]>)(({ theme }) => ({
  backgroundColor: "#f1faff",
  fontSize: "14px",
  fontFamily: "'Inter', sans-serif",
  minWidth: "100px",
  borderRadius: 2,
  padding: "5px 7px 5px 10px",
  "& .MuiSelect-multiple": {
    color: "#162859",
  },
}));

export const CustomSelectItem = MUIStyled(MenuItem)(() => ({
  fontFamily: "Inter, sans-serif",
  backgroundColor: "#ffffff",
  fontSize: 14,

  ":focus-visible": {
    backgroundColor: "transparent",
    width: "100%",
    minWidth: "100px",
  },

  ":hover": {
    backgroundColor: "#f1faff",
    width: "100%",
    minWidth: "100px",
  },
  "&.Mui-selected": {
    fontFamily: "Inter, sans-serif",
    backgroundColor: "#E8EAEE",
  },
}));

export const Item = MUIStyled(Checkbox)(() => ({
  backgroundColor: "transparent",
  color: "#162859",

  ":hover": {
    backgroundColor: "transparent",
  },

  "&.Mui-checked": {
    color: "#162859",
    backgroundColor: "transparent",
  },

  "&.MuiSvgIcon-root": { fontSize: 15 },
}));

export const ItemDescription = MUIStyled(ListItemText)(({ theme }) => ({
  color: "#162859",
  fontFamily: "Inter, sans-serif",

  "& .MuiTypography-root": {
    fontSize: 14,
    fontFamily: "'Inter', sans-serif",
  },
}));
