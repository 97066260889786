import { Box } from "@mui/material";
import { GridRowHeightParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { FinancialStatements, ReportLineItem, ReportingPeriods, StatementType, Units } from "../../types";
import { normalizeTimeSeries } from "../../utils/auxiliary";
import DataTraceability from "../DataTraceability/DataTraceability";
import { ContentModal } from "../Modal";
import { NotTableData } from "../NotTableData";
import { StyledDataGrid } from "./styles";
import { columnGroupingModel, getColumns } from "./table.config";
import { useAppSelector } from "../../redux/hooks";

interface ReportedRowHeight extends GridRowHeightParams {
  style?: string;
}

interface TraceabilityDetails {
  isOpen: boolean;
  companyId: number;
  data_point: string;
  universal_line_item: string;
  month: number;
}

export interface HighlightedCell {
  target_uuid: string;
  month: number;
}

interface StatementTableProps {
  companyId: number;
  statement: FinancialStatements;
  mode: StatementType;
  period: ReportingPeriods;
  company_period: ReportingPeriods;
  currency: string;
  unit: Units;
  financialYear: number;
  data?: ReportLineItem[];
  highlightItem?: HighlightedCell;
  enableConversions?: boolean;
}

const initState = { isOpen: false, companyId: 0, data_point: "", universal_line_item: "", month: 0 };

export const StatementTable = ({
  companyId,
  statement,
  mode,
  period,
  company_period,
  currency,
  unit,
  financialYear,
  data,
  highlightItem,
  enableConversions,
}: StatementTableProps) => {
  const [rows, setRows] = useState<any[]>([]);
  const [traceabilityDetails, setTraceabilityDetails] = useState<TraceabilityDetails>(initState);
  const financialYearEnd = useAppSelector((state) => state.app.selectedCompany.financial_year_end);

  const handleCloseTraceability = () => {
    setTraceabilityDetails({
      ...traceabilityDetails,
      isOpen: false,
    });
  };

  const handleOpenTraceability = (data_point: string, universal_line_item: string, month: number) => {
    setTraceabilityDetails({
      companyId: companyId,
      data_point: data_point,
      universal_line_item: universal_line_item,
      month: month,
      isOpen: true,
    });
  };

  useEffect(() => {
    const itemsToRender = [];

    if (data?.length) {
      // add the response data to itemsToRender
      for (const item of data) {
        if (item && item.values !== null && item.values?.length) {
          (async () => {
            const normalizedValues = await normalizeTimeSeries(
              item.values,
              companyId,
              item.universal_line_item,
              financialYear,
              company_period,
              period,
              unit,
              item.style,
              enableConversions,
              financialYearEnd
            );
            item.values = normalizedValues;
          })();
        }
        itemsToRender.push(item);
      }
    }
    // Set the rows
    setRows(itemsToRender);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, statement]);

  if (data == null) return <div></div>;

  return (
    <>
      {!data.length ? (
        <NotTableData
          title="Sorry, there's no data to display here at the moment."
          content="We don't seem to have any data available for this report."
        />
      ) : (
        <div style={{ display: "flex", height: "100%" }}>
          <Box sx={{ flexGrow: 1 }}>
            <StyledDataGrid
              experimentalFeatures={{ columnGrouping: true }}
              rows={rows}
              columns={getColumns(
                mode,
                period,
                company_period,
                currency,
                unit,
                statement,
                handleOpenTraceability,
                data,
                highlightItem
              )}
              columnGroupingModel={columnGroupingModel(financialYear)}
              autoHeight
              headerHeight={32}
              disableColumnMenu={true}
              disableColumnFilter
              disableSelectionOnClick={true}
              sortingMode="server"
              hideFooter={true}
              getRowClassName={(params) => `row-class--${params.row.style}`}
              getRowHeight={(values: ReportedRowHeight) => {
                const rowStyle = values.model.style;
                const rowName = values.model.name;
                if (
                  rowStyle === "reported-total" ||
                  rowStyle === "standard-total" ||
                  rowStyle === "reported-subtotal" ||
                  rowStyle === "standard-subtotal"
                ) {
                  return 52;
                } else if (rowStyle === "reported-title" || rowStyle === "standard-title" || rowStyle === "period-title") {
                  return 36;
                } else if (rowName !== undefined && rowName.length < 44) {
                  return 32;
                } else {
                  return "auto";
                }
              }}
            />

            {traceabilityDetails.isOpen && (
              <ContentModal
                open={traceabilityDetails.isOpen}
                onClose={handleCloseTraceability}
                content={
                  <DataTraceability
                    e2e-test-id={`data-traceability`}
                    companyId={companyId}
                    statement={statement}
                    period={period}
                    company_period={company_period}
                    dataPoint={traceabilityDetails.universal_line_item}
                    finYear={financialYear}
                    month={traceabilityDetails.month}
                    currency={currency}
                    unit={unit}
                    enableConversions={enableConversions}
                  />
                }
              />
            )}
          </Box>
        </div>
      )}
    </>
  );
};
