import { Link } from "@mui/material";
import { formatValue } from "../../../utils/auxiliary";
import { DataFormatTypes, StatementType } from "../../../types";
import { Buffer } from "buffer";

type ReportedDataCellProps = {
  data_point: string;
  month: number;
  value: number | null;
  highlightedValue?: number; // add a new prop to specify the value to highlight
  style?: string;
  statement?: string;
  fileUri?: string;
  fileName?: string;
  companyId: number;
  leftCoordinate?: number;
  rightCoordinate?: number;
  topCoordinate?: number;
  bottomCoordinate?: number;
  pageNumber?: number;
  uuid: string;
  mode: StatementType;
};

export const ReportedDataCell = ({
  data_point,
  value,
  month,
  style,
  fileUri,
  fileName,
  companyId,
  leftCoordinate,
  rightCoordinate,
  topCoordinate,
  bottomCoordinate,
  pageNumber,
  uuid,
  mode,
}: ReportedDataCellProps) => {
  const fileId = fileUri?.split("=")[1];

  const handleClick = () => {
    //If the mode is budget or the fileId was not provided return early
    if (mode === StatementType.BUDGET || fileId == null) {
      return;
    }

    const cellInfo = {
      fileId: fileId,
      fileName: fileName,
      value: value,
      dataPoint: uuid,
      coordinates: {
        leftCoordinate,
        rightCoordinate,
        topCoordinate,
        bottomCoordinate,
        pageNumber,
      },
    };
    // TODO Use either another encoding or use a salt file to encode the data.

    const encodedData = Buffer.from(
      JSON.stringify({
        cellInfo: cellInfo,
      })
    ).toString("base64");

    window.open(`/pdf_viewer/${companyId}/${encodedData}`, "_blank", "width=1300,height=750,scrollbars=yes,resizable=yes");
  };

  const symbol = style === "margin" ? DataFormatTypes.margin : style === "ratio" ? DataFormatTypes.ratio : DataFormatTypes.none;
  const outputValue = formatValue(value, symbol);
  return (
    <Link
      e2e-test-id={`value-cell-${data_point}-${month}`}
      color={mode === StatementType.BUDGET || fileId == null ? "#292727" : "#1A50DD"}
      underline="none"
      sx={mode === StatementType.BUDGET || fileId == null ? null : { cursor: "pointer" }}
      onClick={() => handleClick()}
    >
      {outputValue}
    </Link>
  );
};
