import ReactQuill from "react-quill";

var icons = ReactQuill.Quill.import("ui/icons");
icons["bold"] = '<i class="ph ph-text-b"></i>';
icons["italic"] = '<i class="ph ph-text-italic"></i>';
icons["underline"] = '<i class="ph ph-text-underline"></i>';
icons["strike"] = '<i class="ph ph-text-strikethrough"></i>';
icons["header"] = '<i class="ph ph-text-h-one"></i>';
// icons["list"] = '<i class="ph ph-list-bullets"></i>';
icons["indent"] = '<i class="ph ph-text-indent"></i>';

var toolbarOptions = [
  ["bold", "italic", "underline", "strike", { header: 1 }], // toggled buttons

  [{ list: "ordered" }, { list: "bullet" }], // span lists

  [{ indent: "+1" }],
];

export const config = {
  debug: "info",
  modules: {
    toolbar: toolbarOptions,
  },
  placeholder: "",
  readOnly: false,
  theme: "snow",
};
