import styled from "styled-components";
import { Typography, Stack, AccordionSummary, Accordion, Dialog, DialogTitle, AccordionDetails } from "@mui/material";

export const StyledStack = styled(Stack)`
  .MuiTypography-root {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`;
export const StyledAccordion = styled(Accordion)`
  *:before {
    background-color: transparent;
  }
`;
export const StyledAccordionSummary = styled(AccordionSummary)`
  .MuiAccordionSummary-content {
    margin: 0.375rem 0.5rem 0.375rem 0;
  }
  .MuiAccordionSummary-expandIconWrapper {
    color: #1a50dd;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  margin: 0;
  padding-bottom: 0.5rem;
  background-color: "#4D798E1A";
`;

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root.MuiDialog-paper {
    padding: 2.75rem 2.5rem;
  }
  .MuiDialogContent-root {
    padding: 0;
  }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  padding: 0 0 5px;
  .MuiTypography-root,
  .MuiTypography-root > * {
    font-family: "Inter", sans-serif;
    color: #292727;
  }
  .MuiTypography-caption {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
  .MuiTypography-h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 34px;
    margin-bottom: 1rem;
  }
  .MuiTypography-subtitle1 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const Text = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;
