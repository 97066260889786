import { FinancialStatementType, ReportingPeriods, StatementType } from "../../types";
import ApiClient from "../api";
import { FilterOptions } from "./types";

const getReportedIncomeByCompanyId = async <T>(
  companyId: number,
  financialYear: number,
  period: string,
  isPeriodSelected: boolean
) => {
  try {
    const response = await ApiClient.get<T>(
      `/income_statements/${companyId}/reported?financialYear=${financialYear}&period=${period}&isPeriodSelected=${isPeriodSelected}`
    );
    return response.data;
  } catch (error) {
    console.error("API error:", error);
    throw new Error("Failed to fetch reported income data for deal.");
  }
};

const getStandardizedIncomeByCompanyId = async <T>(
  companyId: number,
  financialYear: number,
  period: string,
  isPeriodSelected: boolean
) => {
  try {
    const response = await ApiClient.get<T>(
      `/income_statements/${companyId}/standarized?financialYear=${financialYear}&period=${period}&isPeriodSelected=${isPeriodSelected}`
    );
    return response.data;
  } catch (error) {
    console.error("API error:", error);
    throw new Error("Failed to fetch standardized financial data for deal.");
  }
};

const getBudgetIncomeStatementByCompany = async (
  companyId: number,
  financialYear: number,
  period: string,
  isPeriodSelected: boolean
) => {
  try {
    const response = await ApiClient.get<FinancialStatementType>(
      `/income_statements/${companyId}/budget?financialYear=${financialYear}&period=${period}&isPeriodSelected=${isPeriodSelected}`
    );
    return response.data;
  } catch (error) {
    console.error("API error:", error);
    throw new Error("Failed to fetch budget income data for deal.");
  }
};

const getIncomeFilters = async (companyId: number, type: StatementType, period: ReportingPeriods) => {
  try {
    const response = await ApiClient.get(`/income_statements/${companyId}/filters?type=${type}&period=${period}`);
    return response.data as FilterOptions;
  } catch (error) {
    console.error("API error:", error);
    throw new Error("Failed to fetch reported financial data for deal.");
  }
};

export { getReportedIncomeByCompanyId, getStandardizedIncomeByCompanyId, getBudgetIncomeStatementByCompany, getIncomeFilters };
