import styled from "styled-components";
import theme from "../../styles/theme";

export const Wrapper = styled.div`
  overflow: hidden;
  height: 100vh;
  background-color: #ecf7fd;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-around;
  color: ${theme.palette.text.primary};
`;
export const BrandHeader = styled.div`
  @import url("assets/fonts/GothamPro/stylesheet.css");

  display: flex;
  align-items: center;
  margin-left: 2.5rem;

  img {
    margin-right: 1rem;
  }
  .MuiDivider-vertical {
    border-right: 1px solid #586463;
  }
  h3 {
    font-family: "Gotham Pro", sans-serif;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    margin: 0;
    margin-left: 1rem;
    color: #586463;
  }
`;
export const Container = styled.div`
  margin-right: auto;
  margin-left: 16rem;
  padding-top: 3rem;
  padding-bottom: 2rem;
  width: 50%;
  h1 {
    font-size: 6.375rem;
    font-weight: 200;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  p {
    font-size: 1.75rem;
    font-weight: 200;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }
`;
export const IconBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 2rem;
  img {
    background: rgba(67, 171, 227, 0.2);
    border-radius: 50%;
    padding: 2rem;
    margin-right: 2rem;
  }
`;
